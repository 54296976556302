/**
 * For all environments(local, dev, stage), we save brand Icons and Logos
 * in Trellish-prod GCP porject.
 */

// ie. vanguard, schwab, that have a few tickers
export const BrandKnownUrl = 'https://storage.googleapis.com/trellish-brand-known'

// icons and logos from Polygon
export const IconsBaseUrl = 'https://storage.googleapis.com/trellish-prod-brand-icons'
export const LogosBaseUrl = 'https://storage.googleapis.com/trellish-prod-brand-logos'

// app image hosted
const assetsBaseUrl = 'https://storage.googleapis.com/trellish-assets'

export const Assets = {
  icon60: `${assetsBaseUrl}/AppIcons/Assets.xcassets/AppIcon.appiconset/60.png`,
  logo512: `${assetsBaseUrl}/logo/white_512x512.png`,
  
  appleSignIn: `${assetsBaseUrl}/appleSignIn.png`,
  facebookIcon: `${assetsBaseUrl}/facebookIcon.png`,
  googleIcon: `${assetsBaseUrl}/googleIcon.png`,
  faceId: `${assetsBaseUrl}/faceId.png`,

  gettingStartedBackground: `${assetsBaseUrl}/gettingStartedBackground.png`,
  giftRed: `${assetsBaseUrl}/gift-red.png`,
  giftBlue: `${assetsBaseUrl}/gift-blue.png`,
  giftOrange: `${assetsBaseUrl}/gift-orange.png`,
  giftGreen: `${assetsBaseUrl}/gift-green.png`,
  giftcard: `${assetsBaseUrl}/giftcard.png`,
  personUnknown: `${assetsBaseUrl}/sampleAvatars/avatar-unknown.png`,

  // adult drawings
  manBrownBeard: `${assetsBaseUrl}/sampleAvatars/manbrownbeard.png`,
  womanBlondeGlassesDrawing: `${assetsBaseUrl}/sampleAvatars/womanblondglassesdrawing.png`,

  // kid drawings
  boyDrawing: `${assetsBaseUrl}/sampleAvatars/child-profile.png`,
  girlGingerDrawing: `${assetsBaseUrl}/sampleAvatars/girlginger5drawing.png`,
  boyWhite5Drawing: `${assetsBaseUrl}/sampleAvatars/boywhite5drawing.png`,
  girlBlackDrawing: `${assetsBaseUrl}/sampleAvatars/girl5blackdrawing.png`,
  boyBlackHairDrawing: `${assetsBaseUrl}/sampleAvatars/boyblackhair5drawing.png`,

  printableGiftSample: `${assetsBaseUrl}/printableGiftSample.png`,
  // 404
  sadDog: `${assetsBaseUrl}/sadDog.png`,
}

export function randomGiftImage(): string {
  const array = [Assets.giftBlue, Assets.giftRed, Assets.giftOrange]
  return array[Math.floor(Math.random() * array.length)]
}

export function randomAdultDrawing() {
  const array = [Assets.manBrownBeard, Assets.womanBlondeGlassesDrawing]
  return array[Math.floor(Math.random() * array.length)]
}

export function randomWomanDrawing() {
  const array = [Assets.womanBlondeGlassesDrawing]
  return array[Math.floor(Math.random() * array.length)]
}

export function randomManDrawing() {
  const array = [Assets.manBrownBeard]
  return array[Math.floor(Math.random() * array.length)]
}

export function randomKidDrawing() {
  const array = [Assets.girlBlackDrawing, Assets.girlGingerDrawing, Assets.boyWhite5Drawing, Assets.boyBlackHairDrawing]
  return array[Math.floor(Math.random() * array.length)]
}

export function randomBoyDrawing() {
  const array = [Assets.boyWhite5Drawing, Assets.boyBlackHairDrawing]
  return array[Math.floor(Math.random() * array.length)]
}

export function randomGirlDrawing() {
  const array = [Assets.girlBlackDrawing, Assets.girlGingerDrawing]
  return array[Math.floor(Math.random() * array.length)]
}

export function randomAdultKidDrawing(): string {
  const array = [Assets.girlBlackDrawing, Assets.girlGingerDrawing, Assets.boyWhite5Drawing, Assets.boyBlackHairDrawing, Assets.manBrownBeard, Assets.womanBlondeGlassesDrawing]
  return array[Math.floor(Math.random() * array.length)]
}
