import React, { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import Confetti from 'react-confetti'
import { Grid, Container, Box, SxProps, Card } from '@mui/material'
import Text from '@mui/material/Typography'
import { Link, useParams } from 'react-router-dom'
import _ from 'lodash'

import { getGiftPublic } from '../../service/giftService'
import { TrGiftStatusPublic, TrSymbolSummary } from '../../sdk-ts-axios'
import { getSymbolSummary } from '../../service/marketService'
import { logErr } from '../../service/log'
import { useMixpanel } from 'react-mixpanel-browser'
import QRCode from 'react-qr-code'
import { config } from '../../service/api'
import BrandIcon from '../../components/BrandIcon'
import CompanyLogoTagline from '../../components/CompanyLogoTagline'
import GiftImageWithBackup from '../../components/GiftImageWithBackup'
import GiftMessageWithBackup from '../../components/GiftMsgWithBackup'
import { Path } from '../../routes'
import { addDashPer5Letters, removeParamsFromRouterUrl } from '../../utils/utils'
import { AppStoreURL, PlayStoreURL, AppStoreButton, PlayStoreButton } from '../../utils/constants'
import RedeemInstructions from '../../components/RedeemInstructions'

export default function ViewGiftScreen() {
  const mixpanel = useMixpanel()
  const { giftId } = useParams()

  const { width, height } = useWindowSize()
  const [gift, setGift] = useState<TrGiftStatusPublic | undefined>()
  const [stockSummary, setStockSummary] = useState<TrSymbolSummary>()
  const [err, setError] = useState(false)
  const [confetti, setConfetti] = useState(200)
  const isoDate = new Date(gift?.created_at ?? '')
  const giftUrl = `${config.hosting}/g/${giftId}`
  const linkToViewGift = `${removeParamsFromRouterUrl(Path.ViewGift)}/${giftId}`

  useEffect(() => {
    mixpanel.track('ViewGiftScreen', { giftId })
    if (!giftId || giftId.length < 20) {
      setError(true)
      return
    }
    run()
  }, [giftId])

  useEffect(() => {
    const timer = setTimeout(() => {
      setConfetti(0)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  // If on mobile and app installed, go to App.   // V2 (not tested)
  // if(config.deepLink)
  //   window.location.replace(config.deepLink)
  // dynamic links will figure out if web or to mobile apps
  // https://www.youtube.com/watch?v=YT841IVQvSc&t=241s TODO finish this tutorial for V2

  async function run() {
    const giftData = await getGiftPublic(giftId ?? '')
    if (!giftData) {
      setError(true)
      return
    }
    // eslint-disable-next-line camelcase
    giftData.gift_id = giftId
    setGift(giftData)
    if (giftData.symbol) {
      const summary = await getSymbolSummary(giftData.symbol)
      if (summary) {
        setStockSummary(summary)
      } else {
        logErr('PortfolioItem missing stockinfo', { symbol: giftData.symbol, stockSummary })
      }
    } // else is Wish
  }

  if (!giftId || err || !gift)
    return (<div>Loading...</div>)

  return (
    <Box sx={centerBox} color="primary">
      <Box sx={{ alignSelf: 'end' }}>
        {isoDate.toDateString()}
      </Box>

      <GiftImageWithBackup gift={gift} />
      <GiftMessageWithBackup gift={gift} />

      <Box sx={row}>
        <Text variant='h3' sx={{ paddingX: 2 }}>
          {`$${gift.cash_gift_amount?.toFixed(2) ?? gift.secs_net_amount?.toFixed(2)}`}
        </Text>

        <BrandIcon symbol={stockSummary?.symbol} name={stockSummary?.name} />

        <Text variant='h6' sx={{ paddingX: 2 }}>
          {stockSummary?.name}
        </Text>

      </Box>

      <RedeemInstructions gift={gift} />

      <Box sx={{ margin: 1 }} />

      <Box sx={row}>
        <Box sx={{ paddingRight: 4 }}>

          <CompanyLogoTagline />
          <Box sx={{ margin: 2 }} />
          <Text>{`Gift ID: ${addDashPer5Letters(giftId)}`}</Text>
          <Link to={AppStoreURL}>
            <Box
              component="img"
              alt={'logo'}
              sx={{height: 40, width: 135, paddingRight: 1}}
              src={AppStoreButton} // use normal <img> attributes as props
            />
          </Link>

          <Link to={PlayStoreURL}>
            <Box
              component="img"
              alt={'logo'}
              sx={{height: 42, width: 130, paddingRight: 1}}
              src={PlayStoreButton} // use normal <img> attributes as props
            />
          </Link>
        </Box>
      
        <Box>
          <Text>See your gift online</Text>
          <Link
            to={linkToViewGift}
            state={{ giftId }}
          >
            <QRCode
              size={150}
              level='M'
              value={giftUrl}
            />
          </Link>
        </Box>
      </Box>

      <Confetti width={width} height={height} numberOfPieces={confetti} />
    </Box>

  )
}

const centerBox: SxProps = {
  margin: 'auto',
  marginTop: 1,
  padding: 2,
  borderRadius: 10,
  border: 5,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: 500
}

const row: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginY: 2
}
