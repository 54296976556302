import React, { useState } from 'react'
import { Box, Button, Card, FilledInput, FormControl, InputAdornment, InputLabel, SxProps, TextField, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import Grid from '@mui/material/Unstable_Grid2'
import { useStore } from '../../hooks/useStore'

function PickStockCardEdit () {
  const store = useStore()
  const snap = getSnapshot(store.Donate)

  function next() {
    store.Donate.setState('pay')
  }

  return (
    <Card sx={sx.card}>

      <Grid container spacing={2}>
        <Grid xs={3}>
          <Box
            component="img"
            sx={sx.logo}
            alt="AAPL"
            src="https://storage.googleapis.com/trellish-prod-brand-icons/AAPL.png"
            onClick={() => {
              store.Donate.setSymbol('aapl')
              next()
            }}
          />
        </Grid>
        <Grid xs={3}>
          <Box
            component="img"
            sx={sx.logo}
            alt="GOOGL"
            src="https://storage.googleapis.com/trellish-prod-brand-icons/GOOGL.png"
            onClick={() => {
              store.Donate.setSymbol('googl')
              next()
            }}
          />
        </Grid>
        <Grid xs={3}>
          <Box
            component="img"
            sx={sx.logo}
            alt="V"
            src="https://storage.googleapis.com/trellish-prod-brand-icons/V.png"
            onClick={() => {
              store.Donate.setSymbol('v')
              next()
            }}
          />
        </Grid>
        <Grid xs={3}>
          <Box
            component="img"
            sx={sx.logo}
            alt="DIS"
            src="https://storage.googleapis.com/trellish-prod-brand-icons/DIS.png"
            onClick={() => {
              store.Donate.setSymbol('dis')
              next()
            }}
          />
        </Grid>
      </Grid>

    </Card>
  )
}

export default observer(PickStockCardEdit)

const sx = {
  card: {
    backgroundColor: 'white',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
    marginY: 1
  } as SxProps,
  logo: {
    height: 50, 
    width: 50,
    border: 1,
    margin: 1,
    borderRadius: 3
  } as SxProps,
}
