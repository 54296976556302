import React, { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import Confetti from 'react-confetti'
import { Grid, Container, Box, SxProps } from '@mui/material'
import Text from '@mui/material/Typography'
import { Link, useLocation, useParams } from 'react-router-dom'
import _ from 'lodash'

import { getGiftPublic } from '../../service/giftService'
import { TrGiftStatusPublic, TrSymbolSummary } from '../../sdk-ts-axios'
import { getSymbolSummary } from '../../service/marketService'
import { logErr } from '../../service/log'
import { useMixpanel } from 'react-mixpanel-browser'
import StockNameBrandGivenGift from '../../components/StockNameBrandGivenGift'
import { addDashPer5Letters, removeParamsFromRouterUrl } from '../../utils/utils'
import { Path } from '../../routes'
import CompanyLogoTagline from '../../components/CompanyLogoTagline'
import GiftImageWithBackup from '../../components/GiftImageWithBackup'
import GiftMessageWithBackup from '../../components/GiftMsgWithBackup'
import WishIcon from '../../components/WishIcon'
import RedeemInstructions from '../../components/RedeemInstructions'
import { AppStoreURL, PlayStoreURL, AppStoreButton, PlayStoreButton } from '../../utils/constants'

export default function ViewGiftScreen() {
  const mixpanel = useMixpanel()
  const { giftId } = useParams()

  const { width, height } = useWindowSize()
  const [gift, setGift] = useState<TrGiftStatusPublic | undefined>()
  const [stockSummary, setStockSummary] = useState<TrSymbolSummary>()
  const [err, setError] = useState(false)
  const [confetti, setConfetti] = useState(200)
  const linkToViewGift = `${removeParamsFromRouterUrl(Path.ViewGiftPrintable)}/${giftId}`

  const isoDate = new Date(gift?.created_at ?? '')

  useEffect(() => {
    mixpanel.track('ViewGiftScreen', { giftId })
    if (!giftId || giftId.length < 20) {
      setError(true)
      return
    }

    async function run() {
      const giftData = await getGiftPublic(giftId ?? '')
      if (!giftData) {
        setError(true)
        return
      }
      // eslint-disable-next-line camelcase
      giftData.gift_id = giftId
      setGift(giftData)
      if (giftData.symbol) {
        const summary = await getSymbolSummary(giftData.symbol)
        if (summary) {
          setStockSummary(summary)
        } else {
          logErr('PortfolioItem missing stockinfo', { symbol: giftData.symbol, stockSummary })
        }
      } // else is Wish
    }
    run()
  }, [giftId])

  useEffect(() => {
    const timer = setTimeout(() => {
      setConfetti(0)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  // If on mobile and app installed, go to App.   // V2 (not tested)
  // if(config.deepLink)
  //   window.location.replace(config.deepLink)
  // dynamic links will figure out if web or to mobile apps
  // https://www.youtube.com/watch?v=YT841IVQvSc&t=241s TODO finish this tutorial for V2

  if (!giftId || err || !gift)
    return (<div>Loading...</div>)

  return (
    <Box sx={page}>
      <Container sx={container}>
        <Box sx={title} >
          {
            gift?.receiver_name 
              ? `${gift?.receiver_name} received a gift from ${gift?.sender_name}!`
              : `GiftCard from ${gift?.sender_name}!`
          }
        </Box>
        {isoDate.toDateString()}

        <Grid container spacing={5}>

          <Grid item xs={12} md={6}>
            <GiftImageWithBackup gift={gift} />
            <GiftMessageWithBackup gift={gift} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Text variant='h4' sx={{ paddingX: 2 }}>
              {`$${gift.cash_gift_amount?.toFixed(2) ?? gift.secs_net_amount?.toFixed(2)}`}
            </Text>
            {
              gift?.is_wish ? (
                <WishIcon name={gift.receiver_name} />
              ) : (
                stockSummary && <StockNameBrandGivenGift stockSummary={stockSummary} />
              )
            }

          </Grid>

          <Grid item xs={12} md={6}>
            <CompanyLogoTagline />
            <Box sx={{ margin: 2 }} />
            <Text>{`Gift ID: ${addDashPer5Letters(giftId)}`}</Text>
          </Grid>

          <Grid item xs={12} md={6}>
            <RedeemInstructions gift={gift} /> 
            <Link to={AppStoreURL}>
              <Box
                component="img"
                alt={'logo'}
                sx={{height: 40, width: 135, paddingRight: 1}}
                src={AppStoreButton} // use normal <img> attributes as props
              />
            </Link>

            <Link to={PlayStoreURL}>
              <Box
                component="img"
                alt={'logo'}
                sx={{height: 42, width: 130, paddingRight: 1}}
                src={PlayStoreButton} // use normal <img> attributes as props
              />
            </Link>
          </Grid>
         
        </Grid>

      </Container>

      <Container>
        <Box sx={{ paddingY: 2 }}>
          <Link
            to={linkToViewGift}
            state={{ giftId }}
          >
            View the Printable Version
          </Link>
        </Box>
      </Container>
      <Confetti width={width} height={height} numberOfPieces={confetti} />
    </Box>
  )
}

const page: SxProps = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#84e4c8',
  paddingTop: 10
}

const container: SxProps = {
  backgroundColor: 'white',
  borderRadius: '3em',

}

const title: SxProps = {
  typography: 'h1',
  textAlign: 'center',
  padding: '1em'
}