import _ from 'lodash'
import axios, { AxiosError } from 'axios'

import { Api } from './api'
import { TrUserProfile, TrUserConfig, TrUserProfilePublic, TrWish } from '../sdk-ts-axios'
import { logErr, logInfo, logWarn } from '../log'
import { axiosErrorLog, getErrorMsg } from '../utils/error'

export async function getMyConfig(): Promise<TrUserConfig | undefined> {
  logInfo('getMyConfig api')
  try {
    const api = await Api()
    const myConfig = await api.usersConfigGet()
    return myConfig.data[0]
  } catch (e: any) {
    axiosErrorLog('getMyConfig', e)
  }
}

export async function getContributorsPublicProfiles(receiverId: string): Promise<TrUserProfilePublic[] | undefined> {
  try {
    const api = await Api()
    const profiles = await api.usersConfigContributorsGet(receiverId)
    return profiles.data
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 404) { // not found is valid
      return undefined 
    } else
      axiosErrorLog('getContributorsPublicProfiles', e)
  }
}

export async function setMyConfig(config: TrUserConfig): Promise<undefined | AxiosError> {
  logInfo('setMyConfig api', { config: JSON.stringify(config) })

  try {
    const api = await Api()
    await api.usersConfigPatch(config)
    return 
  } catch (e: any) {
    return axiosErrorLog('setMyConfig', e)
  }   
}

export async function getMyKidsConfigs(minorUids: string[] | undefined): Promise<TrUserConfig[] | undefined> {
  try {
    logInfo('getMyKidsConfigs api', { minorUids })
    if (!minorUids)
      throw new Error('getMyKidsConfigs, missing minor id')
    const minorUidsJoin = minorUids.join(',')
    const api = await Api()
    const myConfigs = await api.usersConfigGet(minorUidsJoin)

    const removeNulls = myConfigs.data.filter(element => {
      return element !== null
    })
    return removeNulls
  } catch (e: any) {
    axiosErrorLog('getMyKidsConfigs', e)
  }
}

export async function setMyKidsWish(minorUid: string | undefined, wish: TrWish[]): Promise<undefined | AxiosError> {
  logInfo('setMyKidsWish api', { wish })

  const data: TrUserConfig = { wish }
  try {
    if (!minorUid)
      throw new Error('setMyKidsWish, missing minor id')
    const api = await Api()
    await api.usersConfigPatch(data, { params: { id: minorUid } })
    return 
  } catch (e: any) {
    return axiosErrorLog('setMyKidsWish', e)
  }   
}

export async function setContactsLineup(config: TrUserConfig, lineupProfiles: TrUserProfile[]): Promise<undefined | AxiosError> {
  const lineupIds = lineupProfiles
    .map(c => c.id)
    .filter((i): i is string => !! i)
  
  logInfo('setContactsLineup api', { lineupIds })

  const newConfig: TrUserConfig = {
    ...config,
    contacts_lineup: lineupIds
  }
  try {
    const api = await Api()
    await api.usersConfigPatch(newConfig)
    return 
  } catch (e: any) {
    return axiosErrorLog('setContactsLineup', e)
  }   
}

export async function setMyKidsLineup(config: TrUserConfig, lineupProfiles: TrUserProfile[]): Promise<undefined | AxiosError> {
  const lineupIds = lineupProfiles
    .map(c => c.id)
    .filter((i): i is string => !! i)

  logInfo('setMyKidsLineup api', { lineupIds })

  const newConfig: TrUserConfig = {
    ...config,
    mykids_lineup: lineupIds
  }
  try {
    const api = await Api()
    await api.usersConfigPatch(newConfig)
    return 
  } catch (e: any) {
    return axiosErrorLog('setMyKidsLineup', e)
  }   
}
