import axios, { Axios, AxiosError } from 'axios'
import { logErr, logInfo, logWarn } from '../log'

interface AxiosErr {
  response: {
    data: {
      message: string
    },
    status: number
  }
}

export function axiosErrorLog(title: string, e: any): AxiosError {
  if (axios.isAxiosError(e)) {
    const ee = e as AxiosErr
    const message: string = ee.response?.data.message
    const status: number = e.response?.status ?? 503
    logErr(title, { message, status })
  } else if (String(e).includes('auth/no-current-user')) {
    return e
  } else {
    logErr(title + ' not axios error', { err: e.message })
  }
  return e
}

export function axiosInfoLog(title: string, e: any): AxiosError {
  if (axios.isAxiosError(e)) {
    const message: string = (e as AxiosErr).response?.data.message
    const status: number = e.response?.status ?? 503
    logInfo(title, { message, status })
  } else {
    logInfo(title + ' not axios error', { err: JSON.stringify(e) })
  }
  return e
}

export function getErrorMsg(e: any): string {
  if (axios.isAxiosError(e)) {
    const ee = e as AxiosErr
    if (ee.response?.data.message)
      return ee.response?.data.message
    else 
      return String(ee.response?.data)
  } else {
    return e.message
  }
}

export function cleanErrorMessages(dirty: string): string {
  // ie.  "Error: <!DOCTYPE html>\n<html lang=\"en\">\n<head>\n<meta charset=\"utf-8\">\n<title>Error</title>\n</head>\n<body>\n<pre>Error: transferable balance is insufficient<br> &nbsp; &nbsp;at GiftRoute.createGift (/usr/src/app/dist/routes/gifts.route.js:139:27)</pre>\n</body>\n</html>\n"
  if (dirty.includes('<pre>Error: transferable balance is insufficient<br>'))
    return 'insufficient funds. Funds must cover gift amount and fee.'
  return dirty
}
