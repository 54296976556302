import React, { useEffect, useState } from 'react'
import { Avatar, Box, Container, SxProps, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { getPublicUserByUsername } from '../service'
import { TrUserProfilePublic } from '../sdk-ts-axios'
import axios from 'axios'
import { getErrorMsg } from '../utils'
import PersonalizeCard from '../components/donate/PersonalizeCard'
import PersonalizeCardEdit from '../components/donate/PersonalizeCardEdit'
import { useStore } from '../hooks/useStore'
import { State } from '../store/donateStore'
import { getSnapshot } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import PickStockCardEdit from '../components/donate/PickStockCardEdit'
import PickStockCard from '../components/donate/PickStockCard'
import { config } from '../service/api'
import PayCard from '../components/donate/PayCard'

function DonateScreen() {
  const { username } = useParams()
  const store = useStore()
  const snap = getSnapshot(store.Donate)
  const state = snap.state
  const [profile, setProfile] = useState<TrUserProfilePublic>()
  const [ err, setErr ] = useState('')

  useEffect(() => {
    async function run() {
      if(username) {
        const p = await getPublicUserByUsername('@' + username)
        if (axios.isAxiosError(p)) {
          setErr(getErrorMsg(p))
        } else {
          setProfile(p)
        }
      }
    }
    run()
  },[])

  if (config.env === 'prod')
    return null

  return (
    <Container maxWidth="md" sx={sx.container}>  
      <Box>
        <Typography>{username}</Typography> 
        <Typography>{JSON.stringify(profile)}</Typography> 
        { err && <Typography>{JSON.stringify(profile)}</Typography> }
      </Box>

      { username && (
        <Box sx={sx.profileRow}>
          <Avatar
            src="https://storage.googleapis.com/trellish-avatars/6o5khrFajmQM5BIC82wxuu4KZ0m1_68FDF3F9-0969-4354-A710-A01EAC6D07E2.jpg" 
            sx={{ width: 100, height: 100 }}
          />
          <Box sx={sx.nameUsername}>
            <Typography>{`${profile?.firstname} ${profile?.lastname}`}</Typography> 
            <Typography>{`@${profile?.username}`}</Typography> 
          </Box>
        </Box> 
      )}
  
      <Typography>Gift a Stock</Typography>
      {/* // https://mui.com/material-ui/react-stepper/ */}
      { state === 'personalize' ? <PersonalizeCardEdit /> : <PersonalizeCard /> }
      { state === 'stock' ? <PickStockCardEdit /> : <PickStockCard /> }
      { state === 'pay' && <PayCard /> }
    </Container>
  )
}
export default observer(DonateScreen)

const sx = {
  container: {
    backgroundColor: '#f0f0f0',
    paddingBottom: 2
  } as SxProps,
  profileRow: {
    display: 'flex',
    flexDirection: 'row'
  } as SxProps,
  nameUsername: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  } as SxProps,
}
