import { types, applySnapshot } from 'mobx-state-tree'
import { ACTION, Action, GiftInfo } from '../utils/types'
import { OrderSide, OrderType, TimeInForce, TrGiftRequest, TrGiftRequestAssetTypeEnum, TrGiftRequestGiftSourceEnum, TrGiftStatus, TrProductGiftPass, TrSnapshot, TrSymbolSummary, TrTradingCreateOrderRequest, TrTradingPosition } from '../sdk-ts-axios'
import { InitOrder } from './store'

const GiftStatus = types.model('GiftStatusStore', {
  giftStatus: types.maybe(types.string), // JSON, TrGiftStatus
}).actions(self => ({
  set(gift: TrGiftStatus) {
    self.giftStatus = JSON.stringify(gift)
  },
})).views(self => ({
  get(): TrGiftStatus | undefined {
    return self.giftStatus ? JSON.parse(self.giftStatus) : undefined
  },
}))

export const Order = types.model('OrderStore', {
  symbolSummary: types.maybe(types.string), // JSON, TrSymbolSummary
  action: types.maybe(types.string), // buy, sell, gift, giftcard as Action
  request: types.maybe(types.string), // JSON, TrTradingCreateOrderRequest
  pricing: types.maybe(types.string), // JSON, TrSnapshot
  position: types.maybe(types.string), // JSON, TrTradingPosition

  type: types.maybe(types.string), // as OrderType for TrTradingCreateOrderRequest
  timeInForce: types.maybe(types.string), // as TimeInForce for TrTradingCreateOrderRequest
  isSellAll: types.maybe(types.boolean),
  isInDollars: types.maybe(types.boolean), // false === shares
  amount: types.maybe(types.string),
  shares: types.maybe(types.string),
  limitPrice: types.maybe(types.string),
  stopPrice: types.maybe(types.string),
  trailPrice: types.maybe(types.string),
  showNotEnoughFunds: types.maybe(types.boolean), 
  offer: types.maybe(types.string), // JSON, TrTradingPosition

  // gift
  giftAmount: types.maybe(types.number),
  giftFee: types.maybe(types.number),
  assetType: types.maybe(types.string),
  giftSource: types.maybe(types.string),
  isWish: types.maybe(types.boolean),
  isGiftCard: types.maybe(types.boolean),
  surpriseDoNotNotifyReceiver: types.maybe(types.boolean),
  message: types.maybe(types.string),
  imageUrl: types.maybe(types.string),
  videoUrl: types.maybe(types.string),

  isSubmitted: types.maybe(types.boolean),
  giftStatus: GiftStatus,
  err: types.maybe(types.string),
}).actions(self => ({
  setRequest(req: TrTradingCreateOrderRequest) {
    self.request = JSON.stringify(req)
  },
  setPricing(pricing: TrSnapshot) {
    self.pricing = JSON.stringify(pricing)
  },
  setPosition(position: TrTradingPosition) {
    self.position = JSON.stringify(position)
  },
  setSymbolSummary(summary: TrSymbolSummary | undefined) {
    self.symbolSummary = summary ? JSON.stringify(summary) : undefined
  },
  setOffer(offer?: TrProductGiftPass) {
    self.offer = offer ? JSON.stringify(offer) : undefined
  },
  setAction(action: Action) {
    self.action = action
  },
  setAmount(amount: string) {  // must be string to accept '.' via textinput
    self.amount = amount
  },
  setShares(shares: string) {  // must be string to accept '.' via textinput
    self.shares = shares
  },
  setIsSellAll(isSellAll: boolean) {  // must be string to accept '.' via textinput
    self.isSellAll = isSellAll
  },
  setLimitPrice(limitPrice?: string) {  // must be string to accept '.' via textinput
    self.limitPrice = limitPrice
  },
  setStopPrice(stopPrice?: string) {  // must be string to accept '.' via textinput
    self.stopPrice = stopPrice
  },
  setTrailPrice(trailPrice?: string) {  // must be string to accept '.' via textinput
    self.trailPrice = trailPrice
  },
  setShowNotEnoughFunds(showNotEnoughFunds: boolean) {
    self.showNotEnoughFunds = showNotEnoughFunds ?? false
  },
  setIsGiftCard(isGiftCard: boolean) {
    self.isGiftCard = isGiftCard
  },
  setIsWish(isWish: boolean) {
    self.isWish = isWish
  },
  setTimeInForce(timeInForce: TimeInForce) {
    self.timeInForce = timeInForce
  },
  setOrderType(type: OrderType) {
    self.type = type
  },
  setIsInDollars(isInDollars?: boolean) {
    self.isInDollars = isInDollars ?? false
  },
  setSubmitted(isSubmitted: boolean) {
    self.isSubmitted = isSubmitted
  },
  setError(err?: string) { 
    self.err = err
  },
  clear() {
    applySnapshot(self, InitOrder)
  },

  // gift
  setGiftInfo(gift: GiftInfo) {
    self.action = gift.action
    self.symbolSummary = JSON.stringify(gift.symbolSummary)
    self.isWish = gift.is_wish
    self.giftAmount = gift.cash_gift_amount
    self.giftFee = gift.cash_fee_amount
    self.giftSource = gift.gift_source
    self.assetType = gift.asset_type
  },
  setMessage(msg: string) {
    self.message = msg
  },
  setImageUrl(imageUrl: string) {
    self.imageUrl = imageUrl
  },
  setSurpriseDoNotNotifyReceiver(surpriseDoNotNotifyReceiver: boolean) {
    self.surpriseDoNotNotifyReceiver = surpriseDoNotNotifyReceiver
  }
})).views(self => ({
  isEmpty() {
    // must be valid for gift.
    return self.action === undefined
  },
  getRequest(): TrTradingCreateOrderRequest | undefined {
    return self.request ? JSON.parse(self.request) : undefined
  },
  getSymbolSummary(): TrSymbolSummary | undefined {
    return self.symbolSummary ? JSON.parse(self.symbolSummary) : undefined
  },
  getPricing(): TrSnapshot | undefined {
    return self.pricing ? JSON.parse(self.pricing) : undefined
  },
  getPosition(): TrTradingPosition | undefined {
    return self.position ? JSON.parse(self.position) : undefined
  },
  getGiftInfo(): GiftInfo {
    return {
      symbolSummary: self.symbolSummary ? JSON.parse(self.symbolSummary) : undefined,
      action: self.action as Action,
      cash_gift_amount: self.giftAmount ?? 0,
      cash_fee_amount: self.giftFee ?? 0.5,
      gift_source: self.giftSource as TrGiftRequestGiftSourceEnum,
      asset_type: self.assetType as TrGiftRequestAssetTypeEnum,
      is_wish: self.isWish,
      is_unassigned: self.isGiftCard,
      message: self.message,
      image_url: self.imageUrl,
      video_url: self.videoUrl,
      notify_receiver: !self.surpriseDoNotNotifyReceiver,
    }
  },
  getAction() {
    return self.action as Action
  },
  getIsGift() {
    return self.action === ACTION.gift || self.action === ACTION.giftCard
  },
  getIsGiftCard(): boolean {
    return self.isGiftCard ?? false
  },
  getTimeInForce(): TimeInForce {
    return self.timeInForce as TimeInForce
  },
  getOrderType(): OrderType {
    return self.type ? self.type as OrderType : OrderType.Market
  },
  getIsInDollars() {
    return self.isInDollars
  },
  getAmount(): string {  // must be string to accept '.' via textinput
    return self.amount ?? ''
  },
  getShares(): string {  // must be string to accept '.' via textinput
    return self.shares ?? ''
  },
  getIsSellAll(): boolean {  // must be string to accept '.' via textinput
    return self.isSellAll ?? false
  },
  getLimitPrice(): string {  // must be string to accept '.' via textinput
    return self.limitPrice ?? ''
  },
  getStopPrice(): string {  // must be string to accept '.' via textinput
    return self.stopPrice ?? ''
  },
  getTrailPrice(): string {  // must be string to accept '.' via textinput
    return self.trailPrice ?? ''
  },
  getShowNotEnoughFunds(): boolean {
    return self.showNotEnoughFunds ?? false
  },
  getSurpriseDoNotNotifyReceiver(): boolean {
    return self.surpriseDoNotNotifyReceiver ?? false
  },
  getOffer(): TrProductGiftPass | undefined {
    return self.offer ? JSON.parse(self.offer) : undefined
  },
  getError() { 
    return self.err
  },
}))
