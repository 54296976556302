import React, { CSSProperties, useEffect, useState } from 'react'
import { Grid, Container, Box, SxProps, Card, TextField, Button, Typography } from '@mui/material'
import { Assets } from '../../service/gcpStorage'
interface Props {
  isSignUp: boolean
  disable: boolean
}

export default function GoogleSignIn({ isSignUp, disable }: Props) {

  const styles = {
    button: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      width: 250,
      border: 1,
      borderRadius: 2,
      padding: 1,
      borderColor: 'black',
      bgcolor: 'white',
      opacity: disable ? '25%' : '100%',
    } as SxProps
  }

  return (
    <Box sx={styles.button}>
      <Box
        component="img"
        sx={disable ? disableIcon : icon}
        src={Assets.googleIcon}
      />
      <Typography sx={disable ? disableText : Text}>
        {isSignUp? 'Sign up with Google' : 'Sign in with Google'}
      </Typography>
    </Box>
  )
}

const icon: SxProps = {
  width: 20,
  height: 20,
}
const disableIcon: SxProps = {
  width: 16,
  height: 16,
  opacity: 0.2
}
const disableText: SxProps = {
  color: 'lightgray'
}
const Text: SxProps = {

}
