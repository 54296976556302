import mixpanel from 'mixpanel-browser'

export function logInfo(msg: string, obj?: object) {
  obj ? console.log(msg, obj) : console.log(msg)
  mixpanel.track(msg, obj)
  // DdLogs.info(msg, obj) TODO
}

export function logWarn(msg: string, obj?: object) {
  obj ? console.warn(msg, obj) : console.warn(msg)
  // DdLogs.warn(msg, obj)
}

export function logErr(msg: string, obj?: object) {
  obj ? console.error(msg, obj) : console.error(msg)
  // DdLogs.error(msg, obj)
}
