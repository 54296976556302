import { types, Instance } from 'mobx-state-tree'
import { Me } from './meStore'
import { Custodial } from './myKidsStore'
import { Order } from './orderStore'
import { Recipient } from './recipientStore'
import { Test } from './testStore'
import { Security } from './securityStore'
import { Donate } from './donateStore'

export const RootStore = types.model('RootStore', {
  Me: Me,
  Order: Order,
  Recipient: Recipient,
  Security: Security,
  Donate: Donate,
  Custodial: Custodial, // used when viewing As... minor
  Test: Test
})

export type RootType = Instance<typeof RootStore>

export const InitMe = {
  profile: {},
  config: {},
  accountInfo: {},
  tradingAccount: {},
  policy: {},
  contacts: {},
  positions: {},
  orders: {},
  gifts: {},
  announcements: {},
  ach: {},
  bankTransfers: {},
  secondaryEmails: {},
  deals: {}
}

export const InitCustodial = {
  accounts: {},
  configs: {},
  profiles: {}
}

export const InitRecipient = {
  account: {},
  config: {},
  profile: {},
  position: {},
  tradingAccount: {},
  orders: {}
}

export const InitOrder = {
  giftStatus: {}
}

export const InitStore = {
  Me: InitMe,
  Custodial: InitCustodial,
  Order: InitOrder,
  Recipient: InitRecipient,
  Security: {},
  Donate: {},
  Test: {}
}
