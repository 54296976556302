import {
  Configuration,
  DefaultApi
} from '../sdk-ts-axios'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// env-cmd requires `REACT_APP` prefix
export const config = {
  env: process.env.REACT_APP_ENV,
  api: process.env.REACT_APP_API,
  apiKey: process.env.REACT_APP_API_KEY, // for stage/prod. get from Gitlab CI Vars
  hosting: process.env.REACT_APP_HOSTING,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  deepLink: process.env.REACT_APP_DEEP_LINK,
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
  datadogToken: process.env.REACT_APP_DATADOG_TOKEN,
  datadogAppId: process.env.REACT_APP_DATADOG_APP_ID
}

if(config.env !== 'prod') {
  console.log('config.env', config.env)
  console.log('config.api', config.api)
  console.log('config.hosting', config.hosting)
}

const app = initializeApp(config)
export const auth = getAuth(app)

const apiConfig = async () => {
  const token = await auth.currentUser?.getIdToken()
  return new Configuration({ 
    basePath: config.api,
    accessToken: token
  })
} 

export const Api = async () => new DefaultApi(await apiConfig())
