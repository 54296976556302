import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Routes from './routes'
import mixpanel from 'mixpanel-browser'
import { MixpanelProvider } from 'react-mixpanel-browser'
import { config } from './service/api'
import '@fontsource/roboto' 
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './styles/Theme'
import { StoreContext } from './context/context'
import { RootStore, InitStore } from './store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if(!config.mixpanelToken) 
  console.error('missing mixpanel token')
else
  mixpanel.init(config.mixpanelToken)

root.render(<Main />)

function Main() {
  const [rootStore] = useState(() => RootStore.create(InitStore))
  return (
    <MixpanelProvider mixpanel={mixpanel}>
      <StoreContext.Provider value={rootStore}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </StoreContext.Provider>
    </MixpanelProvider>
  )
}