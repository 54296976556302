import { TrGiftRequestAssetTypeEnum, TrGiftRequestGiftSourceEnum, TrSymbolSummary } from '../sdk-ts-axios'

export const ACTION = {
  buy: 'buy',
  sell: 'sell',
  gift: 'gift',
  giftCard: 'giftCard'
}
export type Action = 'buy' | 'sell' | 'gift' | 'giftCard'

export const SSN_LENGTH = /^.{11,}$/ // 999-99-9999

export enum MoneyRangeText {
  Less20k = '< $20,000',
  Between20k49k = '$20,000 - $49,999',
  Between50k99k = '$50,000 - $99,999',
  Between100k499k = '$100,000 - $499,999',
  Between500k1M = '$500,000 - $999,999',
  More1M = '> 1,000,000'
}

// Giftinfo is a subset of TrGiftRequest with action & symbol summary
export interface GiftInfo {
  action: Action
  symbolSummary?: TrSymbolSummary
  cash_gift_amount: number
  cash_fee_amount: number
  gift_source: TrGiftRequestGiftSourceEnum
  asset_type: TrGiftRequestAssetTypeEnum
  is_wish?: boolean
  is_unassigned?: boolean
  message?: string
  notify_sender?: boolean,
  notify_receiver?: boolean,
  image_url?: string
  video_url?: string
}
