import { Box } from '@mui/material'
import React from 'react'
import { TrGiftStatusPublic } from '../sdk-ts-axios'

interface Props {
  gift: TrGiftStatusPublic
}

export default function GiftMessageWithBackup({ gift }: Props) {
  return (
    <Box>
      { gift?.message ? (
        <>
          <div>
            {gift.message.split(/\n/).map(line => <div key={line}>{line}</div>)}
          </div>
        </> 
      ) : (
        <div>
          <Box>{`Hi ${gift?.receiver_name}`}</Box>
          <Box>{`You're amazing. We've gifted you ${gift?.cash_gift_amount ?? gift?.secs_net_amount} of ${gift?.symbol} Stock.`}</Box>
          <div />
          <Box>{'Cheers,'}</Box>
          <Box>{` - ${gift.sender_name}`}</Box>
        </div>
      )}
    </Box>
   
  )
}
