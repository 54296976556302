import _ from 'lodash'
import { types, SnapshotOut, applySnapshot, flow } from 'mobx-state-tree'
import { AccountStatus, Announcement, TrAccountInfo, TrACHRelationshipData, TrEmail, TrGiftStatus, TrProductGiftPass, TrTradingAccount, TrTradingOrder, TrTradingPosition, TrTransferResource, TrUserConfig, TrUserPolicy, TrUserProfile } from '../sdk-ts-axios'
import { getCustodialAccounts, setContactsLineup, setMyConfig, setMyKidsLineup } from '../service'
import { InitMe } from './store'

// https://mobx-state-tree.js.org/tips/typescript
export type IUser = SnapshotOut<typeof Me>

const Policy = types.model('PolicyStore', {
  policy: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(policy?: TrUserPolicy) {
    self.policy = policy ? JSON.stringify(policy) : undefined
  }
})).views(self => ({
  get(): TrUserPolicy | undefined {
    return self.policy ? JSON.parse(self.policy) : undefined
  }
}))

const Contacts = types.model('ContactsStore', {
  contacts: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(contacts: TrUserProfile[]) {
    self.contacts = JSON.stringify(contacts)
  },
})).views(self => ({
  get(): TrUserProfile[] {
    return self.contacts ? JSON.parse(self.contacts) : []
  },
}))

export const Profile = types.model('ProfileStore', {
  profile: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(profile?: TrUserProfile) {
    self.profile = profile ? JSON.stringify(profile) : undefined
  },
  setProfileUrl(url?: string) {
    const obj: TrUserProfile = self.profile ? JSON.parse(self.profile) : undefined
    if (obj && url) {
      const newObj: TrUserProfile = {
        ...obj,
        profile_url: url
      }
      self.profile = JSON.stringify(newObj)
    }
  },
})).views(self => ({
  get(): TrUserProfile {
    return self.profile ? JSON.parse(self.profile) : undefined
  },
}))

export const Config = types.model('ConfigStore', {
  config: types.maybe(types.string), // JSON blob
  myKidsLineup: types.optional(types.array(types.string), []),
  contactsLineup: types.optional(types.array(types.string), [])
}).actions(self => ({
  set: flow(function* (config: TrUserConfig, skipSaveToDb?: boolean) {
    self.config = JSON.stringify(config)
    if (!skipSaveToDb)
      yield setMyConfig(config)
  }),
  setMyKidsLineup: flow(function* (lineup: TrUserProfile[]) {
    // save to store
    const lineupIds = lineup
      .map(c => c.id)
      .filter((i): i is string => !! i)

    const isEqual = _.isEqual(lineupIds, self.myKidsLineup)
    if (!isEqual) {
      self.myKidsLineup.clear()
      for (const id of lineupIds) {
        self.myKidsLineup.push(id)
      }
      const config: TrUserConfig | undefined = self.config ? JSON.parse(self.config) : undefined
      if (config)
        yield setMyKidsLineup(config, lineup)  // save to backend
    }
  }),
  setContactsLineup: flow(function* (lineup: TrUserProfile[]) {
    const lineupIds = lineup
      .map(c => c.id)
      .filter((i): i is string => !! i)
      
    const isEqual = _.isEqual(lineupIds, self.contactsLineup)
    if (!isEqual) {
      self.contactsLineup.clear()
      for (const id of lineupIds) {
        self.contactsLineup.push(id)
      }
      const config: TrUserConfig | undefined = self.config ? JSON.parse(self.config) : undefined
      if (config)
        yield setContactsLineup(config,lineup)
    }
  }),
})).views(self => ({
  get(): TrUserConfig {
    return self.config ? JSON.parse(self.config) : []
  },
  getMyKidsLineup(): string[] {
    return self.myKidsLineup
  },
  getContactsLineup(): string[] {
    return self.contactsLineup
  },
}))

export const AccountInfo = types.model('AccountInfoStore', {
  accountInfo: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(account: TrAccountInfo | undefined) {
    self.accountInfo = account ? JSON.stringify(account) : undefined
  },
})).views(self => ({
  get(): TrAccountInfo | undefined {
    return self.accountInfo ? JSON.parse(self.accountInfo) : undefined
  },
}))

export const TradingAccount = types.model('TradingAccountStore', {
  tradingAccount: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(account: TrTradingAccount | undefined) {
    self.tradingAccount = account ? JSON.stringify(account) : undefined
  },
})).views(self => ({
  get(): TrTradingAccount |  undefined {
    return self.tradingAccount ? JSON.parse(self.tradingAccount) : undefined
  },
}))

export const Positions = types.model('PositionsStore', {
  positions: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(list: TrTradingPosition[]) {
    self.positions = JSON.stringify(list)
  },  
})).views(self => ({
  get(): TrTradingPosition[] {
    return self.positions ? JSON.parse(self.positions) : []
  },
}))

export const Orders = types.model('OrdersStore', {
  orders: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(orders: TrTradingOrder[]) {
    self.orders = JSON.stringify(orders)
  }, 
})).views(self => ({
  get(): TrTradingOrder[] {
    return self.orders ? JSON.parse(self.orders) : []
  },
}))

const Gifts = types.model('GiftsStore', {
  gifts: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(list: TrGiftStatus[]) {
    self.gifts = JSON.stringify(list)
  },
})).views(self => ({
  get(): TrGiftStatus[] {
    return self.gifts ? JSON.parse(self.gifts) :[]
  },
}))

const Announcements = types.model('AnnouncementsStore', {
  announcements: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(announcements?: Announcement[]) {
    self.announcements = announcements ? JSON.stringify(announcements) : undefined
  }
})).views(self => ({
  get(): Announcement[] | undefined {
    return self.announcements ? JSON.parse(self.announcements) : undefined
  }
}))

const ACH = types.model('AchStore', {
  ach: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(achData?: TrACHRelationshipData) {
    self.ach = achData ? JSON.stringify(achData) : undefined
  }
})).views(self => ({
  get(): TrACHRelationshipData | undefined {
    return self.ach ? JSON.parse(self.ach) : undefined
  }
}))

const BankTransfers = types.model('BankTransfersStore', {
  transfers: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(transfers?: TrTransferResource[]) {
    self.transfers = transfers ? JSON.stringify(transfers) : undefined
  }
})).views(self => ({
  get(): TrTransferResource[] | undefined {
    return self.transfers ? JSON.parse(self.transfers) : undefined
  }
}))

const SecondaryEmails = types.model('SecondaryEmailsStore', {
  emails: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(secondaryEmails?: TrEmail[]) {
    self.emails = secondaryEmails ? JSON.stringify(secondaryEmails) : undefined
  }
})).views(self => ({
  get(): TrEmail[] | undefined {
    return self.emails ? JSON.parse(self.emails) : undefined
  }
}))

const Deals = types.model('DealsStore', {
  deals: types.maybe(types.string), // JSON blob
}).actions(self => ({
  set(deals?: TrProductGiftPass[]) {
    self.deals = deals ? JSON.stringify(deals) : undefined
  }
})).views(self => ({
  get(): TrProductGiftPass[] | undefined {
    return self.deals ? JSON.parse(self.deals) : undefined
  }
}))

export const Me = types.model('UserStore', {
  profile: Profile,
  config: Config,
  contacts: Contacts,
  accountInfo: AccountInfo,
  tradingAccount: TradingAccount,
  policy: Policy,
  positions: Positions,
  orders: Orders,
  announcements: Announcements,
  gifts: Gifts,
  ach: ACH,
  bankTransfers: BankTransfers,
  secondaryEmails: SecondaryEmails,
  deals: Deals,

  isSetupMode: types.maybe(types.boolean),
}).actions(self => ({
  clear() {
    applySnapshot(self, InitMe)
  },
})).views(self => ({

}))
