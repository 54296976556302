/* tslint:disable */
/* eslint-disable */
/**
 * Trellish API
 * Trellish API external and internal
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ACHRelationship
 */
export interface ACHRelationship {
    /**
     * 
     * @type {string}
     * @memberof ACHRelationship
     */
    'id'?: string;
    /**
     * Format: 2020-01-01T01:01:01Z
     * @type {string}
     * @memberof ACHRelationship
     */
    'created_at'?: string;
    /**
     * Format: 2020-01-01T01:01:01Z
     * @type {string}
     * @memberof ACHRelationship
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ACHRelationship
     */
    'account_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ACHRelationship
     */
    'status'?: ACHRelationshipStatusEnum;
    /**
     * Name of the account owner
     * @type {string}
     * @memberof ACHRelationship
     */
    'account_owner_name'?: string;
    /**
     * Must be CHECKING or SAVINGS
     * @type {string}
     * @memberof ACHRelationship
     */
    'bank_account_type'?: ACHRelationshipBankAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ACHRelationship
     */
    'bank_account_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ACHRelationship
     */
    'bank_routing_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ACHRelationship
     */
    'nickname'?: string;
}

export const ACHRelationshipStatusEnum = {
    Queued: 'QUEUED',
    Approved: 'APPROVED',
    Pending: 'PENDING',
    CancelRequested: 'CANCEL_REQUESTED'
} as const;

export type ACHRelationshipStatusEnum = typeof ACHRelationshipStatusEnum[keyof typeof ACHRelationshipStatusEnum];
export const ACHRelationshipBankAccountTypeEnum = {
    Checking: 'CHECKING',
    Savings: 'SAVINGS'
} as const;

export type ACHRelationshipBankAccountTypeEnum = typeof ACHRelationshipBankAccountTypeEnum[keyof typeof ACHRelationshipBankAccountTypeEnum];

/**
 * Represents additional configuration settings for an account
 * @export
 * @interface AccountConfigurations
 */
export interface AccountConfigurations {
    /**
     * both, entry, or exit. Controls Day Trading Margin Call (DTMC) checks.
     * @type {string}
     * @memberof AccountConfigurations
     */
    'dtbp_check': AccountConfigurationsDtbpCheckEnum;
    /**
     * all or none. If none, emails for order fills are not sent.
     * @type {string}
     * @memberof AccountConfigurations
     */
    'trade_confirm_email': AccountConfigurationsTradeConfirmEmailEnum;
    /**
     * If true, new orders are blocked.
     * @type {boolean}
     * @memberof AccountConfigurations
     */
    'suspend_trade': boolean;
    /**
     * If true, account becomes long-only mode.
     * @type {boolean}
     * @memberof AccountConfigurations
     */
    'no_shorting': boolean;
    /**
     * If true, account is able to participate in fractional trading
     * @type {boolean}
     * @memberof AccountConfigurations
     */
    'fractional_trading': boolean;
    /**
     * Can be \"1\" or \"2\"
     * @type {string}
     * @memberof AccountConfigurations
     */
    'max_margin_multiplier': string;
    /**
     * 
     * @type {string}
     * @memberof AccountConfigurations
     */
    'pdt_check': string;
}

export const AccountConfigurationsDtbpCheckEnum = {
    Both: 'both',
    Entry: 'entry',
    Exit: 'exit'
} as const;

export type AccountConfigurationsDtbpCheckEnum = typeof AccountConfigurationsDtbpCheckEnum[keyof typeof AccountConfigurationsDtbpCheckEnum];
export const AccountConfigurationsTradeConfirmEmailEnum = {
    All: 'all',
    None: 'none'
} as const;

export type AccountConfigurationsTradeConfirmEmailEnum = typeof AccountConfigurationsTradeConfirmEmailEnum[keyof typeof AccountConfigurationsTradeConfirmEmailEnum];

/**
 * Represents the fields required to create a new account
 * @export
 * @interface AccountCreationRequest
 */
export interface AccountCreationRequest {
    /**
     * 
     * @type {Array<AssetClass>}
     * @memberof AccountCreationRequest
     */
    'enabled_assets'?: Array<AssetClass>;
    /**
     * 
     * @type {Contact}
     * @memberof AccountCreationRequest
     */
    'contact': Contact;
    /**
     * 
     * @type {Identity}
     * @memberof AccountCreationRequest
     */
    'identity': Identity;
    /**
     * 
     * @type {Disclosures}
     * @memberof AccountCreationRequest
     */
    'disclosures': Disclosures;
    /**
     * The client has to present the Alpaca Account and Margin Agreements to the end user, and have them read full sentences.
     * @type {Array<Agreement>}
     * @memberof AccountCreationRequest
     */
    'agreements': Array<Agreement>;
    /**
     * 
     * @type {Array<DocumentUploadRequest>}
     * @memberof AccountCreationRequest
     */
    'documents'?: Array<DocumentUploadRequest>;
    /**
     * 
     * @type {TrustedContact}
     * @memberof AccountCreationRequest
     */
    'trusted_contact'?: TrustedContact;
    /**
     * 
     * @type {AccountType}
     * @memberof AccountCreationRequest
     */
    'account_type'?: AccountType;
    /**
     * 
     * @type {CustodialAccountMinorIdentity}
     * @memberof AccountCreationRequest
     */
    'minor_identity'?: CustodialAccountMinorIdentity;
}
/**
 * 
 * @export
 * @interface AccountStatementOrTradeConfirmation
 */
export interface AccountStatementOrTradeConfirmation {
    /**
     * 
     * @type {string}
     * @memberof AccountStatementOrTradeConfirmation
     */
    'truncated_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountStatementOrTradeConfirmation
     */
    'type'?: string;
}
/**
 * Designates the current status of this account  Possible Values: - **ONBOARDING** An application is expected for this user, but has not been submitted yet. - **SUBMITTED** The application has been submitted and in process. - **RESUBMITTED** Resubmitted is used to display when request has been re-submitted to Apex after account update - **SUBMISSION_FAILED** Used to display if failure on submission - **ACTION_REQUIRED** The application requires manual action. - **EDITED** Application was edited (e.g. to match info from uploaded docs). This is a transient status. - **ACCOUNT_UPDATED** Used to display when Account has been modified by user - **APPROVAL_PENDING** Initial value. The application approval process is in process. - **REAPPROVAL_PENDING** This is a transient status used to display once apex approves a re-submission - **SIGNED_UP** Users who were directed to and competed the [first stage](https://app.alpaca.markets/signup) of v2 registration - **KYC_SUBMITTED** Users that have had their KYC submitted to the routed KYC Provider - **LIMITED** Limited Users that pass KYC but are missing financial_information and employment_details - **AML_REVIEW** Users that pass KYC but from a restricted/high risk country and need manual AML approval - **APPROVED** The account application has been approved, and waiting to be ACTIVE - **REJECTED** The account application is rejected for some reason - **ACTIVE** The account is fully active. Trading and funding are processed under this status. - **DISABLED** The account is disabled after ACTIVE status. - **DISABLE_PENDING** DisablePending is used for accounts which requested to be disabled, but have not been processed yet. - **ACCOUNT_CLOSED** The account is closed. - **PAPER_ONLY** Used to display when only paper trading is allowed for this account 
 * @export
 * @enum {string}
 */

export const AccountStatus = {
    Onboarding: 'ONBOARDING',
    Submitted: 'SUBMITTED',
    Resubmitted: 'RESUBMITTED',
    SubmissionFailed: 'SUBMISSION_FAILED',
    ActionRequired: 'ACTION_REQUIRED',
    Edited: 'EDITED',
    AccountUpdated: 'ACCOUNT_UPDATED',
    ApprovalPending: 'APPROVAL_PENDING',
    ReapprovalPending: 'REAPPROVAL_PENDING',
    SignedUp: 'SIGNED_UP',
    KycSubmitted: 'KYC_SUBMITTED',
    Limited: 'LIMITED',
    AmlReview: 'AML_REVIEW',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Active: 'ACTIVE',
    Disabled: 'DISABLED',
    DisablePending: 'DISABLE_PENDING',
    AccountClosed: 'ACCOUNT_CLOSED',
    PaperOnly: 'PAPER_ONLY'
} as const;

export type AccountStatus = typeof AccountStatus[keyof typeof AccountStatus];


/**
 * Possible values are:  - trading - custodial - donor_advised - traditional - traditional_rollover - roth_rollover - roth - trust
 * @export
 * @enum {string}
 */

export const AccountType = {
    Trading: 'trading',
    Custodial: 'custodial',
    DonorAdvised: 'donor_advised',
    Traditional: 'traditional',
    TraditionalRollover: 'traditional_rollover',
    RothRollover: 'roth_rollover',
    Roth: 'roth',
    Trust: 'trust'
} as const;

export type AccountType = typeof AccountType[keyof typeof AccountType];


/**
 * 
 * @export
 * @interface AchPaymentInfo
 */
export interface AchPaymentInfo {
    /**
     * 
     * @type {string}
     * @memberof AchPaymentInfo
     */
    'bank_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AchPaymentInfo
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof AchPaymentInfo
     */
    'routing_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof AchPaymentInfo
     */
    'account_number'?: string;
}
/**
 * Represents the various kinds of activity.  TradeActivity\'s will always have the type `FILL`  - **FILL**   Order Fills (Partial/Full) - **ACATC**   ACATS IN/OUT (Cash) - **ACATS**   ACATS IN/OUT (Securities) - **CIL**   Cash in Lieu of Stock - **CSD**   Cash Disbursement (+) - **CSW**   Cash Withdrawable - **DIV**   Dividend - **DIVCGL**   Dividend (Capital Gain Long Term) - **DIVCGS**   Dividend (Capital Gain Short Term) - **DIVNRA**   Dividend Adjusted (NRA Withheld) - **DIVROC**   Dividend Return of Capital - **DIVTXEX**   Dividend (Tax Exempt) - **FEE**   REG and TAF Fees - **INT**   Interest (Credit/Margin) - **JNLC**   Journal Entry (Cash) - **JNLS**   Journal Entry (Stock) - **MA**   Merger/Acquisition - **PTC**   Pass Thru Change - **REORG**   Reorg CA - **SPIN**   Stock Spinoff - **SPLIT**   Stock Split
 * @export
 * @enum {string}
 */

export const ActivityType = {
    Fill: 'FILL',
    Acatc: 'ACATC',
    Acats: 'ACATS',
    Cil: 'CIL',
    Csd: 'CSD',
    Csw: 'CSW',
    Div: 'DIV',
    Divcgl: 'DIVCGL',
    Divcgs: 'DIVCGS',
    Divnra: 'DIVNRA',
    Divroc: 'DIVROC',
    Divtxex: 'DIVTXEX',
    Fee: 'FEE',
    Int: 'INT',
    Jnlc: 'JNLC',
    Jnls: 'JNLS',
    Ma: 'MA',
    Ptc: 'PTC',
    Reorg: 'REORG',
    Spin: 'SPIN',
    Split: 'SPLIT'
} as const;

export type ActivityType = typeof ActivityType[keyof typeof ActivityType];


/**
 * 
 * @export
 * @interface AddMySecondaryEmailRequest
 */
export interface AddMySecondaryEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof AddMySecondaryEmailRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface Agreement
 */
export interface Agreement {
    /**
     * 
     * @type {AgreementType}
     * @memberof Agreement
     */
    'agreement': AgreementType;
    /**
     * 
     * @type {string}
     * @memberof Agreement
     */
    'signed_at': string;
    /**
     * 
     * @type {string}
     * @memberof Agreement
     */
    'ip_address': string;
    /**
     * 
     * @type {string}
     * @memberof Agreement
     */
    'revision'?: string;
}
/**
 * 
 * @export
 * @interface Agreement1
 */
export interface Agreement1 {
    /**
     * 
     * @type {AgreementType}
     * @memberof Agreement1
     */
    'agreement': AgreementType;
    /**
     * 
     * @type {string}
     * @memberof Agreement1
     */
    'signed_at': string;
    /**
     * 
     * @type {string}
     * @memberof Agreement1
     */
    'ip_address': string;
    /**
     * 
     * @type {string}
     * @memberof Agreement1
     */
    'revision'?: string;
}
/**
 * - margin_agreement: Alpaca Margin Agreement - account_agreement: Alpaca Account Agreement - customer_agreement: Alpaca Customer Agreement - crypto_agreement: Alpaca Crypto agreement - custodian_customer_agreement: Alpaca Custodian agreement 
 * @export
 * @enum {string}
 */

export const AgreementType = {
    MarginAgreement: 'margin_agreement',
    AccountAgreement: 'account_agreement',
    CustomerAgreement: 'customer_agreement',
    CryptoAgreement: 'crypto_agreement',
    CustodianCustomerAgreement: 'custodian_customer_agreement'
} as const;

export type AgreementType = typeof AgreementType[keyof typeof AgreementType];


/**
 * The announcements endpoint contains public information on previous and upcoming dividends, mergers, spinoffs, and stock splits.  Announcement data is made available through the API as soon as it is ingested by Alpaca, which is typically the following trading day after the declaration date. This provides insight into future account stock position and cash balance changes that will take effect on an announcement’s payable date. Additionally, viewing previous announcement details can improve bookkeeping and reconciling previous account cash and position changes.
 * @export
 * @interface Announcement
 */
export interface Announcement {
    /**
     * ID that is specific to a single announcement.
     * @type {string}
     * @memberof Announcement
     */
    'id'?: string;
    /**
     * ID that remains consistent across all announcements for the same corporate action. Unlike ‘id’, this can be used to connect multiple announcements to see how the terms have changed throughout the lifecycle of the corporate action event.
     * @type {string}
     * @memberof Announcement
     */
    'corporate_action_id'?: string;
    /**
     * 
     * @type {AnnouncementCAType}
     * @memberof Announcement
     */
    'ca_type'?: AnnouncementCAType;
    /**
     * 
     * @type {AnnouncementCASubType}
     * @memberof Announcement
     */
    'ca_sub_type'?: AnnouncementCASubType;
    /**
     * Symbol of the company initiating the announcement.
     * @type {string}
     * @memberof Announcement
     */
    'initiating_symbol'?: string;
    /**
     * CUSIP of the company initiating the announcement.
     * @type {string}
     * @memberof Announcement
     */
    'initiating_original_cusip'?: string;
    /**
     * Symbol of the child company involved in the announcement.
     * @type {string}
     * @memberof Announcement
     */
    'target_symbol'?: string | null;
    /**
     * CUSIP of the child company involved in the announcement.
     * @type {string}
     * @memberof Announcement
     */
    'target_original_cusip'?: string | null;
    /**
     * Date the corporate action or subsequent terms update was announced.
     * @type {string}
     * @memberof Announcement
     */
    'declaration_date'?: string;
    /**
     * The first date that purchasing a security will not result in a corporate action entitlement.
     * @type {string}
     * @memberof Announcement
     */
    'ex_date'?: string | null;
    /**
     * The date an account must hold a settled position in the security in order to receive the corporate action entitlement.
     * @type {string}
     * @memberof Announcement
     */
    'record_date'?: string | null;
    /**
     * The date the announcement will take effect. On this date, account stock and cash balances are expected to be processed accordingly.
     * @type {string}
     * @memberof Announcement
     */
    'pay_date'?: string;
    /**
     * The amount of cash to be paid per share held by an account on the record date.
     * @type {string}
     * @memberof Announcement
     */
    'cash'?: string | null;
    /**
     * The denominator to determine any quantity change ratios in positions.
     * @type {string}
     * @memberof Announcement
     */
    'old_rate'?: string | null;
    /**
     * The numerator to determine any quantity change ratios in positions.
     * @type {string}
     * @memberof Announcement
     */
    'new_rate'?: string | null;
    /**
     * number of dividends per year. sometimes shows.
     * @type {number}
     * @memberof Announcement
     */
    'frequency'?: number;
}
/**
 * Announcements have both a type and a subtype to categorize them. This model represents the lowever level abstract \"sub types\" of Announcement. Please see the AnnouncementCAType model for higher level descriptions of the possible types  Possible values are:  - from the `dividend` type:   - **cash**      A cash payment based on the number of shares the account holds on the record date.   - **stock**      A stock payment based on the number of shares the account holds on the record date.  - from the `merger` type:   - **merger_update**      An update to the terms of an upcoming merger. This can happen any number of times before the merger is completed and can be tracked by using the id parameter.    - **merger_completion**      A final update in the terms of the merger in which the intiating_symbol will acquire the target_symbol. Any previous terms updates for this announcement will have the same id value.  - from the `split` type:   - **stock_split**      An increase in the number of shares outstanding with a decrease in the dollar value of each share. The new_rate and old_rate parameters will be returned in order to derive the ratio of the split   - **until_split**      An increase in the number of shares outstanding with a decrease in the dollar value of each share. The new_rate and old_rate parameters will be returned in order to derive the ratio of the split.   - **reverse_split**      A decrease in the number of shares outstanding with an increase in the dollar value of each share. The new_rate and old_rate parameters will be returned in order to derive the ratio of the spli   - **recapitalization**      A stock recapitalization, typically used by a company to adjust debt and equity ratios.  - from the `spinoff` type:   - **spinoff**      A disbursement of a newly tradable security when the intiating_symbol creates the target_symbol.
 * @export
 * @enum {string}
 */

export const AnnouncementCASubType = {
    Cash: 'cash',
    Stock: 'stock',
    MergerUpdate: 'merger_update',
    MergerCompletion: 'merger_completion',
    StockSplit: 'stock_split',
    UntilSplit: 'until_split',
    ReverseSplit: 'reverse_split',
    Recapitalization: 'recapitalization',
    Spinoff: 'spinoff'
} as const;

export type AnnouncementCASubType = typeof AnnouncementCASubType[keyof typeof AnnouncementCASubType];


/**
 * Announcements have both a type and a subtype to categorize them. This model represents the higher level abstract \"types\" of Announcement. Please see the AnnouncementCASubType model for finer grain descriptions of the subtypes  Possible values are: - dividend   can have `cash` and `stock` subtypes - merger   has `merger_update` and `merger_completion` sub types - split   has `stock_split`, `until_split`, `reverse_split`, and `recapitalization` sub types - spinoff   currently has only the `spinoff` subtype and thus is just this higher level category for now. A disbursement of a newly tradable security when the intiating_symbol creates the target_symbol.
 * @export
 * @enum {string}
 */

export const AnnouncementCAType = {
    Dividend: 'dividend',
    Merger: 'merger',
    Split: 'split',
    Spinoff: 'spinoff'
} as const;

export type AnnouncementCAType = typeof AnnouncementCAType[keyof typeof AnnouncementCAType];


/**
 * If an account has documents on the application submission, it has the ApplicationDocument model in exchange with DocumentUpload. 
 * @export
 * @interface ApplicationDocument
 */
export interface ApplicationDocument {
    /**
     * 
     * @type {string}
     * @memberof ApplicationDocument
     */
    'id': string;
    /**
     * 
     * @type {DocumentType}
     * @memberof ApplicationDocument
     */
    'document_type': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDocument
     */
    'document_sub_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDocument
     */
    'mime_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDocument
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationDocument
     */
    'created_at': string;
}
/**
 * Assets are sorted by asset class, exchange and symbol. Some assets are not tradable with Alpaca. These assets will be marked with the flag tradable=false
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * Asset ID
     * @type {string}
     * @memberof Asset
     */
    'id'?: string;
    /**
     * 
     * @type {AssetClass}
     * @memberof Asset
     */
    'class'?: AssetClass;
    /**
     * 
     * @type {Exchange}
     * @memberof Asset
     */
    'exchange'?: Exchange;
    /**
     * The symbol of the asset
     * @type {string}
     * @memberof Asset
     */
    'symbol'?: string;
    /**
     * The official name of the asset
     * @type {string}
     * @memberof Asset
     */
    'name'?: string;
    /**
     * active or inactive
     * @type {string}
     * @memberof Asset
     */
    'status'?: AssetStatusEnum;
    /**
     * Asset is tradable on Alpaca or not
     * @type {boolean}
     * @memberof Asset
     */
    'tradable'?: boolean;
    /**
     * Asset is marginable or not
     * @type {boolean}
     * @memberof Asset
     */
    'marginable'?: boolean;
    /**
     * Asset is shortable or not
     * @type {boolean}
     * @memberof Asset
     */
    'shortable'?: boolean;
    /**
     * Asset is easy-to-borrow or not (filtering for easy_to_borrow = True is the best way to check whether the name is currently available to short at Alpaca).
     * @type {boolean}
     * @memberof Asset
     */
    'easy_to_borrow'?: boolean;
    /**
     * Asset is fractionable or not
     * @type {boolean}
     * @memberof Asset
     */
    'fractionable'?: boolean;
    /**
     * Percent change for the trading day as of last market closure. NOTE: This field is currently in this spec however it may not be present in the production environment at time of publishing. It will be coming in a future update at which point this spec should be updated.
     * @type {string}
     * @memberof Asset
     */
    'last_close_pct_change'?: string;
    /**
     * Most recent available price for this asset on the market. NOTE: This field is currently in this spec however it may not be present in the production environment at time of publishing. It will be coming in a future update at which point this spec should be updated.
     * @type {string}
     * @memberof Asset
     */
    'last_price'?: string;
    /**
     * Last price of the asset upon market closure on the most recent trading day. NOTE: This field is currently in this spec however it may not be present in the production environment at time of publishing. It will be coming in a future update at which point this spec should be updated.
     * @type {string}
     * @memberof Asset
     */
    'last_close'?: string;
}

export const AssetStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type AssetStatusEnum = typeof AssetStatusEnum[keyof typeof AssetStatusEnum];

/**
 * Assets are sorted by asset class, exchange and symbol. Some assets are not tradable with Alpaca. These assets will be marked with the flag tradable=false
 * @export
 * @interface Asset1
 */
export interface Asset1 {
    /**
     * Asset ID
     * @type {string}
     * @memberof Asset1
     */
    'id'?: string;
    /**
     * 
     * @type {AssetClass}
     * @memberof Asset1
     */
    'class'?: AssetClass;
    /**
     * 
     * @type {Exchange}
     * @memberof Asset1
     */
    'exchange'?: Exchange;
    /**
     * The symbol of the asset
     * @type {string}
     * @memberof Asset1
     */
    'symbol'?: string;
    /**
     * The official name of the asset
     * @type {string}
     * @memberof Asset1
     */
    'name'?: string;
    /**
     * active or inactive
     * @type {string}
     * @memberof Asset1
     */
    'status'?: Asset1StatusEnum;
    /**
     * Asset is tradable on Alpaca or not
     * @type {boolean}
     * @memberof Asset1
     */
    'tradable'?: boolean;
    /**
     * Asset is marginable or not
     * @type {boolean}
     * @memberof Asset1
     */
    'marginable'?: boolean;
    /**
     * Asset is shortable or not
     * @type {boolean}
     * @memberof Asset1
     */
    'shortable'?: boolean;
    /**
     * Asset is easy-to-borrow or not (filtering for easy_to_borrow = True is the best way to check whether the name is currently available to short at Alpaca).
     * @type {boolean}
     * @memberof Asset1
     */
    'easy_to_borrow'?: boolean;
    /**
     * Asset is fractionable or not
     * @type {boolean}
     * @memberof Asset1
     */
    'fractionable'?: boolean;
    /**
     * Percent change for the trading day as of last market closure. NOTE: This field is currently in this spec however it may not be present in the production environment at time of publishing. It will be coming in a future update at which point this spec should be updated.
     * @type {string}
     * @memberof Asset1
     */
    'last_close_pct_change'?: string;
    /**
     * Most recent available price for this asset on the market. NOTE: This field is currently in this spec however it may not be present in the production environment at time of publishing. It will be coming in a future update at which point this spec should be updated.
     * @type {string}
     * @memberof Asset1
     */
    'last_price'?: string;
    /**
     * Last price of the asset upon market closure on the most recent trading day. NOTE: This field is currently in this spec however it may not be present in the production environment at time of publishing. It will be coming in a future update at which point this spec should be updated.
     * @type {string}
     * @memberof Asset1
     */
    'last_close'?: string;
}

export const Asset1StatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type Asset1StatusEnum = typeof Asset1StatusEnum[keyof typeof Asset1StatusEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const AssetClass = {
    UsEquity: 'us_equity',
    Crypto: 'crypto'
} as const;

export type AssetClass = typeof AssetClass[keyof typeof AssetClass];


/**
 * The calendar API serves the full list of market days from 1970 to 2029. It can also be queried by specifying a start and/or end time to narrow down the results. In addition to the dates, the response also contains the specific open and close times for the market days, taking into account early closures.
 * @export
 * @interface Calendar
 */
export interface Calendar {
    /**
     * Date string in YYYY-MM-DD format
     * @type {string}
     * @memberof Calendar
     */
    'date': string;
    /**
     * The time the market opens at on this date in HH:MM format
     * @type {string}
     * @memberof Calendar
     */
    'open': string;
    /**
     * The time the market closes at on this date in HH:MM format
     * @type {string}
     * @memberof Calendar
     */
    'close': string;
    /**
     * this field has been deprecated please ignore
     * @type {string}
     * @memberof Calendar
     * @deprecated
     */
    'session_open'?: string;
    /**
     * this field has been deprecated please ignore
     * @type {string}
     * @memberof Calendar
     * @deprecated
     */
    'session_close'?: string;
}
/**
 * Represents the current market time and open/close events.
 * @export
 * @interface Clock
 */
export interface Clock {
    /**
     * Current timestamp
     * @type {string}
     * @memberof Clock
     */
    'timestamp': string;
    /**
     * Whether the market is open or not
     * @type {boolean}
     * @memberof Clock
     */
    'is_open': boolean;
    /**
     * Next market open timestamp
     * @type {string}
     * @memberof Clock
     */
    'next_open': string;
    /**
     * Next market close timestamp
     * @type {string}
     * @memberof Clock
     */
    'next_close': string;
}
/**
 * Contact is the model for the account owner contact information. 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'email_address'?: string;
    /**
     * with country code, no hyphen or space
     * @type {string}
     * @memberof Contact
     */
    'phone_number'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Contact
     */
    'street_address'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'postal_code'?: string;
}
/**
 * Represents the fields used in creation of a new ACHRelationship.  You can create an ACHRelationship by passing the required fields here or if you have an account with Plaid you can use our integration with Plaid to create a relationship.  Please see the documentation [here](https://alpaca.markets/docs/api-references/broker-api/funding/ach/#plaid-integration-for-bank-transfers) for more info on using Plaid with Alpaca
 * @export
 * @interface CreateACHRelationshipRequest
 */
export interface CreateACHRelationshipRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateACHRelationshipRequest
     */
    'account_owner_name': string;
    /**
     * Must be CHECKING or SAVINGS
     * @type {string}
     * @memberof CreateACHRelationshipRequest
     */
    'bank_account_type': CreateACHRelationshipRequestBankAccountTypeEnum;
    /**
     * In sandbox, this still must be a valid format
     * @type {string}
     * @memberof CreateACHRelationshipRequest
     */
    'bank_account_number': string;
    /**
     * In sandbox, this still must be a valid format
     * @type {string}
     * @memberof CreateACHRelationshipRequest
     */
    'bank_routing_number': string;
    /**
     * 
     * @type {string}
     * @memberof CreateACHRelationshipRequest
     */
    'nickname'?: string;
    /**
     * If using Plaid, you can specify a Plaid processor token here 
     * @type {string}
     * @memberof CreateACHRelationshipRequest
     */
    'processor_token'?: string;
}

export const CreateACHRelationshipRequestBankAccountTypeEnum = {
    Checking: 'CHECKING',
    Savings: 'SAVINGS'
} as const;

export type CreateACHRelationshipRequestBankAccountTypeEnum = typeof CreateACHRelationshipRequestBankAccountTypeEnum[keyof typeof CreateACHRelationshipRequestBankAccountTypeEnum];

/**
 * 
 * @export
 * @interface CreateOrderRequest
 */
export interface CreateOrderRequest {
    /**
     * Symbol or asset ID to identify the asset to trade
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'symbol': string;
    /**
     * Number of shares to trade. Can be fractionable for only market and day order types.
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'qty'?: string;
    /**
     * Dollar amount to trade. Cannot work with qty. Can only work for market order types and time_in_force = day.
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'notional'?: string;
    /**
     * 
     * @type {OrderSide}
     * @memberof CreateOrderRequest
     */
    'side': OrderSide;
    /**
     * 
     * @type {OrderType}
     * @memberof CreateOrderRequest
     */
    'type': OrderType;
    /**
     * 
     * @type {TimeInForce}
     * @memberof CreateOrderRequest
     */
    'time_in_force': TimeInForce;
    /**
     * Required if type is limit or stop_limit
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'limit_price'?: string;
    /**
     * Required if type is stop or stop_limit
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'stop_price'?: string;
    /**
     * If type is trailing_stop, then one of trail_price or trail_percent is required
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'trail_price'?: string;
    /**
     * If type is trailing_stop, then one of trail_price or trail_percent is required
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'trail_percent'?: string;
    /**
     * Defaults to false. If true, order will be eligible to execute in premarket/afterhours. Only works with type limit and time_in_force = day.
     * @type {boolean}
     * @memberof CreateOrderRequest
     */
    'extended_hours'?: boolean;
    /**
     * A unique identifier for the order. Automatically generated if not sent. (<= 48 characters)
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'client_order_id'?: string;
    /**
     * 
     * @type {OrderClass}
     * @memberof CreateOrderRequest
     */
    'order_class'?: OrderClass;
    /**
     * 
     * @type {CreateOrderRequestTakeProfit}
     * @memberof CreateOrderRequest
     */
    'take_profit'?: CreateOrderRequestTakeProfit;
    /**
     * 
     * @type {CreateOrderRequestStopLoss}
     * @memberof CreateOrderRequest
     */
    'stop_loss'?: CreateOrderRequestStopLoss;
    /**
     * The commission you want to collect from the user.
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'commission'?: string;
}
/**
 * Takes in a string/number values for stop_price and limit_price
 * @export
 * @interface CreateOrderRequestStopLoss
 */
export interface CreateOrderRequestStopLoss {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequestStopLoss
     */
    'stop_price'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequestStopLoss
     */
    'limit_price'?: string;
}
/**
 * Takes in a string/number value for limit_price
 * @export
 * @interface CreateOrderRequestTakeProfit
 */
export interface CreateOrderRequestTakeProfit {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequestTakeProfit
     */
    'limit_price'?: string;
}
/**
 * Represents Identity information for a minor that an account of type \"custodial\" is for
 * @export
 * @interface CustodialAccountMinorIdentity
 */
export interface CustodialAccountMinorIdentity {
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity
     */
    'tax_id'?: string;
    /**
     * 
     * @type {TaxIdType}
     * @memberof CustodialAccountMinorIdentity
     */
    'tax_id_type'?: TaxIdType;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity
     */
    'country_of_citizenship'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity
     */
    'country_of_birth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity
     */
    'country_of_tax_residence': string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity
     */
    'email': string;
}
/**
 * Represents Identity information for a minor that an account of type \"custodial\" is for
 * @export
 * @interface CustodialAccountMinorIdentity1
 */
export interface CustodialAccountMinorIdentity1 {
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity1
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity1
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity1
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity1
     */
    'tax_id'?: string;
    /**
     * 
     * @type {TaxIdType}
     * @memberof CustodialAccountMinorIdentity1
     */
    'tax_id_type'?: TaxIdType;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity1
     */
    'country_of_citizenship'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity1
     */
    'country_of_birth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity1
     */
    'country_of_tax_residence': string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity1
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof CustodialAccountMinorIdentity1
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface DisclosureContextAnnotation
 */
export interface DisclosureContextAnnotation {
    /**
     * Specifies the type of disclosure annotation. Valid types are FINRA affiliations, for users affiliated with or employed by a FINRA member firm, a Stock Exchange Member, FINRA, Registered Investment Advisor, or a Municipal Securities Broker/Dealer; Company control relationships, for senior executives, and 10% or greater shareholders, of a publicly traded company; and immediate family members of politically exposed individuals.
     * @type {string}
     * @memberof DisclosureContextAnnotation
     */
    'context_type': DisclosureContextAnnotationContextTypeEnum;
    /**
     * Required for FINRA affiliations and controlled firms.
     * @type {string}
     * @memberof DisclosureContextAnnotation
     */
    'company_name'?: string;
    /**
     * Required for FINRA affiliations and controlled firms.
     * @type {string}
     * @memberof DisclosureContextAnnotation
     */
    'company_street_address'?: string;
    /**
     * Required for FINRA affiliations and controlled firms.
     * @type {string}
     * @memberof DisclosureContextAnnotation
     */
    'company_city'?: string;
    /**
     * Required if and only if `company_country` is `USA`.
     * @type {string}
     * @memberof DisclosureContextAnnotation
     */
    'company_state'?: string;
    /**
     * Required for FINRA affiliations and controlled firms.
     * @type {string}
     * @memberof DisclosureContextAnnotation
     */
    'company_country'?: string;
    /**
     * Required for FINRA affiliations and controlled firms.
     * @type {string}
     * @memberof DisclosureContextAnnotation
     */
    'company_compliance_email'?: string;
    /**
     * Required for immediate family members of politically exposed persons.
     * @type {string}
     * @memberof DisclosureContextAnnotation
     */
    'given_name'?: string;
    /**
     * Required for immediate family members of politically exposed persons.
     * @type {string}
     * @memberof DisclosureContextAnnotation
     */
    'family_name'?: string;
}

export const DisclosureContextAnnotationContextTypeEnum = {
    ControlledFirm: 'CONTROLLED_FIRM',
    ImmediateFamilyExposed: 'IMMEDIATE_FAMILY_EXPOSED',
    AffiliateFirm: 'AFFILIATE_FIRM'
} as const;

export type DisclosureContextAnnotationContextTypeEnum = typeof DisclosureContextAnnotationContextTypeEnum[keyof typeof DisclosureContextAnnotationContextTypeEnum];

/**
 * Disclosures fields denote if the account owner falls under each category defined by FINRA rule. The client has to ask questions for the end user and the values should reflect their answers. If one of the answers is true (yes), the account goes into ACTION_REQUIRED status. 
 * @export
 * @interface Disclosures
 */
export interface Disclosures {
    /**
     * 
     * @type {string}
     * @memberof Disclosures
     */
    'employment_status'?: DisclosuresEmploymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Disclosures
     */
    'employer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Disclosures
     */
    'employer_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Disclosures
     */
    'employment_position'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Disclosures
     */
    'is_control_person': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Disclosures
     */
    'is_affiliated_exchange_or_finra': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Disclosures
     */
    'is_politically_exposed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Disclosures
     */
    'immediate_family_exposed': boolean;
    /**
     * Array of annotations describing the rational for marking `is_control_person`, `is_affiliated_exchange_or_finra`, and/or `immediate_family_exposed` as true
     * @type {Array<DisclosureContextAnnotation>}
     * @memberof Disclosures
     */
    'context'?: Array<DisclosureContextAnnotation> | null;
}

export const DisclosuresEmploymentStatusEnum = {
    Unemployed: 'unemployed',
    Employed: 'employed',
    Student: 'student',
    Retired: 'retired'
} as const;

export type DisclosuresEmploymentStatusEnum = typeof DisclosuresEmploymentStatusEnum[keyof typeof DisclosuresEmploymentStatusEnum];

/**
 * - identity_verification:   identity verification document  - address_verification:   address verification document  - date_of_birth_verification:   date of birth verification document  - tax_id_verification:   tax ID verification document  - account_approval_letter:   407 approval letter  - cip_result:   initial CIP result 
 * @export
 * @enum {string}
 */

export const DocumentType = {
    IdentityVerification: 'identity_verification',
    AddressVerification: 'address_verification',
    DateOfBirthVerification: 'date_of_birth_verification',
    TaxIdVerification: 'tax_id_verification',
    AccountApprovalLetter: 'account_approval_letter',
    CipResult: 'cip_result'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * If an account has documents after the submission, it has the Document model in exchange with DocumentUploadRequest. 
 * @export
 * @interface DocumentUploadRequest
 */
export interface DocumentUploadRequest {
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentUploadRequest
     */
    'document_type': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadRequest
     */
    'document_sub_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadRequest
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadRequest
     */
    'mime_type': string;
}
/**
 * 
 * @export
 * @interface EmailVerification
 */
export interface EmailVerification {
    /**
     * 
     * @type {string}
     * @memberof EmailVerification
     */
    'code'?: string;
}
/**
 * Represents the exchange where an asset is traded.  For Stocks: - AMEX - ARCA - BATS - NYSE - NASDAQ - NYSEARCA - OTC  For Crypto: - ERSX - FTXU
 * @export
 * @enum {string}
 */

export const Exchange = {
    Amex: 'AMEX',
    Arca: 'ARCA',
    Bats: 'BATS',
    Nyse: 'NYSE',
    Nasdaq: 'NASDAQ',
    Nysearca: 'NYSEARCA',
    Otc: 'OTC',
    Ersx: 'ERSX',
    Ftxu: 'FTXU'
} as const;

export type Exchange = typeof Exchange[keyof typeof Exchange];


/**
 * Identity is the model to provide account owner’s identity information. 
 * @export
 * @interface Identity
 */
export interface Identity {
    /**
     * 
     * @type {string}
     * @memberof Identity
     */
    'given_name': string;
    /**
     * trellish manually updated
     * @type {string}
     * @memberof Identity
     */
    'middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Identity
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof Identity
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof Identity
     */
    'tax_id'?: string;
    /**
     * 
     * @type {TaxIdType}
     * @memberof Identity
     */
    'tax_id_type'?: TaxIdType;
    /**
     * [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html). 
     * @type {string}
     * @memberof Identity
     */
    'country_of_citizenship'?: string;
    /**
     * [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html). 
     * @type {string}
     * @memberof Identity
     */
    'country_of_birth'?: string;
    /**
     * [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html). 
     * @type {string}
     * @memberof Identity
     */
    'country_of_tax_residence': string;
    /**
     * trellish manually updated
     * @type {string}
     * @memberof Identity
     */
    'visa_type'?: IdentityVisaTypeEnum;
    /**
     * trellish manually updated
     * @type {string}
     * @memberof Identity
     */
    'visa_expiration_date'?: string;
    /**
     * trellish manually updated
     * @type {string}
     * @memberof Identity
     */
    'date_of_departure_from_usa'?: string;
    /**
     * trellish manually updated
     * @type {boolean}
     * @memberof Identity
     */
    'permanent_resident'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Identity
     */
    'funding_source': Array<IdentityFundingSourceEnum>;
    /**
     * 
     * @type {number}
     * @memberof Identity
     */
    'annual_income_min'?: number;
    /**
     * 
     * @type {number}
     * @memberof Identity
     */
    'annual_income_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof Identity
     */
    'liquid_net_worth_min'?: number;
    /**
     * 
     * @type {number}
     * @memberof Identity
     */
    'liquid_net_worth_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof Identity
     */
    'total_net_worth_min'?: number;
    /**
     * 
     * @type {number}
     * @memberof Identity
     */
    'total_net_worth_max'?: number;
    /**
     * any extra information used for KYC purposes 
     * @type {object}
     * @memberof Identity
     */
    'extra'?: object;
}

export const IdentityVisaTypeEnum = {
    B1: 'B1',
    B2: 'B2',
    Daca: 'DACA',
    E1: 'E1',
    E2: 'E2',
    E3: 'E3',
    F1: 'F1',
    G4: 'G4',
    H1B: 'H1B',
    J1: 'J1',
    L1: 'L1',
    Other: 'OTHER',
    O1: 'O1',
    Tn1: 'TN1'
} as const;

export type IdentityVisaTypeEnum = typeof IdentityVisaTypeEnum[keyof typeof IdentityVisaTypeEnum];
export const IdentityFundingSourceEnum = {
    EmploymentIncome: 'employment_income',
    Investments: 'investments',
    Inheritance: 'inheritance',
    BusinessIncome: 'business_income',
    Savings: 'savings',
    Family: 'family'
} as const;

export type IdentityFundingSourceEnum = typeof IdentityFundingSourceEnum[keyof typeof IdentityFundingSourceEnum];

/**
 * Identity is the model to provide account owner’s identity information. 
 * @export
 * @interface Identity1
 */
export interface Identity1 {
    /**
     * 
     * @type {string}
     * @memberof Identity1
     */
    'given_name': string;
    /**
     * trellish manually updated
     * @type {string}
     * @memberof Identity1
     */
    'middle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Identity1
     */
    'family_name': string;
    /**
     * 
     * @type {string}
     * @memberof Identity1
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof Identity1
     */
    'tax_id'?: string;
    /**
     * 
     * @type {TaxIdType}
     * @memberof Identity1
     */
    'tax_id_type'?: TaxIdType;
    /**
     * [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html). 
     * @type {string}
     * @memberof Identity1
     */
    'country_of_citizenship'?: string;
    /**
     * [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html). 
     * @type {string}
     * @memberof Identity1
     */
    'country_of_birth'?: string;
    /**
     * [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html). 
     * @type {string}
     * @memberof Identity1
     */
    'country_of_tax_residence': string;
    /**
     * trellish manually updated
     * @type {string}
     * @memberof Identity1
     */
    'visa_type'?: Identity1VisaTypeEnum;
    /**
     * trellish manually updated
     * @type {string}
     * @memberof Identity1
     */
    'visa_expiration_date'?: string;
    /**
     * trellish manually updated
     * @type {string}
     * @memberof Identity1
     */
    'date_of_departure_from_usa'?: string;
    /**
     * trellish manually updated
     * @type {boolean}
     * @memberof Identity1
     */
    'permanent_resident'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Identity1
     */
    'funding_source': Array<Identity1FundingSourceEnum>;
    /**
     * 
     * @type {number}
     * @memberof Identity1
     */
    'annual_income_min'?: number;
    /**
     * 
     * @type {number}
     * @memberof Identity1
     */
    'annual_income_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof Identity1
     */
    'liquid_net_worth_min'?: number;
    /**
     * 
     * @type {number}
     * @memberof Identity1
     */
    'liquid_net_worth_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof Identity1
     */
    'total_net_worth_min'?: number;
    /**
     * 
     * @type {number}
     * @memberof Identity1
     */
    'total_net_worth_max'?: number;
    /**
     * any extra information used for KYC purposes 
     * @type {object}
     * @memberof Identity1
     */
    'extra'?: object;
}

export const Identity1VisaTypeEnum = {
    B1: 'B1',
    B2: 'B2',
    Daca: 'DACA',
    E1: 'E1',
    E2: 'E2',
    E3: 'E3',
    F1: 'F1',
    G4: 'G4',
    H1B: 'H1B',
    J1: 'J1',
    L1: 'L1',
    Other: 'OTHER',
    O1: 'O1',
    Tn1: 'TN1'
} as const;

export type Identity1VisaTypeEnum = typeof Identity1VisaTypeEnum[keyof typeof Identity1VisaTypeEnum];
export const Identity1FundingSourceEnum = {
    EmploymentIncome: 'employment_income',
    Investments: 'investments',
    Inheritance: 'inheritance',
    BusinessIncome: 'business_income',
    Savings: 'savings',
    Family: 'family'
} as const;

export type Identity1FundingSourceEnum = typeof Identity1FundingSourceEnum[keyof typeof Identity1FundingSourceEnum];

/**
 * Hold information about the result of KYC. Please see the documentation [here](https://alpaca.markets/docs/api-references/broker-api/events/#kyc-results) for more indepth details
 * @export
 * @interface KYCResult
 */
export interface KYCResult {
    /**
     * 
     * @type {object}
     * @memberof KYCResult
     */
    'reject'?: object;
    /**
     * 
     * @type {object}
     * @memberof KYCResult
     */
    'accept'?: object;
    /**
     * 
     * @type {object}
     * @memberof KYCResult
     */
    'indeterminate'?: object;
    /**
     * 
     * @type {string}
     * @memberof KYCResult
     */
    'addidional_information'?: string;
    /**
     * 
     * @type {string}
     * @memberof KYCResult
     */
    'summary'?: string;
}
/**
 * LinkTokenCreateResponse defines the response schema for `/link/token/create`
 * @export
 * @interface LinkTokenCreateResponse
 */
export interface LinkTokenCreateResponse {
    [key: string]: any;

    /**
     * A `link_token`, which can be supplied to Link in order to initialize it and receive a `public_token`, which can be exchanged for an `access_token`.
     * @type {string}
     * @memberof LinkTokenCreateResponse
     */
    'link_token': string;
    /**
     * The expiration date for the `link_token`, in [ISO 8601](https://wikipedia.org/wiki/ISO_8601) format. A `link_token` created to generate a `public_token` that will be exchanged for a new `access_token` expires after 4 hours. A `link_token` created for an existing Item (such as when updating an existing `access_token` by launching Link in update mode) expires after 30 minutes.
     * @type {string}
     * @memberof LinkTokenCreateResponse
     */
    'expiration': string;
    /**
     * A unique identifier for the request, which can be used for troubleshooting. This identifier, like all Plaid identifiers, is case sensitive.
     * @type {string}
     * @memberof LinkTokenCreateResponse
     */
    'request_id': string;
}
/**
 * 
 * @export
 * @interface MetaData
 */
export interface MetaData {
    /**
     * 
     * @type {Array<string>}
     * @memberof MetaData
     */
    'bcc_emails'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MetaData
     */
    'dedup_timeout_mins'?: number;
}
/**
 * 
 * @export
 * @interface NonTradeActivity
 */
export interface NonTradeActivity {
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof NonTradeActivity
     */
    'date'?: string;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof NonTradeActivity
     */
    'net_amount'?: string;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof NonTradeActivity
     */
    'description'?: string;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof NonTradeActivity
     */
    'status'?: NonTradeActivityStatusEnum;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof NonTradeActivity
     */
    'symbol'?: string;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof NonTradeActivity
     */
    'qty'?: string;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof NonTradeActivity
     */
    'per_share_amount'?: string;
}

export const NonTradeActivityStatusEnum = {
    Executed: 'executed',
    Correct: 'correct',
    Canceled: 'canceled'
} as const;

export type NonTradeActivityStatusEnum = typeof NonTradeActivityStatusEnum[keyof typeof NonTradeActivityStatusEnum];

/**
 * 
 * @export
 * @interface NotifyGift
 */
export interface NotifyGift {
    /**
     * 
     * @type {string}
     * @memberof NotifyGift
     */
    'gift_id'?: string;
    /**
     * gift sender\'s first and last name
     * @type {string}
     * @memberof NotifyGift
     */
    'sender_name'?: string;
    /**
     * gift reciever\'s first and last name
     * @type {string}
     * @memberof NotifyGift
     */
    'receiver_name'?: string;
    /**
     * receiver email, so the reciever knows what account to sign up or into.
     * @type {string}
     * @memberof NotifyGift
     */
    'receiver_email'?: string;
    /**
     * receiver username, so the reciever knows what account to sign up or into.
     * @type {string}
     * @memberof NotifyGift
     */
    'receiver_username'?: string;
    /**
     * if minor, gift reciever\'s guardian first and last name
     * @type {string}
     * @memberof NotifyGift
     */
    'receiver_guardian_name'?: string;
    /**
     * if minor, receiver\'s guardiam email, so the reciever knows what account to sign up or into.
     * @type {string}
     * @memberof NotifyGift
     */
    'receiver_guardian_email'?: string;
    /**
     * if minor, receiver\'s guardian username, so the reciever knows what account to sign up or into.
     * @type {string}
     * @memberof NotifyGift
     */
    'receiver_guardian_username'?: string;
    /**
     * gift asset type
     * @type {object}
     * @memberof NotifyGift
     */
    'asset_type'?: NotifyGiftAssetTypeEnum;
    /**
     * when sender wants gift wish stock(s)
     * @type {boolean}
     * @memberof NotifyGift
     */
    'is_wish'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotifyGift
     */
    'symbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotifyGift
     */
    'secs_qty'?: number;
    /**
     * securities dollar amount on gift initialization
     * @type {number}
     * @memberof NotifyGift
     */
    'secs_net_amount'?: number;
    /**
     * cash gift
     * @type {number}
     * @memberof NotifyGift
     */
    'cash_gift_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotifyGift
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifyGift
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifyGift
     */
    'video_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifyGift
     */
    'status'?: NotifyGiftStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NotifyGift
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifyGift
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifyGift
     */
    'symbol_url'?: string;
}

export const NotifyGiftAssetTypeEnum = {
    Cash: 'cash',
    Secs: 'secs'
} as const;

export type NotifyGiftAssetTypeEnum = typeof NotifyGiftAssetTypeEnum[keyof typeof NotifyGiftAssetTypeEnum];
export const NotifyGiftStatusEnum = {
    Initialized: 'initialized',
    EscrowInProgress: 'escrow_in_progress',
    RedeemReady: 'redeem_ready',
    RedeemInitialized: 'redeem_initialized',
    RedeemInProgressSecsBuy: 'redeem_in_progress_secs_buy',
    RedeemInProgressSecsJournal: 'redeem_in_progress_secs_journal',
    Completed: 'completed',
    CancelInitialized: 'cancel_initialized',
    CancelInProgress: 'cancel_in_progress',
    Cancelled: 'cancelled'
} as const;

export type NotifyGiftStatusEnum = typeof NotifyGiftStatusEnum[keyof typeof NotifyGiftStatusEnum];

/**
 * 
 * @export
 * @interface NotifyGiftAllOf
 */
export interface NotifyGiftAllOf {
    /**
     * 
     * @type {string}
     * @memberof NotifyGiftAllOf
     */
    'symbol_url'?: string;
}
/**
 * 
 * @export
 * @interface NotifyTransfer
 */
export interface NotifyTransfer {
    /**
     * 
     * @type {number}
     * @memberof NotifyTransfer
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotifyTransfer
     */
    'direction'?: NotifyTransferDirectionEnum;
}

export const NotifyTransferDirectionEnum = {
    Deposit: 'Deposit',
    Withdrawal: 'Withdrawal'
} as const;

export type NotifyTransferDirectionEnum = typeof NotifyTransferDirectionEnum[keyof typeof NotifyTransferDirectionEnum];

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'client_order_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'submitted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'filled_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'expired_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'canceled_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'failed_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'replaced_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'replaced_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'replaces'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'asset_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'symbol': string;
    /**
     * 
     * @type {AssetClass}
     * @memberof Order
     */
    'asset_class'?: AssetClass;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'notional'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'qty'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'filled_qty'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'filled_avg_price'?: string | null;
    /**
     * 
     * @type {OrderClass}
     * @memberof Order
     */
    'order_class'?: OrderClass;
    /**
     * 
     * @type {OrderType}
     * @memberof Order
     */
    'order_type'?: OrderType;
    /**
     * 
     * @type {OrderType}
     * @memberof Order
     */
    'type'?: OrderType;
    /**
     * 
     * @type {OrderSide}
     * @memberof Order
     */
    'side'?: OrderSide;
    /**
     * 
     * @type {TimeInForce}
     * @memberof Order
     */
    'time_in_force'?: TimeInForce;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'limit_price'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'stop_price'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof Order
     */
    'status'?: OrderStatus;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    'extended_hours'?: boolean;
    /**
     * 
     * @type {Array<Order>}
     * @memberof Order
     */
    'legs'?: Array<Order> | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'trail_price'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'trail_percent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'hwm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'commission'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderClass = {
    Simple: 'simple',
    Bracket: 'bracket',
    Oco: 'oco',
    Oto: 'oto'
} as const;

export type OrderClass = typeof OrderClass[keyof typeof OrderClass];


/**
 * Represents what side of the transaction an order was on.
 * @export
 * @enum {string}
 */

export const OrderSide = {
    Buy: 'buy',
    Sell: 'sell',
    BuyMinus: 'buy_minus',
    SellPlus: 'sell_plus',
    SellShort: 'sell_short',
    SellShortExempt: 'sell_short_exempt',
    Undisclosed: 'undisclosed',
    Cross: 'cross',
    CrossShort: 'cross_short'
} as const;

export type OrderSide = typeof OrderSide[keyof typeof OrderSide];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderStatus = {
    New: 'new',
    PartiallyFilled: 'partially_filled',
    Filled: 'filled',
    DoneForDay: 'done_for_day',
    Canceled: 'canceled',
    Expired: 'expired',
    Replaced: 'replaced',
    PendingCancel: 'pending_cancel',
    PendingReplace: 'pending_replace',
    Accepted: 'accepted',
    PendingNew: 'pending_new',
    AcceptedForBidding: 'accepted_for_bidding',
    Stopped: 'stopped',
    Rejected: 'rejected',
    Suspended: 'suspended',
    Calculated: 'calculated'
} as const;

export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderType = {
    Market: 'market',
    Limit: 'limit',
    Stop: 'stop',
    StopLimit: 'stop_limit',
    TrailingStop: 'trailing_stop'
} as const;

export type OrderType = typeof OrderType[keyof typeof OrderType];


/**
 * 
 * @export
 * @interface PlaidLinkAccount
 */
export interface PlaidLinkAccount {
    /**
     * 
     * @type {string}
     * @memberof PlaidLinkAccount
     */
    'VerificationStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaidLinkAccount
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaidLinkAccount
     */
    'mask'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaidLinkAccount
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaidLinkAccount
     */
    'subtype'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaidLinkAccount
     */
    'type'?: string;
}
/**
 * Timeseries data for equity and profit loss information of the account.
 * @export
 * @interface PortfolioHistory
 */
export interface PortfolioHistory {
    /**
     * time of each data element, left-labeled (the beginning of time window)
     * @type {Array<number>}
     * @memberof PortfolioHistory
     */
    'timestamp': Array<number>;
    /**
     * equity value of the account in dollar amount as of the end of each time window
     * @type {Array<number>}
     * @memberof PortfolioHistory
     */
    'equity': Array<number>;
    /**
     * profit/loss in dollar from the base value
     * @type {Array<number>}
     * @memberof PortfolioHistory
     */
    'profit_loss': Array<number>;
    /**
     * profit/loss in percentage from the base value
     * @type {Array<number>}
     * @memberof PortfolioHistory
     */
    'profit_loss_pct': Array<number>;
    /**
     * basis in dollar of the profit loss calculation
     * @type {number}
     * @memberof PortfolioHistory
     */
    'base_value': number | null;
    /**
     * time window size of each data element
     * @type {string}
     * @memberof PortfolioHistory
     */
    'timeframe': string | null;
}
/**
 * 
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * Asset ID
     * @type {string}
     * @memberof Position
     */
    'asset_id': string;
    /**
     * Asset symbol
     * @type {string}
     * @memberof Position
     */
    'symbol': string;
    /**
     * Exchange name of the asset
     * @type {string}
     * @memberof Position
     */
    'exchange': string;
    /**
     * 
     * @type {AssetClass}
     * @memberof Position
     */
    'asset_class': AssetClass;
    /**
     * Indicates if this asset is marginable
     * @type {boolean}
     * @memberof Position
     */
    'asset_marginable'?: boolean;
    /**
     * Average entry price of the position
     * @type {string}
     * @memberof Position
     */
    'avg_entry_price': string;
    /**
     * The number of shares
     * @type {string}
     * @memberof Position
     */
    'qty': string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'side': PositionSideEnum;
    /**
     * Total dollar amount of the position
     * @type {string}
     * @memberof Position
     */
    'market_value': string;
    /**
     * Total cost basis in dollar
     * @type {string}
     * @memberof Position
     */
    'cost_basis': string;
    /**
     * Unrealized profit/loss in dollars
     * @type {string}
     * @memberof Position
     */
    'unrealized_pl': string;
    /**
     * Unrealized profit/loss percent (by a factor of 1)
     * @type {string}
     * @memberof Position
     */
    'unrealized_plpc': string;
    /**
     * Unrealized profit/loss in dollars for the day
     * @type {string}
     * @memberof Position
     */
    'unrealized_intraday_pl': string;
    /**
     * Unrealized interday profit/loss percent (by a factor of 1)
     * @type {string}
     * @memberof Position
     */
    'unrealized_intraday_plpc': string;
    /**
     * Current asset price per share
     * @type {string}
     * @memberof Position
     */
    'current_price': string;
    /**
     * Last day’s asset price per share based on the closing value of the last trading day
     * @type {string}
     * @memberof Position
     */
    'lastday_price': string;
    /**
     * Percent change from last day price (by a factor of 1)
     * @type {string}
     * @memberof Position
     */
    'change_today': string;
}

export const PositionSideEnum = {
    Long: 'long',
    Short: 'short'
} as const;

export type PositionSideEnum = typeof PositionSideEnum[keyof typeof PositionSideEnum];

/**
 * 
 * @export
 * @interface ResponseError
 */
export interface ResponseError {
    /**
     * 
     * @type {number}
     * @memberof ResponseError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseError
     */
    'message': string;
}
/**
 * An Enum of the various kinds of Tax ID formats Alpaca supports.  Possible Values are:   - **USA_SSN** USA Social Security Number  - **ARG_AR_CUIT** Argentina CUIT  - **AUS_TFN** Australian Tax File Number  - **AUS_ABN** Australian Business Number  - **BOL_NIT** Bolivia NIT  - **BRA_CPF** Brazil CPF  - **CHL_RUT** Chile RUT  - **COL_NIT** Colombia NIT  - **CRI_NITE** Costa Rica NITE  - **DEU_TAX_ID** Germany Tax ID (Identifikationsnummer)  - **DOM_RNC** Dominican Republic RNC  - **ECU_RUC** Ecuador RUC  - **FRA_SPI** France SPI (Reference Tax Number)  - **GBR_UTR** UK UTR (Unique Taxpayer Reference)  - **GBR_NINO** UK NINO (National Insurance Number)  - **GTM_NIT** Guatemala NIT  - **HND_RTN** Honduras RTN  - **HUN_TIN** Hungary TIN Number  - **IDN_KTP** Indonesia KTP  - **IND_PAN** India PAN Number  - **ISR_TAX_ID** Israel Tax ID (Teudat Zehut)  - **ITA_TAX_ID** Italy Tax ID (Codice Fiscale)  - **JPN_TAX_ID** Japan Tax ID (Koijin Bango)  - **MEX_RFC** Mexico RFC  - **NIC_RUC** Nicaragua RUC  - **NLD_TIN** Netherlands TIN Number  - **PAN_RUC** Panama RUC  - **PER_RUC** Peru RUC  - **PRY_RUC** Paraguay RUC  - **SGP_NRIC** Singapore NRIC  - **SGP_FIN** Singapore FIN  - **SGP_ASGD** Singapore ASGD  - **SGP_ITR** Singapore ITR  - **SLV_NIT** El Salvador NIT  - **SWE_TAX_ID** Sweden Tax ID (Personnummer)  - **URY_RUT** Uruguay RUT  - **VEN_RIF** Venezuela RIF  - **NOT_SPECIFIED** Other Tax IDs
 * @export
 * @enum {string}
 */

export const TaxIdType = {
    NotSpecified: 'NOT_SPECIFIED',
    UsaSsn: 'USA_SSN',
    ArgAgCuit: 'ARG_AG_CUIT',
    AusTfn: 'AUS_TFN',
    AusAbn: 'AUS_ABN',
    BolNit: 'BOL_NIT',
    BraCpf: 'BRA_CPF',
    ChlRut: 'CHL_RUT',
    ColNit: 'COL_NIT',
    CriNite: 'CRI_NITE',
    DeuTaxId: 'DEU_TAX_ID',
    DomRnc: 'DOM_RNC',
    EcuRuc: 'ECU_RUC',
    FraSpi: 'FRA_SPI',
    GbrUtr: 'GBR_UTR',
    GbrNino: 'GBR_NINO',
    GtmNit: 'GTM_NIT',
    HndRtn: 'HND_RTN',
    HunTin: 'HUN_TIN',
    IdnKtp: 'IDN_KTP',
    IndPan: 'IND_PAN',
    IsrTaxId: 'ISR_TAX_ID',
    ItaTaxId: 'ITA_TAX_ID',
    JpnTaxId: 'JPN_TAX_ID',
    MexRfc: 'MEX_RFC',
    NicRuc: 'NIC_RUC',
    NldTin: 'NLD_TIN',
    PanRuc: 'PAN_RUC',
    PerRuc: 'PER_RUC',
    PryRuc: 'PRY_RUC',
    SgpNric: 'SGP_NRIC',
    SgpFin: 'SGP_FIN',
    SgpAsgd: 'SGP_ASGD',
    SgpItr: 'SGP_ITR',
    SlvNit: 'SLV_NIT',
    SweTaxId: 'SWE_TAX_ID',
    UryRut: 'URY_RUT',
    VenRif: 'VEN_RIF'
} as const;

export type TaxIdType = typeof TaxIdType[keyof typeof TaxIdType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TimeInForce = {
    Day: 'day',
    Gtc: 'gtc',
    Opg: 'opg',
    Cls: 'cls',
    Ioc: 'ioc',
    Fok: 'fok'
} as const;

export type TimeInForce = typeof TimeInForce[keyof typeof TimeInForce];


/**
 * ACH relationship
 * @export
 * @interface TrACHRelationshipData
 */
export interface TrACHRelationshipData {
    /**
     * 
     * @type {string}
     * @memberof TrACHRelationshipData
     */
    'id'?: string;
    /**
     * Format: 2020-01-01T01:01:01Z
     * @type {string}
     * @memberof TrACHRelationshipData
     */
    'created_at'?: string;
    /**
     * Format: 2020-01-01T01:01:01Z
     * @type {string}
     * @memberof TrACHRelationshipData
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrACHRelationshipData
     */
    'account_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrACHRelationshipData
     */
    'status'?: TrACHRelationshipDataStatusEnum;
    /**
     * Name of the account owner
     * @type {string}
     * @memberof TrACHRelationshipData
     */
    'account_owner_name'?: string;
    /**
     * Must be CHECKING or SAVINGS
     * @type {string}
     * @memberof TrACHRelationshipData
     */
    'bank_account_type'?: TrACHRelationshipDataBankAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TrACHRelationshipData
     */
    'bank_account_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrACHRelationshipData
     */
    'bank_routing_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrACHRelationshipData
     */
    'nickname'?: string;
}

export const TrACHRelationshipDataStatusEnum = {
    Queued: 'QUEUED',
    Approved: 'APPROVED',
    Pending: 'PENDING',
    CancelRequested: 'CANCEL_REQUESTED'
} as const;

export type TrACHRelationshipDataStatusEnum = typeof TrACHRelationshipDataStatusEnum[keyof typeof TrACHRelationshipDataStatusEnum];
export const TrACHRelationshipDataBankAccountTypeEnum = {
    Checking: 'CHECKING',
    Savings: 'SAVINGS'
} as const;

export type TrACHRelationshipDataBankAccountTypeEnum = typeof TrACHRelationshipDataBankAccountTypeEnum[keyof typeof TrACHRelationshipDataBankAccountTypeEnum];

/**
 * ACH tranfer
 * @export
 * @interface TrACHTransferData
 */
export interface TrACHTransferData {
    /**
     * 
     * @type {string}
     * @memberof TrACHTransferData
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof TrACHTransferData
     */
    'direction': TrACHTransferDataDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof TrACHTransferData
     */
    'relationship_id': string;
}

export const TrACHTransferDataDirectionEnum = {
    Incoming: 'INCOMING',
    Outgoing: 'OUTGOING'
} as const;

export type TrACHTransferDataDirectionEnum = typeof TrACHTransferDataDirectionEnum[keyof typeof TrACHTransferDataDirectionEnum];

/**
 * 
 * @export
 * @interface TrAccountActivityItem
 */
export interface TrAccountActivityItem {
    /**
     * 
     * @type {ActivityType}
     * @memberof TrAccountActivityItem
     */
    'activity_type'?: ActivityType;
    /**
     * 
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'id'?: string;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'transaction_time'?: string;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'type'?: TrAccountActivityItemTypeEnum;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'price'?: string;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'qty'?: string;
    /**
     * 
     * @type {OrderSide}
     * @memberof TrAccountActivityItem
     */
    'side'?: OrderSide;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'symbol'?: string;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'leaves_qty'?: string;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'order_id'?: string;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'cum_qty'?: string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof TrAccountActivityItem
     */
    'order_status'?: OrderStatus;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'date'?: string;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'net_amount'?: string;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'description'?: string;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'status'?: TrAccountActivityItemStatusEnum;
    /**
     * Valid only for non-trading activity types. Null for trading activites.
     * @type {string}
     * @memberof TrAccountActivityItem
     */
    'per_share_amount'?: string;
}

export const TrAccountActivityItemTypeEnum = {
    Fill: 'fill',
    PartialFill: 'partial_fill'
} as const;

export type TrAccountActivityItemTypeEnum = typeof TrAccountActivityItemTypeEnum[keyof typeof TrAccountActivityItemTypeEnum];
export const TrAccountActivityItemStatusEnum = {
    Executed: 'executed',
    Correct: 'correct',
    Canceled: 'canceled'
} as const;

export type TrAccountActivityItemStatusEnum = typeof TrAccountActivityItemStatusEnum[keyof typeof TrAccountActivityItemStatusEnum];

/**
 * 
 * @export
 * @interface TrAccountActivityItemAllOf
 */
export interface TrAccountActivityItemAllOf {
    /**
     * 
     * @type {ActivityType}
     * @memberof TrAccountActivityItemAllOf
     */
    'activity_type'?: ActivityType;
    /**
     * 
     * @type {string}
     * @memberof TrAccountActivityItemAllOf
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface TrAccountCreationRequest
 */
export interface TrAccountCreationRequest {
    /**
     * 
     * @type {Array<AssetClass>}
     * @memberof TrAccountCreationRequest
     */
    'enabled_assets'?: Array<AssetClass>;
    /**
     * 
     * @type {Contact}
     * @memberof TrAccountCreationRequest
     */
    'contact': Contact;
    /**
     * 
     * @type {Identity}
     * @memberof TrAccountCreationRequest
     */
    'identity': Identity;
    /**
     * 
     * @type {Disclosures}
     * @memberof TrAccountCreationRequest
     */
    'disclosures': Disclosures;
    /**
     * The client has to present the Alpaca Account and Margin Agreements to the end user, and have them read full sentences.
     * @type {Array<Agreement>}
     * @memberof TrAccountCreationRequest
     */
    'agreements': Array<Agreement>;
    /**
     * 
     * @type {Array<DocumentUploadRequest>}
     * @memberof TrAccountCreationRequest
     */
    'documents'?: Array<DocumentUploadRequest>;
    /**
     * 
     * @type {TrustedContact}
     * @memberof TrAccountCreationRequest
     */
    'trusted_contact'?: TrustedContact;
    /**
     * 
     * @type {AccountType}
     * @memberof TrAccountCreationRequest
     */
    'account_type'?: AccountType;
    /**
     * 
     * @type {CustodialAccountMinorIdentity}
     * @memberof TrAccountCreationRequest
     */
    'minor_identity'?: CustodialAccountMinorIdentity;
    /**
     * 
     * @type {string}
     * @memberof TrAccountCreationRequest
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface TrAccountCreationRequestAllOf
 */
export interface TrAccountCreationRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof TrAccountCreationRequestAllOf
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface TrAccountDocumentItem
 */
export interface TrAccountDocumentItem {
    /**
     * 
     * @type {string}
     * @memberof TrAccountDocumentItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrAccountDocumentItem
     */
    'name'?: string;
    /**
     * filename
     * @type {string}
     * @memberof TrAccountDocumentItem
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrAccountDocumentItem
     */
    'sub_type'?: string;
    /**
     * YYYY-MM-DD
     * @type {string}
     * @memberof TrAccountDocumentItem
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface TrAccountInfo
 */
export interface TrAccountInfo {
    /**
     * 
     * @type {string}
     * @memberof TrAccountInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrAccountInfo
     */
    'account_number'?: string | null;
    /**
     * 
     * @type {AccountStatus}
     * @memberof TrAccountInfo
     */
    'status'?: AccountStatus;
    /**
     * 
     * @type {AccountStatus}
     * @memberof TrAccountInfo
     */
    'crypto_status'?: AccountStatus;
    /**
     * 
     * @type {KYCResult}
     * @memberof TrAccountInfo
     */
    'kyc_results'?: KYCResult;
    /**
     * Always \"USD\"
     * @type {string}
     * @memberof TrAccountInfo
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrAccountInfo
     */
    'last_equity'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrAccountInfo
     */
    'created_at'?: string;
    /**
     * 
     * @type {Contact}
     * @memberof TrAccountInfo
     */
    'contact'?: Contact;
    /**
     * 
     * @type {Identity1}
     * @memberof TrAccountInfo
     */
    'identity'?: Identity1;
    /**
     * 
     * @type {Disclosures}
     * @memberof TrAccountInfo
     */
    'disclosures'?: Disclosures;
    /**
     * 
     * @type {Array<Agreement1>}
     * @memberof TrAccountInfo
     */
    'agreements'?: Array<Agreement1>;
    /**
     * 
     * @type {Array<ApplicationDocument>}
     * @memberof TrAccountInfo
     */
    'documents'?: Array<ApplicationDocument>;
    /**
     * 
     * @type {TrustedContact}
     * @memberof TrAccountInfo
     */
    'trusted_contact'?: TrustedContact;
    /**
     * 
     * @type {string}
     * @memberof TrAccountInfo
     */
    'account_name'?: string;
    /**
     * 
     * @type {AccountType}
     * @memberof TrAccountInfo
     */
    'account_type'?: AccountType;
    /**
     * \"UGMA\" or \"UTMA\" only used when account_type is \"custodial\"
     * @type {string}
     * @memberof TrAccountInfo
     */
    'custodial_account_type'?: TrAccountInfoCustodialAccountTypeEnum;
    /**
     * 
     * @type {CustodialAccountMinorIdentity1}
     * @memberof TrAccountInfo
     */
    'minor_identity'?: CustodialAccountMinorIdentity1;
    /**
     * 
     * @type {AccountConfigurations}
     * @memberof TrAccountInfo
     */
    'trading_configurations'?: AccountConfigurations;
}

export const TrAccountInfoCustodialAccountTypeEnum = {
    Utma: 'UTMA',
    Ugma: 'UGMA'
} as const;

export type TrAccountInfoCustodialAccountTypeEnum = typeof TrAccountInfoCustodialAccountTypeEnum[keyof typeof TrAccountInfoCustodialAccountTypeEnum];

/**
 * 
 * @export
 * @interface TrAsset
 */
export interface TrAsset {
    /**
     * Asset ID
     * @type {string}
     * @memberof TrAsset
     */
    'id'?: string;
    /**
     * 
     * @type {AssetClass}
     * @memberof TrAsset
     */
    'class'?: AssetClass;
    /**
     * 
     * @type {Exchange}
     * @memberof TrAsset
     */
    'exchange'?: Exchange;
    /**
     * The symbol of the asset
     * @type {string}
     * @memberof TrAsset
     */
    'symbol'?: string;
    /**
     * The official name of the asset
     * @type {string}
     * @memberof TrAsset
     */
    'name'?: string;
    /**
     * active or inactive
     * @type {string}
     * @memberof TrAsset
     */
    'status'?: TrAssetStatusEnum;
    /**
     * Asset is tradable on Alpaca or not
     * @type {boolean}
     * @memberof TrAsset
     */
    'tradable'?: boolean;
    /**
     * Asset is marginable or not
     * @type {boolean}
     * @memberof TrAsset
     */
    'marginable'?: boolean;
    /**
     * Asset is shortable or not
     * @type {boolean}
     * @memberof TrAsset
     */
    'shortable'?: boolean;
    /**
     * Asset is easy-to-borrow or not (filtering for easy_to_borrow = True is the best way to check whether the name is currently available to short at Alpaca).
     * @type {boolean}
     * @memberof TrAsset
     */
    'easy_to_borrow'?: boolean;
    /**
     * Asset is fractionable or not
     * @type {boolean}
     * @memberof TrAsset
     */
    'fractionable'?: boolean;
    /**
     * Percent change for the trading day as of last market closure. NOTE: This field is currently in this spec however it may not be present in the production environment at time of publishing. It will be coming in a future update at which point this spec should be updated.
     * @type {string}
     * @memberof TrAsset
     */
    'last_close_pct_change'?: string;
    /**
     * Most recent available price for this asset on the market. NOTE: This field is currently in this spec however it may not be present in the production environment at time of publishing. It will be coming in a future update at which point this spec should be updated.
     * @type {string}
     * @memberof TrAsset
     */
    'last_price'?: string;
    /**
     * Last price of the asset upon market closure on the most recent trading day. NOTE: This field is currently in this spec however it may not be present in the production environment at time of publishing. It will be coming in a future update at which point this spec should be updated.
     * @type {string}
     * @memberof TrAsset
     */
    'last_close'?: string;
}

export const TrAssetStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type TrAssetStatusEnum = typeof TrAssetStatusEnum[keyof typeof TrAssetStatusEnum];

/**
 * balance sheet financials from polygon
 * @export
 * @interface TrBalanceSheet
 */
export interface TrBalanceSheet {
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'current_assets'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'fixed_assets'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'equity_attributable_to_noncontrolling_interest'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'liabilities'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'assets'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'liabilities_and_equity'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'noncurrent_liabilities'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'other_than_fixed_noncurrent_assets'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'equity_attributable_to_parent'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'current_liabilities'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'equity'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrBalanceSheet
     */
    'noncurrent_assets'?: TrIncomeStatementRevenues;
}
/**
 * 
 * @export
 * @interface TrBar
 */
export interface TrBar {
    /**
     * Close price.
     * @type {number}
     * @memberof TrBar
     */
    'c'?: number;
    /**
     * High price.
     * @type {number}
     * @memberof TrBar
     */
    'h'?: number;
    /**
     * Low price.
     * @type {number}
     * @memberof TrBar
     */
    'l'?: number;
    /**
     * Number of trades.
     * @type {number}
     * @memberof TrBar
     */
    'n'?: number;
    /**
     * Open price.
     * @type {number}
     * @memberof TrBar
     */
    'o'?: number;
    /**
     * Timestamp in RFC-3339
     * @type {string}
     * @memberof TrBar
     */
    't'?: string;
    /**
     * Volume.
     * @type {number}
     * @memberof TrBar
     */
    'v'?: number;
    /**
     * Volume weighted average price.
     * @type {number}
     * @memberof TrBar
     */
    'vw'?: number;
}
/**
 * 
 * @export
 * @interface TrClock
 */
export interface TrClock {
    /**
     * Current timestamp
     * @type {string}
     * @memberof TrClock
     */
    'timestamp': string;
    /**
     * Whether the market is open or not
     * @type {boolean}
     * @memberof TrClock
     */
    'is_open': boolean;
    /**
     * Next market open timestamp
     * @type {string}
     * @memberof TrClock
     */
    'next_open': string;
    /**
     * Next market close timestamp
     * @type {string}
     * @memberof TrClock
     */
    'next_close': string;
}
/**
 * Comprehensive Income financials from polygon
 * @export
 * @interface TrComprehensiveIncome
 */
export interface TrComprehensiveIncome {
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrComprehensiveIncome
     */
    'other_comprehensive_income_loss_attributable_to_parent'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrComprehensiveIncome
     */
    'comprehensive_income_loss'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrComprehensiveIncome
     */
    'comprehensive_income_loss_attributable_to_noncontrolling_interest'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrComprehensiveIncome
     */
    'comprehensive_income_loss_attributable_to_parent'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrComprehensiveIncome
     */
    'other_comprehensive_income_loss'?: TrIncomeStatementRevenues;
}
/**
 * 
 * @export
 * @interface TrCreateACHRelationshipRequest
 */
export interface TrCreateACHRelationshipRequest {
    /**
     * 
     * @type {string}
     * @memberof TrCreateACHRelationshipRequest
     */
    'account_owner_name': string;
    /**
     * Must be CHECKING or SAVINGS
     * @type {string}
     * @memberof TrCreateACHRelationshipRequest
     */
    'bank_account_type': TrCreateACHRelationshipRequestBankAccountTypeEnum;
    /**
     * In sandbox, this still must be a valid format
     * @type {string}
     * @memberof TrCreateACHRelationshipRequest
     */
    'bank_account_number': string;
    /**
     * In sandbox, this still must be a valid format
     * @type {string}
     * @memberof TrCreateACHRelationshipRequest
     */
    'bank_routing_number': string;
    /**
     * 
     * @type {string}
     * @memberof TrCreateACHRelationshipRequest
     */
    'nickname'?: string;
    /**
     * If using Plaid, you can specify a Plaid processor token here 
     * @type {string}
     * @memberof TrCreateACHRelationshipRequest
     */
    'processor_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrCreateACHRelationshipRequest
     */
    'security_code'?: string;
}

export const TrCreateACHRelationshipRequestBankAccountTypeEnum = {
    Checking: 'CHECKING',
    Savings: 'SAVINGS'
} as const;

export type TrCreateACHRelationshipRequestBankAccountTypeEnum = typeof TrCreateACHRelationshipRequestBankAccountTypeEnum[keyof typeof TrCreateACHRelationshipRequestBankAccountTypeEnum];

/**
 * 
 * @export
 * @interface TrCreateACHRelationshipRequestAllOf
 */
export interface TrCreateACHRelationshipRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof TrCreateACHRelationshipRequestAllOf
     */
    'security_code'?: string;
}
/**
 * 
 * @export
 * @interface TrCustodialAccountCreationRequest
 */
export interface TrCustodialAccountCreationRequest {
    /**
     * 
     * @type {Array<AssetClass>}
     * @memberof TrCustodialAccountCreationRequest
     */
    'enabled_assets'?: Array<AssetClass>;
    /**
     * 
     * @type {Contact}
     * @memberof TrCustodialAccountCreationRequest
     */
    'contact': Contact;
    /**
     * 
     * @type {Identity}
     * @memberof TrCustodialAccountCreationRequest
     */
    'identity': Identity;
    /**
     * 
     * @type {Disclosures}
     * @memberof TrCustodialAccountCreationRequest
     */
    'disclosures': Disclosures;
    /**
     * The client has to present the Alpaca Account and Margin Agreements to the end user, and have them read full sentences.
     * @type {Array<Agreement>}
     * @memberof TrCustodialAccountCreationRequest
     */
    'agreements': Array<Agreement>;
    /**
     * 
     * @type {Array<DocumentUploadRequest>}
     * @memberof TrCustodialAccountCreationRequest
     */
    'documents'?: Array<DocumentUploadRequest>;
    /**
     * 
     * @type {TrustedContact}
     * @memberof TrCustodialAccountCreationRequest
     */
    'trusted_contact'?: TrustedContact;
    /**
     * 
     * @type {AccountType}
     * @memberof TrCustodialAccountCreationRequest
     */
    'account_type'?: AccountType;
    /**
     * 
     * @type {CustodialAccountMinorIdentity}
     * @memberof TrCustodialAccountCreationRequest
     */
    'minor_identity'?: CustodialAccountMinorIdentity;
    /**
     * 
     * @type {string}
     * @memberof TrCustodialAccountCreationRequest
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface TrEmail
 */
export interface TrEmail {
    /**
     * 
     * @type {string}
     * @memberof TrEmail
     */
    'user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrEmail
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrEmail
     */
    'type'?: TrEmailTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TrEmail
     */
    'verified'?: boolean;
}

export const TrEmailTypeEnum = {
    Primary: 'primary',
    Secondary: 'secondary',
    Substitute: 'substitute'
} as const;

export type TrEmailTypeEnum = typeof TrEmailTypeEnum[keyof typeof TrEmailTypeEnum];

/**
 * Firebase cloud messages(fcm), for push notifications, and event data
 * @export
 * @interface TrEvent
 */
export interface TrEvent {
    /**
     * send background event data to the user
     * @type {object}
     * @memberof TrEvent
     */
    'data'?: object;
    /**
     * 
     * @type {TrEventNotify}
     * @memberof TrEvent
     */
    'notification'?: TrEventNotify;
    /**
     * user fcm tokens
     * @type {Array<string>}
     * @memberof TrEvent
     */
    'tokens'?: Array<string>;
}
/**
 * push notification to user
 * @export
 * @interface TrEventNotify
 */
export interface TrEventNotify {
    /**
     * 
     * @type {string}
     * @memberof TrEventNotify
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrEventNotify
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrEventNotify
     */
    'title'?: string;
}
/**
 * notification topics users can subscribe to
 * @export
 * @enum {string}
 */

export const TrEventTopic = {
    Promotions: 'promotions'
} as const;

export type TrEventTopic = typeof TrEventTopic[keyof typeof TrEventTopic];


/**
 * feature flags to turn on and off things without redeploying
 * @export
 * @interface TrFeatureFlags
 */
export interface TrFeatureFlags {
    /**
     * if true, prevent new user without incoming gifts to signup. redirect to waitlist
     * @type {boolean}
     * @memberof TrFeatureFlags
     */
    'block_signups'?: boolean;
}
/**
 * journal ids for a gift
 * @export
 * @interface TrGiftJournals
 */
export interface TrGiftJournals {
    /**
     * 
     * @type {string}
     * @memberof TrGiftJournals
     */
    'sender_to_escrow'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftJournals
     */
    'escrow_to_receiver'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftJournals
     */
    'escrow_to_sender'?: string;
}
/**
 * tracks number of shares per symbol, and total cash deposited
 * @export
 * @interface TrGiftReceiverSummary
 */
export interface TrGiftReceiverSummary {
    /**
     * 
     * @type {object}
     * @memberof TrGiftReceiverSummary
     */
    'shares'?: object;
    /**
     * total dollar amount deposited
     * @type {number}
     * @memberof TrGiftReceiverSummary
     */
    'total_cash_deposited'?: number;
    /**
     * generated by api-server. gets symbol per share price
     * @type {object}
     * @memberof TrGiftReceiverSummary
     */
    'current_share_price'?: object;
}
/**
 * gift update
 * @export
 * @interface TrGiftRedeemUpdate
 */
export interface TrGiftRedeemUpdate {
    /**
     * 
     * @type {string}
     * @memberof TrGiftRedeemUpdate
     */
    'minor_id'?: string;
}
/**
 * gift request
 * @export
 * @interface TrGiftRequest
 */
export interface TrGiftRequest {
    /**
     * gift sender\'s email address for reverse lookup on gift, if other user has sender\'s email only. 
     * @type {string}
     * @memberof TrGiftRequest
     */
    'sender_email'?: string;
    /**
     * gift sender\'s alias (twitch username etc) 
     * @type {string}
     * @memberof TrGiftRequest
     */
    'sender_alias'?: string;
    /**
     * gift receiver is not set on gift creation.  
     * @type {boolean}
     * @memberof TrGiftRequest
     */
    'is_unassigned'?: boolean;
    /**
     * gift receiver\'s firebase uid (minor or adult) if minor\'s uid is unknown,  
     * @type {string}
     * @memberof TrGiftRequest
     */
    'receiver_id'?: string;
    /**
     * gift receiver\'s email address when the receiver is not Trellish user, receiver\'s email is required   
     * @type {string}
     * @memberof TrGiftRequest
     */
    'receiver_email'?: string;
    /**
     * gift reciever\'s first and last name (optional) 
     * @type {string}
     * @memberof TrGiftRequest
     */
    'receiver_name'?: string;
    /**
     * gift reciever\'s alias (twitch username etc) 
     * @type {string}
     * @memberof TrGiftRequest
     */
    'receiver_alias'?: string;
    /**
     * when reciever is minor and knowing guardian\'s firebase uid 
     * @type {string}
     * @memberof TrGiftRequest
     */
    'guardian_id'?: string;
    /**
     * when reciever is minor and not knowing guardian\'s uid
     * @type {string}
     * @memberof TrGiftRequest
     */
    'guardian_email'?: string;
    /**
     * gift funding source
     * @type {object}
     * @memberof TrGiftRequest
     */
    'gift_source': TrGiftRequestGiftSourceEnum;
    /**
     * gift asset type
     * @type {object}
     * @memberof TrGiftRequest
     */
    'asset_type': TrGiftRequestAssetTypeEnum;
    /**
     * when sender wants gift wish selection for the receiver
     * @type {boolean}
     * @memberof TrGiftRequest
     */
    'is_wish'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrGiftRequest
     */
    'symbol'?: string;
    /**
     * secs quantity, required for asset_type secs
     * @type {number}
     * @memberof TrGiftRequest
     */
    'secs_qty'?: number;
    /**
     * securities dollar amount on gift initialization, required for asset_type secs
     * @type {number}
     * @memberof TrGiftRequest
     */
    'secs_value_amount'?: number;
    /**
     * secs fee USD ammount based on current value, required for asset_type secs
     * @type {number}
     * @memberof TrGiftRequest
     */
    'secs_fee_amount'?: number;
    /**
     * cash gift amount, required for asset_type cash
     * @type {number}
     * @memberof TrGiftRequest
     */
    'cash_gift_amount'?: number;
    /**
     * cash gift fee, required for asset_type cash
     * @type {number}
     * @memberof TrGiftRequest
     */
    'cash_fee_amount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TrGiftRequest
     */
    'use_pass'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrGiftRequest
     */
    'pass_sku'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrGiftRequest
     */
    'pass_fee_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrGiftRequest
     */
    'pass_gift_count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TrGiftRequest
     */
    'notify_sender'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TrGiftRequest
     */
    'notify_receiver'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrGiftRequest
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftRequest
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftRequest
     */
    'video_url'?: string;
}

export const TrGiftRequestGiftSourceEnum = {
    Alpaca: 'alpaca',
    Credit: 'credit',
    Ach: 'ach'
} as const;

export type TrGiftRequestGiftSourceEnum = typeof TrGiftRequestGiftSourceEnum[keyof typeof TrGiftRequestGiftSourceEnum];
export const TrGiftRequestAssetTypeEnum = {
    Cash: 'cash',
    Secs: 'secs'
} as const;

export type TrGiftRequestAssetTypeEnum = typeof TrGiftRequestAssetTypeEnum[keyof typeof TrGiftRequestAssetTypeEnum];

/**
 * gift status
 * @export
 * @interface TrGiftStatus
 */
export interface TrGiftStatus {
    /**
     * gift sender\'s email address for reverse lookup on gift, if other user has sender\'s email only. 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'sender_email'?: string;
    /**
     * gift sender\'s alias (twitch username etc) 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'sender_alias'?: string;
    /**
     * gift receiver is not set on gift creation.  
     * @type {boolean}
     * @memberof TrGiftStatus
     */
    'is_unassigned'?: boolean;
    /**
     * gift receiver\'s firebase uid (minor or adult) if minor\'s uid is unknown,  
     * @type {string}
     * @memberof TrGiftStatus
     */
    'receiver_id'?: string;
    /**
     * gift receiver\'s email address when the receiver is not Trellish user, receiver\'s email is required   
     * @type {string}
     * @memberof TrGiftStatus
     */
    'receiver_email'?: string;
    /**
     * gift reciever\'s first and last name (optional) 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'receiver_name'?: string;
    /**
     * gift reciever\'s alias (twitch username etc) 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'receiver_alias'?: string;
    /**
     * when reciever is minor and knowing guardian\'s firebase uid 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'guardian_id'?: string;
    /**
     * when reciever is minor and not knowing guardian\'s uid
     * @type {string}
     * @memberof TrGiftStatus
     */
    'guardian_email'?: string;
    /**
     * gift funding source
     * @type {object}
     * @memberof TrGiftStatus
     */
    'gift_source': TrGiftStatusGiftSourceEnum;
    /**
     * gift asset type
     * @type {object}
     * @memberof TrGiftStatus
     */
    'asset_type': TrGiftStatusAssetTypeEnum;
    /**
     * when sender wants gift wish selection for the receiver
     * @type {boolean}
     * @memberof TrGiftStatus
     */
    'is_wish'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'symbol'?: string;
    /**
     * secs quantity, required for asset_type secs
     * @type {number}
     * @memberof TrGiftStatus
     */
    'secs_qty'?: number;
    /**
     * securities dollar amount on gift initialization, required for asset_type secs
     * @type {number}
     * @memberof TrGiftStatus
     */
    'secs_value_amount'?: number;
    /**
     * secs fee USD ammount based on current value, required for asset_type secs
     * @type {number}
     * @memberof TrGiftStatus
     */
    'secs_fee_amount'?: number;
    /**
     * cash gift amount, required for asset_type cash
     * @type {number}
     * @memberof TrGiftStatus
     */
    'cash_gift_amount'?: number;
    /**
     * cash gift fee, required for asset_type cash
     * @type {number}
     * @memberof TrGiftStatus
     */
    'cash_fee_amount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TrGiftStatus
     */
    'use_pass'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'pass_sku'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrGiftStatus
     */
    'pass_fee_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrGiftStatus
     */
    'pass_gift_count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TrGiftStatus
     */
    'notify_sender'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TrGiftStatus
     */
    'notify_receiver'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'video_url'?: string;
    /**
     * gift id, generated by gift service
     * @type {string}
     * @memberof TrGiftStatus
     */
    'gift_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'sender_id'?: string;
    /**
     * gift sender\'s first and last name
     * @type {string}
     * @memberof TrGiftStatus
     */
    'sender_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'sender_alpaca_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'receiver_alpaca_id'?: string;
    /**
     * securities dollar amount on redeem
     * @type {number}
     * @memberof TrGiftStatus
     */
    'secs_exec_net_amount'?: number;
    /**
     * api-server will update to cash_gift_amount + cash_fee_amount
     * @type {number}
     * @memberof TrGiftStatus
     */
    'cash_net_amount'?: number;
    /**
     * api-server will update to cash_gift_amount + cash_fee_amount
     * @type {number}
     * @memberof TrGiftStatus
     */
    'cash_refundable_amount'?: number;
    /**
     * executed securities quantity from sender\'s cash gift
     * @type {number}
     * @memberof TrGiftStatus
     */
    'cash_exec_qty'?: number;
    /**
     * unique bundle id on each bundle purchase
     * @type {string}
     * @memberof TrGiftStatus
     */
    'pass_id'?: string;
    /**
     * current remaining count of remaining free gifts
     * @type {number}
     * @memberof TrGiftStatus
     */
    'pass_remaining_gift_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'status'?: TrGiftStatusStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'sender_status'?: TrGiftStatusSenderStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TrGiftStatus
     */
    'sender_count_notified'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'sender_last_notified'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'receiver_status'?: TrGiftStatusReceiverStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TrGiftStatus
     */
    'receiver_count_notified'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'receiver_last_notified'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatus
     */
    'updated_at'?: string;
    /**
     * 
     * @type {TrGiftJournals}
     * @memberof TrGiftStatus
     */
    'journals'?: TrGiftJournals;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrGiftStatus
     */
    'journal_ids'?: Array<string>;
    /**
     * 
     * @type {TrGiftTrades}
     * @memberof TrGiftStatus
     */
    'trades'?: TrGiftTrades;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrGiftStatus
     */
    'trade_ids'?: Array<string>;
}

export const TrGiftStatusGiftSourceEnum = {
    Alpaca: 'alpaca',
    Credit: 'credit',
    Ach: 'ach'
} as const;

export type TrGiftStatusGiftSourceEnum = typeof TrGiftStatusGiftSourceEnum[keyof typeof TrGiftStatusGiftSourceEnum];
export const TrGiftStatusAssetTypeEnum = {
    Cash: 'cash',
    Secs: 'secs'
} as const;

export type TrGiftStatusAssetTypeEnum = typeof TrGiftStatusAssetTypeEnum[keyof typeof TrGiftStatusAssetTypeEnum];
export const TrGiftStatusStatusEnum = {
    Initialized: 'initialized',
    EscrowInProgress: 'escrow_in_progress',
    RedeemReady: 'redeem_ready',
    RedeemInitialized: 'redeem_initialized',
    RedeemInProgressSecsBuy: 'redeem_in_progress_secs_buy',
    RedeemInProgressSecsJournal: 'redeem_in_progress_secs_journal',
    Completed: 'completed',
    CancelInitialized: 'cancel_initialized',
    CancelInProgress: 'cancel_in_progress',
    Cancelled: 'cancelled'
} as const;

export type TrGiftStatusStatusEnum = typeof TrGiftStatusStatusEnum[keyof typeof TrGiftStatusStatusEnum];
export const TrGiftStatusSenderStatusEnum = {
    RedeemLinkNotified: 'redeem_link_notified',
    SenderCancelled: 'sender_cancelled',
    NotEnoughFund: 'not_enough_fund',
    UnknownError: 'unknown_error'
} as const;

export type TrGiftStatusSenderStatusEnum = typeof TrGiftStatusSenderStatusEnum[keyof typeof TrGiftStatusSenderStatusEnum];
export const TrGiftStatusReceiverStatusEnum = {
    RedeemLinkNotified: 'redeem_link_notified',
    ReceiverRefused: 'receiver_refused'
} as const;

export type TrGiftStatusReceiverStatusEnum = typeof TrGiftStatusReceiverStatusEnum[keyof typeof TrGiftStatusReceiverStatusEnum];

/**
 * 
 * @export
 * @interface TrGiftStatusAllOf
 */
export interface TrGiftStatusAllOf {
    /**
     * gift id, generated by gift service
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'gift_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'sender_id'?: string;
    /**
     * gift sender\'s first and last name
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'sender_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'sender_alpaca_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'receiver_alpaca_id'?: string;
    /**
     * securities dollar amount on redeem
     * @type {number}
     * @memberof TrGiftStatusAllOf
     */
    'secs_exec_net_amount'?: number;
    /**
     * api-server will update to cash_gift_amount + cash_fee_amount
     * @type {number}
     * @memberof TrGiftStatusAllOf
     */
    'cash_net_amount'?: number;
    /**
     * api-server will update to cash_gift_amount + cash_fee_amount
     * @type {number}
     * @memberof TrGiftStatusAllOf
     */
    'cash_refundable_amount'?: number;
    /**
     * executed securities quantity from sender\'s cash gift
     * @type {number}
     * @memberof TrGiftStatusAllOf
     */
    'cash_exec_qty'?: number;
    /**
     * unique bundle id on each bundle purchase
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'pass_id'?: string;
    /**
     * current remaining count of remaining free gifts
     * @type {number}
     * @memberof TrGiftStatusAllOf
     */
    'pass_remaining_gift_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'status'?: TrGiftStatusAllOfStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'sender_status'?: TrGiftStatusAllOfSenderStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TrGiftStatusAllOf
     */
    'sender_count_notified'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'sender_last_notified'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'receiver_status'?: TrGiftStatusAllOfReceiverStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TrGiftStatusAllOf
     */
    'receiver_count_notified'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'receiver_last_notified'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusAllOf
     */
    'updated_at'?: string;
    /**
     * 
     * @type {TrGiftJournals}
     * @memberof TrGiftStatusAllOf
     */
    'journals'?: TrGiftJournals;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrGiftStatusAllOf
     */
    'journal_ids'?: Array<string>;
    /**
     * 
     * @type {TrGiftTrades}
     * @memberof TrGiftStatusAllOf
     */
    'trades'?: TrGiftTrades;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrGiftStatusAllOf
     */
    'trade_ids'?: Array<string>;
}

export const TrGiftStatusAllOfStatusEnum = {
    Initialized: 'initialized',
    EscrowInProgress: 'escrow_in_progress',
    RedeemReady: 'redeem_ready',
    RedeemInitialized: 'redeem_initialized',
    RedeemInProgressSecsBuy: 'redeem_in_progress_secs_buy',
    RedeemInProgressSecsJournal: 'redeem_in_progress_secs_journal',
    Completed: 'completed',
    CancelInitialized: 'cancel_initialized',
    CancelInProgress: 'cancel_in_progress',
    Cancelled: 'cancelled'
} as const;

export type TrGiftStatusAllOfStatusEnum = typeof TrGiftStatusAllOfStatusEnum[keyof typeof TrGiftStatusAllOfStatusEnum];
export const TrGiftStatusAllOfSenderStatusEnum = {
    RedeemLinkNotified: 'redeem_link_notified',
    SenderCancelled: 'sender_cancelled',
    NotEnoughFund: 'not_enough_fund',
    UnknownError: 'unknown_error'
} as const;

export type TrGiftStatusAllOfSenderStatusEnum = typeof TrGiftStatusAllOfSenderStatusEnum[keyof typeof TrGiftStatusAllOfSenderStatusEnum];
export const TrGiftStatusAllOfReceiverStatusEnum = {
    RedeemLinkNotified: 'redeem_link_notified',
    ReceiverRefused: 'receiver_refused'
} as const;

export type TrGiftStatusAllOfReceiverStatusEnum = typeof TrGiftStatusAllOfReceiverStatusEnum[keyof typeof TrGiftStatusAllOfReceiverStatusEnum];

/**
 * gift status for public access without authentication
 * @export
 * @interface TrGiftStatusPublic
 */
export interface TrGiftStatusPublic {
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'gift_id'?: string;
    /**
     * gift sender\'s first and last name
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'sender_name'?: string;
    /**
     * gift reciever\'s first and last name
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'receiver_name'?: string;
    /**
     * receiver email, so the reciever knows what account to sign up or into.
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'receiver_email'?: string;
    /**
     * receiver username, so the reciever knows what account to sign up or into.
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'receiver_username'?: string;
    /**
     * if minor, gift reciever\'s guardian first and last name
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'receiver_guardian_name'?: string;
    /**
     * if minor, receiver\'s guardiam email, so the reciever knows what account to sign up or into.
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'receiver_guardian_email'?: string;
    /**
     * if minor, receiver\'s guardian username, so the reciever knows what account to sign up or into.
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'receiver_guardian_username'?: string;
    /**
     * gift asset type
     * @type {object}
     * @memberof TrGiftStatusPublic
     */
    'asset_type'?: TrGiftStatusPublicAssetTypeEnum;
    /**
     * when sender wants gift wish stock(s)
     * @type {boolean}
     * @memberof TrGiftStatusPublic
     */
    'is_wish'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'symbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrGiftStatusPublic
     */
    'secs_qty'?: number;
    /**
     * securities dollar amount on gift initialization
     * @type {number}
     * @memberof TrGiftStatusPublic
     */
    'secs_net_amount'?: number;
    /**
     * cash gift
     * @type {number}
     * @memberof TrGiftStatusPublic
     */
    'cash_gift_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'video_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'status'?: TrGiftStatusPublicStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrGiftStatusPublic
     */
    'updated_at'?: string;
}

export const TrGiftStatusPublicAssetTypeEnum = {
    Cash: 'cash',
    Secs: 'secs'
} as const;

export type TrGiftStatusPublicAssetTypeEnum = typeof TrGiftStatusPublicAssetTypeEnum[keyof typeof TrGiftStatusPublicAssetTypeEnum];
export const TrGiftStatusPublicStatusEnum = {
    Initialized: 'initialized',
    EscrowInProgress: 'escrow_in_progress',
    RedeemReady: 'redeem_ready',
    RedeemInitialized: 'redeem_initialized',
    RedeemInProgressSecsBuy: 'redeem_in_progress_secs_buy',
    RedeemInProgressSecsJournal: 'redeem_in_progress_secs_journal',
    Completed: 'completed',
    CancelInitialized: 'cancel_initialized',
    CancelInProgress: 'cancel_in_progress',
    Cancelled: 'cancelled'
} as const;

export type TrGiftStatusPublicStatusEnum = typeof TrGiftStatusPublicStatusEnum[keyof typeof TrGiftStatusPublicStatusEnum];

/**
 * trade ids for a gift
 * @export
 * @interface TrGiftTrades
 */
export interface TrGiftTrades {
    /**
     * 
     * @type {string}
     * @memberof TrGiftTrades
     */
    'escrow_buy'?: string;
}
/**
 * income statement financials from polygon
 * @export
 * @interface TrIncomeStatement
 */
export interface TrIncomeStatement {
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'revenues'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'net_income_loss_available_to_common_stockholders_basic'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'basic_earnings_per_share'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'interest_expense_operating'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'net_income_loss_attributable_to_parent'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'income_loss_from_continuing_operations_after_tax'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'net_income_loss_attributable_to_noncontrolling_interest'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'income_tax_expense_benefit'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'benefits_costs_expenses'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'participating_securities_distributed_and_undistributed_earnings_loss_basic'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'income_loss_from_continuing_operations_before_tax'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'net_income_loss'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'income_loss_from_equity_method_investments'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'operating_expenses'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'operating_income_loss'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'income_loss_before_equity_method_investments'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'preferred_stock_dividends_and_other_adjustments'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'costs_and_expenses'?: TrIncomeStatementRevenues;
    /**
     * 
     * @type {TrIncomeStatementRevenues}
     * @memberof TrIncomeStatement
     */
    'diluted_earnings_per_share'?: TrIncomeStatementRevenues;
}
/**
 * 
 * @export
 * @interface TrIncomeStatementRevenues
 */
export interface TrIncomeStatementRevenues {
    /**
     * 
     * @type {number}
     * @memberof TrIncomeStatementRevenues
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrIncomeStatementRevenues
     */
    'unit'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrIncomeStatementRevenues
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface TrInvoice
 */
export interface TrInvoice {
    /**
     * 
     * @type {string}
     * @memberof TrInvoice
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrInvoice
     */
    'amount_due'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrInvoice
     */
    'amount_paid'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrInvoice
     */
    'amount_remaining'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TrInvoice
     */
    'attempted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TrInvoice
     */
    'attempt_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrInvoice
     */
    'billing_reason'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrInvoice
     */
    'created'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrInvoice
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrInvoice
     */
    'hosted_invoice_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrInvoice
     */
    'invoice_pdf'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrInvoice
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrInvoice
     */
    'next_payment_attempt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrInvoice
     */
    'paid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrInvoice
     */
    'status'?: string;
    /**
     * 
     * @type {object}
     * @memberof TrInvoice
     */
    'status_transitions'?: object;
    /**
     * 
     * @type {number}
     * @memberof TrInvoice
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrInvoice
     */
    'total_excluding_tax'?: number;
}
/**
 * 
 * @export
 * @interface TrMarketBars
 */
export interface TrMarketBars {
    /**
     * 
     * @type {Array<TrBar>}
     * @memberof TrMarketBars
     */
    'bars'?: Array<TrBar>;
    /**
     * 
     * @type {string}
     * @memberof TrMarketBars
     */
    'symbol'?: string;
}
/**
 * popular symbol categories
 * @export
 * @enum {string}
 */

export const TrMarketCategory = {
    All: 'All',
    Etfs: 'ETFs',
    Reits: 'REITs',
    Companies: 'Companies',
    Tech: 'Tech',
    Travel: 'Travel',
    Entertainment: 'Entertainment',
    Consumer: 'Consumer',
    Finance: 'Finance'
} as const;

export type TrMarketCategory = typeof TrMarketCategory[keyof typeof TrMarketCategory];


/**
 * 
 * @export
 * @interface TrMarketDay
 */
export interface TrMarketDay {
    /**
     * Date string in YYYY-MM-DD format
     * @type {string}
     * @memberof TrMarketDay
     */
    'date': string;
    /**
     * The time the market opens at on this date in HH:MM format
     * @type {string}
     * @memberof TrMarketDay
     */
    'open': string;
    /**
     * The time the market closes at on this date in HH:MM format
     * @type {string}
     * @memberof TrMarketDay
     */
    'close': string;
    /**
     * this field has been deprecated please ignore
     * @type {string}
     * @memberof TrMarketDay
     * @deprecated
     */
    'session_open'?: string;
    /**
     * this field has been deprecated please ignore
     * @type {string}
     * @memberof TrMarketDay
     * @deprecated
     */
    'session_close'?: string;
}
/**
 * popular div tickers
 * @export
 * @interface TrMarketPopular
 */
export interface TrMarketPopular {
    /**
     * 
     * @type {Array<TrSymbolCalc>}
     * @memberof TrMarketPopular
     */
    'kings'?: Array<TrSymbolCalc>;
    /**
     * 
     * @type {Array<TrSymbolCalc>}
     * @memberof TrMarketPopular
     */
    'aristocrats'?: Array<TrSymbolCalc>;
    /**
     * 
     * @type {Array<TrSymbolCalc>}
     * @memberof TrMarketPopular
     */
    'bluechip'?: Array<TrSymbolCalc>;
    /**
     * 
     * @type {Array<TrSymbolCalc>}
     * @memberof TrMarketPopular
     */
    'contenders'?: Array<TrSymbolCalc>;
    /**
     * 
     * @type {Array<TrSymbolCalc>}
     * @memberof TrMarketPopular
     */
    'top'?: Array<TrSymbolCalc>;
}
/**
 * 
 * @export
 * @interface TrMarketQuote
 */
export interface TrMarketQuote {
    /**
     * 
     * @type {TrQuote}
     * @memberof TrMarketQuote
     */
    'quote'?: TrQuote;
    /**
     * 
     * @type {string}
     * @memberof TrMarketQuote
     */
    'symbol'?: string;
}
/**
 * snapshots
 * @export
 * @interface TrMarketSnapshots
 */
export interface TrMarketSnapshots {
    [key: string]: TrSnapshot | any;

    /**
     * comma separated stock symbols
     * @type {string}
     * @memberof TrMarketSnapshots
     */
    'symbols'?: string;
}
/**
 * 
 * @export
 * @interface TrMarketTrade
 */
export interface TrMarketTrade {
    /**
     * 
     * @type {string}
     * @memberof TrMarketTrade
     */
    'symbol'?: string;
    /**
     * 
     * @type {TrTrade}
     * @memberof TrMarketTrade
     */
    'trade'?: TrTrade;
}
/**
 * For Name and Latest trade object
 * @export
 * @interface TrMarketTradeAndDetail
 */
export interface TrMarketTradeAndDetail {
    /**
     * 
     * @type {string}
     * @memberof TrMarketTradeAndDetail
     */
    'color'?: string;
    /**
     * 
     * @type {TrSymbolDetail}
     * @memberof TrMarketTradeAndDetail
     */
    'detail'?: TrSymbolDetail;
    /**
     * 
     * @type {TrSymbolSummary}
     * @memberof TrMarketTradeAndDetail
     */
    'summary'?: TrSymbolSummary;
    /**
     * 
     * @type {string}
     * @memberof TrMarketTradeAndDetail
     */
    'symbol'?: string;
    /**
     * 
     * @type {TrTrade}
     * @memberof TrMarketTradeAndDetail
     */
    'trade'?: TrTrade;
}
/**
 * for Alpaca Income Range and liquid asset range
 * @export
 * @enum {string}
 */

export const TrMoneyRange = {
    Less20k: 'less20k',
    Between20k49k: 'between20k49k',
    Between50k99k: 'between50k99k',
    Between100k499k: 'between100k499k',
    Between500k1M: 'between500k1M',
    More1M: 'more1M'
} as const;

export type TrMoneyRange = typeof TrMoneyRange[keyof typeof TrMoneyRange];


/**
 * 
 * @export
 * @interface TrNotifyEmail
 */
export interface TrNotifyEmail {
    /**
     * 
     * @type {TrUserProfile}
     * @memberof TrNotifyEmail
     */
    'me'?: TrUserProfile;
    /**
     * sendgrids template Id. see email.service.ts
     * @type {string}
     * @memberof TrNotifyEmail
     */
    'template_id': string;
    /**
     * 
     * @type {TrUserProfile}
     * @memberof TrNotifyEmail
     */
    'recipient'?: TrUserProfile;
    /**
     * 
     * @type {TrTradingOrder}
     * @memberof TrNotifyEmail
     */
    'order'?: TrTradingOrder;
    /**
     * 
     * @type {NotifyTransfer}
     * @memberof TrNotifyEmail
     */
    'transfer'?: NotifyTransfer;
    /**
     * 
     * @type {NotifyGift}
     * @memberof TrNotifyEmail
     */
    'gift'?: NotifyGift;
    /**
     * 
     * @type {EmailVerification}
     * @memberof TrNotifyEmail
     */
    'verify'?: EmailVerification;
    /**
     * 
     * @type {AccountStatementOrTradeConfirmation}
     * @memberof TrNotifyEmail
     */
    'statement'?: AccountStatementOrTradeConfirmation;
    /**
     * 
     * @type {AchPaymentInfo}
     * @memberof TrNotifyEmail
     */
    'payment'?: AchPaymentInfo;
    /**
     * 
     * @type {MetaData}
     * @memberof TrNotifyEmail
     */
    'meta'?: MetaData;
}
/**
 * 
 * @export
 * @interface TrPlaidLinkTokenCreateResponse
 */
export interface TrPlaidLinkTokenCreateResponse {
    /**
     * A `link_token`, which can be supplied to Link in order to initialize it and receive a `public_token`, which can be exchanged for an `access_token`.
     * @type {string}
     * @memberof TrPlaidLinkTokenCreateResponse
     */
    'link_token': string;
    /**
     * The expiration date for the `link_token`, in [ISO 8601](https://wikipedia.org/wiki/ISO_8601) format. A `link_token` created to generate a `public_token` that will be exchanged for a new `access_token` expires after 4 hours. A `link_token` created for an existing Item (such as when updating an existing `access_token` by launching Link in update mode) expires after 30 minutes.
     * @type {string}
     * @memberof TrPlaidLinkTokenCreateResponse
     */
    'expiration': string;
    /**
     * A unique identifier for the request, which can be used for troubleshooting. This identifier, like all Plaid identifiers, is case sensitive.
     * @type {string}
     * @memberof TrPlaidLinkTokenCreateResponse
     */
    'request_id': string;
}
/**
 * plaid public token request
 * @export
 * @interface TrPlaidPublicToken
 */
export interface TrPlaidPublicToken {
    /**
     * 
     * @type {Array<PlaidLinkAccount>}
     * @memberof TrPlaidPublicToken
     */
    'accounts'?: Array<PlaidLinkAccount>;
    /**
     * 
     * @type {string}
     * @memberof TrPlaidPublicToken
     */
    'public_token'?: string;
}
/**
 * 
 * @export
 * @interface TrPortfolioHistory
 */
export interface TrPortfolioHistory {
    /**
     * time of each data element, left-labeled (the beginning of time window)
     * @type {Array<number>}
     * @memberof TrPortfolioHistory
     */
    'timestamp': Array<number>;
    /**
     * equity value of the account in dollar amount as of the end of each time window
     * @type {Array<number>}
     * @memberof TrPortfolioHistory
     */
    'equity': Array<number>;
    /**
     * profit/loss in dollar from the base value
     * @type {Array<number>}
     * @memberof TrPortfolioHistory
     */
    'profit_loss': Array<number>;
    /**
     * profit/loss in percentage from the base value
     * @type {Array<number>}
     * @memberof TrPortfolioHistory
     */
    'profit_loss_pct': Array<number>;
    /**
     * basis in dollar of the profit loss calculation
     * @type {number}
     * @memberof TrPortfolioHistory
     */
    'base_value': number | null;
    /**
     * time window size of each data element
     * @type {string}
     * @memberof TrPortfolioHistory
     */
    'timeframe': string | null;
}
/**
 * trellish products general
 * @export
 * @interface TrProduct
 */
export interface TrProduct {
    /**
     * 
     * @type {string}
     * @memberof TrProduct
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrProduct
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrProduct
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrProduct
     */
    'is_active'?: boolean;
}
/**
 * gift pass
 * @export
 * @interface TrProductGiftPass
 */
export interface TrProductGiftPass {
    /**
     * 
     * @type {string}
     * @memberof TrProductGiftPass
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrProductGiftPass
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrProductGiftPass
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrProductGiftPass
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TrProductGiftPass
     */
    'pass_fee_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrProductGiftPass
     */
    'pass_gift_count'?: number;
}
/**
 * 
 * @export
 * @interface TrProductGiftPassAllOf
 */
export interface TrProductGiftPassAllOf {
    /**
     * 
     * @type {number}
     * @memberof TrProductGiftPassAllOf
     */
    'pass_fee_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrProductGiftPassAllOf
     */
    'pass_gift_count'?: number;
}
/**
 * 
 * @export
 * @interface TrQuote
 */
export interface TrQuote {
    /**
     * ask price
     * @type {number}
     * @memberof TrQuote
     */
    'ap'?: number;
    /**
     * ask size
     * @type {number}
     * @memberof TrQuote
     */
    'as'?: number;
    /**
     * ask exchange
     * @type {string}
     * @memberof TrQuote
     */
    'ax'?: string;
    /**
     * bid price
     * @type {number}
     * @memberof TrQuote
     */
    'bp'?: number;
    /**
     * bid size
     * @type {number}
     * @memberof TrQuote
     */
    'bs'?: number;
    /**
     * bid exchange
     * @type {string}
     * @memberof TrQuote
     */
    'bx'?: string;
    /**
     * quote conditions
     * @type {Array<string>}
     * @memberof TrQuote
     */
    'c'?: Array<string>;
    /**
     * Timestamp in RFC-3339
     * @type {string}
     * @memberof TrQuote
     */
    't'?: string;
    /**
     * Tape
     * @type {string}
     * @memberof TrQuote
     */
    'z'?: string;
}
/**
 * one of the following. family(any adult), child(mykids), nibling(minors not your kid). friend will go into family
 * @export
 * @enum {string}
 */

export const TrRelation = {
    Mykid: 'mykid',
    Adult: 'adult',
    Minor: 'minor'
} as const;

export type TrRelation = typeof TrRelation[keyof typeof TrRelation];


/**
 * gift role
 * @export
 * @enum {string}
 */

export const TrRole = {
    Sender: 'sender',
    Receiver: 'receiver',
    Guardian: 'guardian',
    All: 'all',
    Pair: 'pair'
} as const;

export type TrRole = typeof TrRole[keyof typeof TrRole];


/**
 * all these properties follow camelcase in alpaca Market()).v2StocksSnapshotsGet
 * @export
 * @interface TrSnapshot
 */
export interface TrSnapshot {
    /**
     * 
     * @type {TrBar}
     * @memberof TrSnapshot
     */
    'dailyBar'?: TrBar;
    /**
     * 
     * @type {TrQuote}
     * @memberof TrSnapshot
     */
    'latestQuote'?: TrQuote;
    /**
     * 
     * @type {TrTrade}
     * @memberof TrSnapshot
     */
    'latestTrade'?: TrTrade;
    /**
     * 
     * @type {TrBar}
     * @memberof TrSnapshot
     */
    'minuteBar'?: TrBar;
    /**
     * 
     * @type {TrBar}
     * @memberof TrSnapshot
     */
    'prevDailyBar'?: TrBar;
}
/**
 * 
 * @export
 * @interface TrSubscription
 */
export interface TrSubscription {
    /**
     * 
     * @type {string}
     * @memberof TrSubscription
     */
    'plan_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrSubscription
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrSubscription
     */
    'amount_decimal'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSubscription
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSubscription
     */
    'interval'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrSubscription
     */
    'interval_count'?: number;
    /**
     * timestamp when stripe subscription created
     * @type {number}
     * @memberof TrSubscription
     */
    'created'?: number;
    /**
     * timestamp when user agreed subscription
     * @type {number}
     * @memberof TrSubscription
     */
    'agreed'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TrSubscription
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrSubscription
     */
    'status'?: TrSubscriptionStatusEnum;
}

export const TrSubscriptionStatusEnum = {
    Agreed: 'agreed',
    Active: 'active',
    Canceled: 'canceled',
    Trialing: 'trialing'
} as const;

export type TrSubscriptionStatusEnum = typeof TrSubscriptionStatusEnum[keyof typeof TrSubscriptionStatusEnum];

/**
 * symbol details, address
 * @export
 * @interface TrSymbolAddress
 */
export interface TrSymbolAddress {
    /**
     * The first line of the company\'s headquarters address.
     * @type {string}
     * @memberof TrSymbolAddress
     */
    'address1'?: string;
    /**
     * The city of the company\'s headquarters address.
     * @type {string}
     * @memberof TrSymbolAddress
     */
    'city'?: string;
    /**
     * The state of the company\'s headquarters address.
     * @type {string}
     * @memberof TrSymbolAddress
     */
    'state'?: string;
}
/**
 * brand file names for fast lookup. to check if brand exist and what the file extension it is.
 * @export
 * @interface TrSymbolBrandImages
 */
export interface TrSymbolBrandImages {
    /**
     * 
     * @type {Array<string>}
     * @memberof TrSymbolBrandImages
     */
    'icons'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrSymbolBrandImages
     */
    'logos'?: Array<string>;
}
/**
 * symbol details, branding
 * @export
 * @interface TrSymbolBranding
 */
export interface TrSymbolBranding {
    /**
     * A link to this ticker\'s company\'s icon. Icon\'s are generally smaller, square images that represent the company at a glance. Note that you must provide an API key when accessing this URL. See the \"Authentication\" section at the top of this page for more details
     * @type {string}
     * @memberof TrSymbolBranding
     */
    'icon_url'?: string;
    /**
     * A link to this ticker\'s company\'s logo. Note that you must provide an API key when accessing this URL. See the \"Authentication\" section at the top of this page for more details.
     * @type {string}
     * @memberof TrSymbolBranding
     */
    'logo_url'?: string;
}
/**
 * single api call for the calculations.
 * @export
 * @interface TrSymbolCalc
 */
export interface TrSymbolCalc {
    /**
     * 
     * @type {string}
     * @memberof TrSymbolCalc
     */
    'symbol'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolCalc
     */
    'name'?: string;
    /**
     * price per share
     * @type {number}
     * @memberof TrSymbolCalc
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrSymbolCalc
     */
    'market_cap'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrSymbolCalc
     */
    'price_equity_ratio'?: number;
    /**
     * 1 year yield percent
     * @type {number}
     * @memberof TrSymbolCalc
     */
    'div_yield'?: number;
    /**
     * 3 year yield percent
     * @type {number}
     * @memberof TrSymbolCalc
     */
    'div_yield_3y'?: number;
    /**
     * 5 year yield percent
     * @type {number}
     * @memberof TrSymbolCalc
     */
    'div_yield_5y'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrSymbolCalc
     */
    'payout_ratio'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrSymbolCalc
     */
    'payout_ratio_3y'?: number;
    /**
     * free cash flow payout ratio in percent
     * @type {number}
     * @memberof TrSymbolCalc
     */
    'fcf_payout_ratio'?: number;
    /**
     * Compound annual growth rate in percent
     * @type {number}
     * @memberof TrSymbolCalc
     */
    'cagr_3y'?: number;
    /**
     * Compound annual growth rate in percent
     * @type {number}
     * @memberof TrSymbolCalc
     */
    'cagr_5y'?: number;
}
/**
 * symbol details V3, general information. from polygon.io https://polygon.io/docs/stocks/get_v3_reference_tickers__ticker
 * @export
 * @interface TrSymbolDetail
 */
export interface TrSymbolDetail {
    /**
     * Whether or not the asset is actively traded. False means the asset has been delisted.
     * @type {boolean}
     * @memberof TrSymbolDetail
     */
    'active'?: boolean;
    /**
     * 
     * @type {TrSymbolAddress}
     * @memberof TrSymbolDetail
     */
    'address'?: TrSymbolAddress;
    /**
     * 
     * @type {TrSymbolBranding}
     * @memberof TrSymbolDetail
     */
    'branding'?: TrSymbolBranding;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'ceo'?: string;
    /**
     * The CIK number for this ticker.
     * @type {number}
     * @memberof TrSymbolDetail
     */
    'cik'?: number;
    /**
     * The composite OpenFIGI number for this ticker
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'composite_figi'?: string;
    /**
     * The name of the currency that this asset is traded with.
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'currency_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'delisted_utc'?: string;
    /**
     * A description of the company and what they do/offer.
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'description'?: string;
    /**
     * number of employees
     * @type {number}
     * @memberof TrSymbolDetail
     */
    'employees'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'homepage_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'hq_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'industry'?: string;
    /**
     * The last time this asset record was updated.
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'last_updated_utc'?: string;
    /**
     * The date that the symbol was first publicly listed in the format YYYY-MM-DD.
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'list_date'?: string;
    /**
     * The locale of the asset.
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'locale'?: string;
    /**
     * The market type of the asset.
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'market'?: string;
    /**
     * The most recent close price of the ticker multiplied by weighted outstanding shares.
     * @type {number}
     * @memberof TrSymbolDetail
     */
    'market_cap'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'phone_number'?: string;
    /**
     * The ISO code of the primary listing exchange for this asset.
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'primary_exchange'?: string;
    /**
     * The share Class OpenFIGI number for this ticker.
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'share_class_figi'?: string;
    /**
     * The recorded number of outstanding shares for this particular share class.
     * @type {number}
     * @memberof TrSymbolDetail
     */
    'share_class_shares_outstanding'?: number;
    /**
     * The standard industrial classification code for this ticker.
     * @type {number}
     * @memberof TrSymbolDetail
     */
    'sic_code'?: number;
    /**
     * A description of this ticker\'s SIC code.
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'sic_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrSymbolDetail
     */
    'total_employees'?: number;
    /**
     * The type of the asset.
     * @type {string}
     * @memberof TrSymbolDetail
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrSymbolDetail
     */
    'weighted_shares_outstanding'?: number;
}
/**
 * symbol tracker for scraping details
 * @export
 * @interface TrSymbolDetailTracker
 */
export interface TrSymbolDetailTracker {
    /**
     * comma separated stock symbols
     * @type {boolean}
     * @memberof TrSymbolDetailTracker
     */
    'active'?: boolean;
    /**
     * epoch timestamp
     * @type {number}
     * @memberof TrSymbolDetailTracker
     */
    'last_updated'?: number;
    /**
     * symbol
     * @type {string}
     * @memberof TrSymbolDetailTracker
     */
    'symbol'?: string;
    /**
     * polygon symbol type. ie. CS,  ETF, ADRC, FUND, ETN
     * @type {string}
     * @memberof TrSymbolDetailTracker
     */
    'type'?: string;
}
/**
 * market/metrics/financials. theres a lot more metrics to add here
 * @export
 * @interface TrSymbolFinancials
 */
export interface TrSymbolFinancials {
    /**
     * 
     * @type {string}
     * @memberof TrSymbolFinancials
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolFinancials
     */
    'filing_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrSymbolFinancials
     */
    'start_date'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolFinancials
     */
    'end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolFinancials
     */
    'source_filing_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolFinancials
     */
    'source_filing_file_url'?: string;
    /**
     * 
     * @type {TrSymbolFinancialsFinancials}
     * @memberof TrSymbolFinancials
     */
    'financials'?: TrSymbolFinancialsFinancials;
}
/**
 * 
 * @export
 * @interface TrSymbolFinancialsFinancials
 */
export interface TrSymbolFinancialsFinancials {
    /**
     * 
     * @type {TrIncomeStatement}
     * @memberof TrSymbolFinancialsFinancials
     */
    'income_statement'?: TrIncomeStatement;
    /**
     * 
     * @type {TrBalanceSheet}
     * @memberof TrSymbolFinancialsFinancials
     */
    'balance_sheet'?: TrBalanceSheet;
    /**
     * 
     * @type {TrComprehensiveIncome}
     * @memberof TrSymbolFinancialsFinancials
     */
    'comprehensive_income'?: TrComprehensiveIncome;
}
/**
 * deprecate this. use TrAsset
 * @export
 * @interface TrSymbolSummary
 */
export interface TrSymbolSummary {
    /**
     * 
     * @type {string}
     * @memberof TrSymbolSummary
     */
    'class'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrSymbolSummary
     */
    'easy_to_borrow'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolSummary
     */
    'exchange'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrSymbolSummary
     */
    'fractionable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolSummary
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrSymbolSummary
     */
    'marginable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolSummary
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrSymbolSummary
     */
    'shortable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolSummary
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrSymbolSummary
     */
    'symbol'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrSymbolSummary
     */
    'tradable'?: boolean;
}
/**
 * 
 * @export
 * @interface TrTrade
 */
export interface TrTrade {
    /**
     * Trade conditions
     * @type {Array<string>}
     * @memberof TrTrade
     */
    'c'?: Array<string>;
    /**
     * Trade ID
     * @type {string}
     * @memberof TrTrade
     */
    'i'?: string;
    /**
     * Trade price
     * @type {number}
     * @memberof TrTrade
     */
    'p'?: number;
    /**
     * Trade size
     * @type {number}
     * @memberof TrTrade
     */
    's'?: number;
    /**
     * Timestamp in RFC-3339
     * @type {string}
     * @memberof TrTrade
     */
    't'?: string;
    /**
     * Exchange where the trade happened
     * @type {string}
     * @memberof TrTrade
     */
    'x'?: string;
    /**
     * Tape
     * @type {string}
     * @memberof TrTrade
     */
    'z'?: string;
}
/**
 * 
 * @export
 * @interface TrTradingAccount
 */
export interface TrTradingAccount {
    /**
     * 
     * @type {boolean}
     * @memberof TrTradingAccount
     */
    'account_blocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'buying_power'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'cash'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'cash_transferable'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'cash_withdrawable'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'clearing_broker'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrTradingAccount
     */
    'daytrade_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'daytrading_buying_power'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'equity'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'initial_margin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'last_buying_power'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'last_cash'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrTradingAccount
     */
    'last_daytrade_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'last_daytrading_buying_power'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'last_equity'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'last_initial_margin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'last_long_market_value'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'last_maintenance_margin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'last_regt_buying_power'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'last_short_market_value'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'long_market_value'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'maintenance_margin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'multiplier'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrTradingAccount
     */
    'pattern_day_trader'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'pending_transfer_out'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'portfolio_value'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'previous_close'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'regt_buying_power'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'short_market_value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TrTradingAccount
     */
    'shorting_enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'sma'?: string;
    /**
     * 
     * @type {AccountStatus}
     * @memberof TrTradingAccount
     */
    'status'?: AccountStatus;
    /**
     * 
     * @type {boolean}
     * @memberof TrTradingAccount
     */
    'trade_suspended_by_user'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TrTradingAccount
     */
    'trading_blocked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TrTradingAccount
     */
    'transfers_blocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TrTradingAccount
     */
    'position_market_value'?: string;
}
/**
 * 
 * @export
 * @interface TrTradingCreateOrderRequest
 */
export interface TrTradingCreateOrderRequest {
    /**
     * Symbol or asset ID to identify the asset to trade
     * @type {string}
     * @memberof TrTradingCreateOrderRequest
     */
    'symbol': string;
    /**
     * Number of shares to trade. Can be fractionable for only market and day order types.
     * @type {string}
     * @memberof TrTradingCreateOrderRequest
     */
    'qty'?: string;
    /**
     * Dollar amount to trade. Cannot work with qty. Can only work for market order types and time_in_force = day.
     * @type {string}
     * @memberof TrTradingCreateOrderRequest
     */
    'notional'?: string;
    /**
     * 
     * @type {OrderSide}
     * @memberof TrTradingCreateOrderRequest
     */
    'side': OrderSide;
    /**
     * 
     * @type {OrderType}
     * @memberof TrTradingCreateOrderRequest
     */
    'type': OrderType;
    /**
     * 
     * @type {TimeInForce}
     * @memberof TrTradingCreateOrderRequest
     */
    'time_in_force': TimeInForce;
    /**
     * Required if type is limit or stop_limit
     * @type {string}
     * @memberof TrTradingCreateOrderRequest
     */
    'limit_price'?: string;
    /**
     * Required if type is stop or stop_limit
     * @type {string}
     * @memberof TrTradingCreateOrderRequest
     */
    'stop_price'?: string;
    /**
     * If type is trailing_stop, then one of trail_price or trail_percent is required
     * @type {string}
     * @memberof TrTradingCreateOrderRequest
     */
    'trail_price'?: string;
    /**
     * If type is trailing_stop, then one of trail_price or trail_percent is required
     * @type {string}
     * @memberof TrTradingCreateOrderRequest
     */
    'trail_percent'?: string;
    /**
     * Defaults to false. If true, order will be eligible to execute in premarket/afterhours. Only works with type limit and time_in_force = day.
     * @type {boolean}
     * @memberof TrTradingCreateOrderRequest
     */
    'extended_hours'?: boolean;
    /**
     * A unique identifier for the order. Automatically generated if not sent. (<= 48 characters)
     * @type {string}
     * @memberof TrTradingCreateOrderRequest
     */
    'client_order_id'?: string;
    /**
     * 
     * @type {OrderClass}
     * @memberof TrTradingCreateOrderRequest
     */
    'order_class'?: OrderClass;
    /**
     * 
     * @type {CreateOrderRequestTakeProfit}
     * @memberof TrTradingCreateOrderRequest
     */
    'take_profit'?: CreateOrderRequestTakeProfit;
    /**
     * 
     * @type {CreateOrderRequestStopLoss}
     * @memberof TrTradingCreateOrderRequest
     */
    'stop_loss'?: CreateOrderRequestStopLoss;
    /**
     * The commission you want to collect from the user.
     * @type {string}
     * @memberof TrTradingCreateOrderRequest
     */
    'commission'?: string;
}
/**
 * 
 * @export
 * @interface TrTradingOrder
 */
export interface TrTradingOrder {
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'client_order_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'submitted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'filled_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'expired_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'canceled_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'failed_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'replaced_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'replaced_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'replaces'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'asset_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'symbol': string;
    /**
     * 
     * @type {AssetClass}
     * @memberof TrTradingOrder
     */
    'asset_class'?: AssetClass;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'notional'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'qty'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'filled_qty'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'filled_avg_price'?: string | null;
    /**
     * 
     * @type {OrderClass}
     * @memberof TrTradingOrder
     */
    'order_class'?: OrderClass;
    /**
     * 
     * @type {OrderType}
     * @memberof TrTradingOrder
     */
    'order_type'?: OrderType;
    /**
     * 
     * @type {OrderType}
     * @memberof TrTradingOrder
     */
    'type'?: OrderType;
    /**
     * 
     * @type {OrderSide}
     * @memberof TrTradingOrder
     */
    'side'?: OrderSide;
    /**
     * 
     * @type {TimeInForce}
     * @memberof TrTradingOrder
     */
    'time_in_force'?: TimeInForce;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'limit_price'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'stop_price'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof TrTradingOrder
     */
    'status'?: OrderStatus;
    /**
     * 
     * @type {boolean}
     * @memberof TrTradingOrder
     */
    'extended_hours'?: boolean;
    /**
     * 
     * @type {Array<Order>}
     * @memberof TrTradingOrder
     */
    'legs'?: Array<Order> | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'trail_price'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'trail_percent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'hwm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTradingOrder
     */
    'commission'?: string;
}
/**
 * 
 * @export
 * @interface TrTradingPosition
 */
export interface TrTradingPosition {
    /**
     * Asset ID
     * @type {string}
     * @memberof TrTradingPosition
     */
    'asset_id': string;
    /**
     * Asset symbol
     * @type {string}
     * @memberof TrTradingPosition
     */
    'symbol': string;
    /**
     * Exchange name of the asset
     * @type {string}
     * @memberof TrTradingPosition
     */
    'exchange': string;
    /**
     * 
     * @type {AssetClass}
     * @memberof TrTradingPosition
     */
    'asset_class': AssetClass;
    /**
     * Indicates if this asset is marginable
     * @type {boolean}
     * @memberof TrTradingPosition
     */
    'asset_marginable'?: boolean;
    /**
     * Average entry price of the position
     * @type {string}
     * @memberof TrTradingPosition
     */
    'avg_entry_price': string;
    /**
     * The number of shares
     * @type {string}
     * @memberof TrTradingPosition
     */
    'qty': string;
    /**
     * 
     * @type {string}
     * @memberof TrTradingPosition
     */
    'side': TrTradingPositionSideEnum;
    /**
     * Total dollar amount of the position
     * @type {string}
     * @memberof TrTradingPosition
     */
    'market_value': string;
    /**
     * Total cost basis in dollar
     * @type {string}
     * @memberof TrTradingPosition
     */
    'cost_basis': string;
    /**
     * Unrealized profit/loss in dollars
     * @type {string}
     * @memberof TrTradingPosition
     */
    'unrealized_pl': string;
    /**
     * Unrealized profit/loss percent (by a factor of 1)
     * @type {string}
     * @memberof TrTradingPosition
     */
    'unrealized_plpc': string;
    /**
     * Unrealized profit/loss in dollars for the day
     * @type {string}
     * @memberof TrTradingPosition
     */
    'unrealized_intraday_pl': string;
    /**
     * Unrealized interday profit/loss percent (by a factor of 1)
     * @type {string}
     * @memberof TrTradingPosition
     */
    'unrealized_intraday_plpc': string;
    /**
     * Current asset price per share
     * @type {string}
     * @memberof TrTradingPosition
     */
    'current_price': string;
    /**
     * Last day’s asset price per share based on the closing value of the last trading day
     * @type {string}
     * @memberof TrTradingPosition
     */
    'lastday_price': string;
    /**
     * Percent change from last day price (by a factor of 1)
     * @type {string}
     * @memberof TrTradingPosition
     */
    'change_today': string;
}

export const TrTradingPositionSideEnum = {
    Long: 'long',
    Short: 'short'
} as const;

export type TrTradingPositionSideEnum = typeof TrTradingPositionSideEnum[keyof typeof TrTradingPositionSideEnum];

/**
 * 
 * @export
 * @interface TrTransferResource
 */
export interface TrTransferResource {
    /**
     * 
     * @type {string}
     * @memberof TrTransferResource
     */
    'amount'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTransferResource
     */
    'created_at'?: string;
    /**
     * 
     * @type {TransferDirection}
     * @memberof TrTransferResource
     */
    'direction'?: TransferDirection;
    /**
     * 
     * @type {string}
     * @memberof TrTransferResource
     */
    'expires_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTransferResource
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrTransferResource
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrTransferResource
     */
    'relationship_id'?: string;
    /**
     * 
     * @type {TransferStatus}
     * @memberof TrTransferResource
     */
    'status'?: TransferStatus;
    /**
     * 
     * @type {string}
     * @memberof TrTransferResource
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface TrUserConfig
 */
export interface TrUserConfig {
    /**
     * firebase Id, auto generated by backend
     * @type {string}
     * @memberof TrUserConfig
     */
    'id'?: string;
    /**
     * firebase cloud messaging token (TODO move to truserconfig)
     * @type {string}
     * @memberof TrUserConfig
     */
    'fcm_token'?: string;
    /**
     * has gone through getting started
     * @type {boolean}
     * @memberof TrUserConfig
     */
    'getting_started'?: boolean;
    /**
     * annual dividend goal in $
     * @type {number}
     * @memberof TrUserConfig
     */
    'annual_dividend_goal'?: number;
    /**
     * monthly contribution
     * @type {number}
     * @memberof TrUserConfig
     */
    'goal_calc_monthly_contribution'?: number;
    /**
     * percent div growth
     * @type {number}
     * @memberof TrUserConfig
     */
    'goal_calc_div_percent'?: number;
    /**
     * percent stock growth
     * @type {number}
     * @memberof TrUserConfig
     */
    'goal_calc_stock_percent'?: number;
    /**
     * gifts to wish go to this array of stocks and percentages
     * @type {Array<TrWish>}
     * @memberof TrUserConfig
     */
    'wish'?: Array<TrWish>;
    /**
     * contacts list viewing order. since users can adjust their list
     * @type {Array<string>}
     * @memberof TrUserConfig
     */
    'contacts_lineup'?: Array<string>;
    /**
     * contacts list viewing order. since users can adjust their list
     * @type {Array<string>}
     * @memberof TrUserConfig
     */
    'mykids_lineup'?: Array<string>;
    /**
     * people who have contributed to me firebase ids
     * @type {Array<string>}
     * @memberof TrUserConfig
     */
    'contributors'?: Array<string>;
}
/**
 * user policy including gift
 * @export
 * @interface TrUserPolicy
 */
export interface TrUserPolicy {
    /**
     * 
     * @type {TrUserPolicyGift}
     * @memberof TrUserPolicy
     */
    'gift'?: TrUserPolicyGift;
    /**
     * 
     * @type {string}
     * @memberof TrUserPolicy
     */
    'subscription'?: TrUserPolicySubscriptionEnum;
}

export const TrUserPolicySubscriptionEnum = {
    Free: 'free',
    Basic: 'basic',
    Pro: 'pro'
} as const;

export type TrUserPolicySubscriptionEnum = typeof TrUserPolicySubscriptionEnum[keyof typeof TrUserPolicySubscriptionEnum];

/**
 * 
 * @export
 * @interface TrUserPolicyGift
 */
export interface TrUserPolicyGift {
    /**
     * 
     * @type {number}
     * @memberof TrUserPolicyGift
     */
    'amount_max_day'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrUserPolicyGift
     */
    'amount_remaining_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrUserPolicyGift
     */
    'fee_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrUserPolicyGift
     */
    'fee_min'?: number;
    /**
     * current remaining count of remaining free gifts
     * @type {number}
     * @memberof TrUserPolicyGift
     */
    'pass_remaining_gift_count'?: number;
}
/**
 * 
 * @export
 * @interface TrUserProfile
 */
export interface TrUserProfile {
    /**
     * YYYY-MM-DD
     * @type {string}
     * @memberof TrUserProfile
     */
    'birthdate'?: string;
    /**
     * email or parent email.
     * @type {string}
     * @memberof TrUserProfile
     */
    'email'?: string;
    /**
     * substitute email.
     * @type {string}
     * @memberof TrUserProfile
     */
    'email_substitute'?: string;
    /**
     * user full name
     * @type {string}
     * @memberof TrUserProfile
     */
    'firstname'?: string;
    /**
     * entity Id. key from db.
     * @type {string}
     * @memberof TrUserProfile
     */
    'id'?: string;
    /**
     * if guardian has username only, pass contacts Entity Id.
     * @type {string}
     * @memberof TrUserProfile
     */
    'guardian_id'?: string;
    /**
     * if guardian has email only, pass contacts email.
     * @type {string}
     * @memberof TrUserProfile
     */
    'guardian_email'?: string;
    /**
     * alpaca id
     * @type {string}
     * @memberof TrUserProfile
     */
    'alpaca_id'?: string;
    /**
     * user full name
     * @type {string}
     * @memberof TrUserProfile
     */
    'lastname'?: string;
    /**
     * phone number
     * @type {string}
     * @memberof TrUserProfile
     */
    'phone'?: string;
    /**
     * profile Url
     * @type {string}
     * @memberof TrUserProfile
     */
    'profile_url'?: string;
    /**
     * income range
     * @type {string}
     * @memberof TrUserProfile
     */
    'income_range'?: string;
    /**
     * 
     * @type {TrRelation}
     * @memberof TrUserProfile
     */
    'relationship'?: TrRelation;
    /**
     * username. only users on the platform have usernames. minors do not have usernames
     * @type {string}
     * @memberof TrUserProfile
     */
    'username'?: string;
    /**
     * username lowercase for search
     * @type {string}
     * @memberof TrUserProfile
     */
    'username_lowercase'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrUserProfile
     */
    'firstname_lowercase'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrUserProfile
     */
    'lastname_lowercase'?: string;
    /**
     * is public in search. by default minors are hidden(false)
     * @type {boolean}
     * @memberof TrUserProfile
     */
    'is_public'?: boolean;
    /**
     * last time user active in signup alpaca process. completed = undefined.
     * @type {number}
     * @memberof TrUserProfile
     */
    'last_signup_alpaca_epoch'?: number;
    /**
     * human readable. not for querying. completed = undefined.
     * @type {string}
     * @memberof TrUserProfile
     */
    'last_signup_alpaca'?: string;
    /**
     * last time user active in signup pay subscription process. completed = undefined.
     * @type {number}
     * @memberof TrUserProfile
     */
    'last_signup_pay_epoch'?: number;
    /**
     * human readable. not for querying. completed = undefined.
     * @type {string}
     * @memberof TrUserProfile
     */
    'last_signup_pay'?: string;
}
/**
 * 
 * @export
 * @interface TrUserProfilePublic
 */
export interface TrUserProfilePublic {
    /**
     * entity Id. key from db.
     * @type {string}
     * @memberof TrUserProfilePublic
     */
    'id'?: string;
    /**
     * user full name
     * @type {string}
     * @memberof TrUserProfilePublic
     */
    'firstname'?: string;
    /**
     * user full name
     * @type {string}
     * @memberof TrUserProfilePublic
     */
    'lastname'?: string;
    /**
     * profile Url
     * @type {string}
     * @memberof TrUserProfilePublic
     */
    'profile_url'?: string;
    /**
     * username. only users on the platform have usernames. minors do not have usernames
     * @type {string}
     * @memberof TrUserProfilePublic
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface TrWish
 */
export interface TrWish {
    /**
     * 
     * @type {string}
     * @memberof TrWish
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof TrWish
     */
    'percent': number;
}
/**
 * 
 * @export
 * @interface TradeActivity
 */
export interface TradeActivity {
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TradeActivity
     */
    'transaction_time'?: string;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TradeActivity
     */
    'type'?: TradeActivityTypeEnum;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TradeActivity
     */
    'price'?: string;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TradeActivity
     */
    'qty'?: string;
    /**
     * 
     * @type {OrderSide}
     * @memberof TradeActivity
     */
    'side'?: OrderSide;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TradeActivity
     */
    'symbol'?: string;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TradeActivity
     */
    'leaves_qty'?: string;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TradeActivity
     */
    'order_id'?: string;
    /**
     * Valid only for trading activity types. Null for non-trading activites.
     * @type {string}
     * @memberof TradeActivity
     */
    'cum_qty'?: string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof TradeActivity
     */
    'order_status'?: OrderStatus;
}

export const TradeActivityTypeEnum = {
    Fill: 'fill',
    PartialFill: 'partial_fill'
} as const;

export type TradeActivityTypeEnum = typeof TradeActivityTypeEnum[keyof typeof TradeActivityTypeEnum];

/**
 * - **INCOMING** Funds incoming to user’s account (deposit). - **OUTGOING** Funds outgoing from user’s account (withdrawal). 
 * @export
 * @enum {string}
 */

export const TransferDirection = {
    Incoming: 'INCOMING',
    Outgoing: 'OUTGOING'
} as const;

export type TransferDirection = typeof TransferDirection[keyof typeof TransferDirection];


/**
 * - **QUEUED** Transfer is in queue to be processed. - **APPROVAL_PENDING** Transfer is pending approval. - **PENDING** Transfer is pending processing. - **SENT_TO_CLEARING** Transfer is being processed by the clearing firm. - **REJECTED** Transfer is rejected. - **CANCELED** Client initiated transfer cancellation. - **APPROVED** Transfer is approved. - **COMPLETE** Transfer is completed. - **RETURNED** The bank issued an ACH return for the transfer. 
 * @export
 * @enum {string}
 */

export const TransferStatus = {
    Queued: 'QUEUED',
    ApprovalPending: 'APPROVAL_PENDING',
    Pending: 'PENDING',
    SentToClearing: 'SENT_TO_CLEARING',
    Rejected: 'REJECTED',
    Canceled: 'CANCELED',
    Approved: 'APPROVED',
    Complete: 'COMPLETE',
    Returned: 'RETURNED'
} as const;

export type TransferStatus = typeof TransferStatus[keyof typeof TransferStatus];


/**
 * This model input is optional. However, the client should make reasonable effort to obtain the trusted contact information. See more details in [FINRA Notice 17-11](https://www.finra.org/sites/default/files/Regulatory-Notice-17-11.pdf) 
 * @export
 * @interface TrustedContact
 */
export interface TrustedContact {
    /**
     * 
     * @type {string}
     * @memberof TrustedContact
     */
    'given_name': string;
    /**
     * 
     * @type {string}
     * @memberof TrustedContact
     */
    'family_name': string;
    /**
     * at least one of `email_address`, `phone_number` or `street_address` is required 
     * @type {string}
     * @memberof TrustedContact
     */
    'email_address'?: string;
    /**
     * at least one of `email_address`, `phone_number` or `street_address` is required 
     * @type {string}
     * @memberof TrustedContact
     */
    'phone_number'?: string;
    /**
     * at least one of `email_address`, `phone_number` or `street_address` is required 
     * @type {Array<string>}
     * @memberof TrustedContact
     */
    'street_address'?: Array<string>;
    /**
     * required if `street_address` is set 
     * @type {string}
     * @memberof TrustedContact
     */
    'city'?: string;
    /**
     * required if `street_address` is set 
     * @type {string}
     * @memberof TrustedContact
     */
    'state'?: string;
    /**
     * required if `street_address` is set 
     * @type {string}
     * @memberof TrustedContact
     */
    'postal_code'?: string;
    /**
     * [ISO 3166-1 alpha-3](https://www.iso.org/iso-3166-country-codes.html). required if `street_address` is set 
     * @type {string}
     * @memberof TrustedContact
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface UntypedACHTransferData
 */
export interface UntypedACHTransferData {
    /**
     * 
     * @type {string}
     * @memberof UntypedACHTransferData
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof UntypedACHTransferData
     */
    'direction': UntypedACHTransferDataDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof UntypedACHTransferData
     */
    'relationship_id': string;
}

export const UntypedACHTransferDataDirectionEnum = {
    Incoming: 'INCOMING',
    Outgoing: 'OUTGOING'
} as const;

export type UntypedACHTransferDataDirectionEnum = typeof UntypedACHTransferDataDirectionEnum[keyof typeof UntypedACHTransferDataDirectionEnum];

/**
 * 
 * @export
 * @interface UntypedACHTransferDataAllOf
 */
export interface UntypedACHTransferDataAllOf {
    /**
     * 
     * @type {string}
     * @memberof UntypedACHTransferDataAllOf
     */
    'relationship_id': string;
}
/**
 * 
 * @export
 * @interface UntypedTransferData
 */
export interface UntypedTransferData {
    /**
     * 
     * @type {string}
     * @memberof UntypedTransferData
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof UntypedTransferData
     */
    'direction': UntypedTransferDataDirectionEnum;
}

export const UntypedTransferDataDirectionEnum = {
    Incoming: 'INCOMING',
    Outgoing: 'OUTGOING'
} as const;

export type UntypedTransferDataDirectionEnum = typeof UntypedTransferDataDirectionEnum[keyof typeof UntypedTransferDataDirectionEnum];

/**
 * 
 * @export
 * @interface UsersContactsAvatarPostRequest
 */
export interface UsersContactsAvatarPostRequest {
    /**
     * 
     * @type {any}
     * @memberof UsersContactsAvatarPostRequest
     */
    'file'?: any;
}
/**
 * 
 * @export
 * @interface VerifyMySecondaryEmailRequest
 */
export interface VerifyMySecondaryEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyMySecondaryEmailRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyMySecondaryEmailRequest
     */
    'code'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get ACH relationship
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAchRelationshipsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/ach-relationships`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an ACH relationship
         * @param {string} relationshipId relationship_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAchRelationshipsRelationshipIdDelete: async (relationshipId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relationshipId' is not null or undefined
            assertParamExists('accountsAchRelationshipsRelationshipIdDelete', 'relationshipId', relationshipId)
            const localVarPath = `/accounts/ach-relationships/{relationship_id}`
                .replace(`{${"relationship_id"}}`, encodeURIComponent(String(relationshipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * all alpaca activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsActivityGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get custodial accounts (Alpaca)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCustodialGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/custodial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update custodial account (Alpaca)
         * @param {string} [id] custodial account firebase uid
         * @param {TrAccountInfo} [trAccountInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCustodialPatch: async (id?: string, trAccountInfo?: TrAccountInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/custodial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trAccountInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a custodial stock account (Alpaca)
         * @param {TrCustodialAccountCreationRequest} [trCustodialAccountCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCustodialPost: async (trCustodialAccountCreationRequest?: TrCustodialAccountCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/custodial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trCustodialAccountCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns a url to download?
         * @param {string} [docId] document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsDocumentsDownloadGet: async (docId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/documents/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (docId !== undefined) {
                localVarQueryParameter['doc_id'] = docId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns a list of documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsDocumentsListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/documents/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get stock account information (Alpaca)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update stock account (Alpaca)
         * @param {TrAccountInfo} [trAccountInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPatch: async (trAccountInfo?: TrAccountInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trAccountInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get plaid link token
         * @param {TrPlaidPublicToken} [trPlaidPublicToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPlaidAlpacaProcessorTokenPost: async (trPlaidPublicToken?: TrPlaidPublicToken, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/plaid/alpaca-processor-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trPlaidPublicToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get plaid link token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPlaidLinkTokenPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/plaid/link-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get plaid link token
         * @param {TrPlaidPublicToken} [trPlaidPublicToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPlaidStripeProcessorTokenPost: async (trPlaidPublicToken?: TrPlaidPublicToken, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/plaid/stripe-processor-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trPlaidPublicToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create stock account (Alpaca)
         * @param {TrAccountCreationRequest} [trAccountCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPost: async (trAccountCreationRequest?: TrAccountCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trAccountCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * all transfers by account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsTransfersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * transfer fund
         * @param {TrACHTransferData} [trACHTransferData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsTransfersPost: async (trACHTransferData?: TrACHTransferData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trACHTransferData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add email address and send a verification emil. if the email address is alreay added without verification and sent out verification email is expired, send a verification email again
         * @summary add email address and send a verification email
         * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMySecondaryEmail: async (addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/emails/secondary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMySecondaryEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add email address and send a verification emil. if the email address is alreay added without verification and sent out verification email is expired, send a verification email again
         * @summary add email address and send a verification email
         * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMySubstituteEmail: async (addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/emails/substitute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMySecondaryEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cancel a gift. can be cancelled by sender or receiver
         * @param {string} giftId gift id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelGift: async (giftId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'giftId' is not null or undefined
            assertParamExists('cancelGift', 'giftId', giftId)
            const localVarPath = `/gifts/{gift_id}`
                .replace(`{${"gift_id"}}`, encodeURIComponent(String(giftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary send Event to user. notifications and/or background data to user
         * @param {Array<TrEvent>} [trEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicatorEventPost: async (trEvent?: Array<TrEvent>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/communicator/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new ACHRelationship for an account
         * @param {TrCreateACHRelationshipRequest} trCreateACHRelationshipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createACHRelationship: async (trCreateACHRelationshipRequest: TrCreateACHRelationshipRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trCreateACHRelationshipRequest' is not null or undefined
            assertParamExists('createACHRelationship', 'trCreateACHRelationshipRequest', trCreateACHRelationshipRequest)
            const localVarPath = `/accounts/ach-relationships`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trCreateACHRelationshipRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create Gift
         * @param {TrGiftRequest} [trGiftRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGift: async (trGiftRequest?: TrGiftRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gifts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trGiftRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/stripe/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * remove secondary email
         * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMySecondaryEmail: async (addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/emails/secondary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMySecondaryEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * dividend alpaca activity
         * @param {string} [symbol] symbol, if not specified, send all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDividendActivity: async (symbol?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/activity/dividend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get gift summary by receiver Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/featureflags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a gift
         * @param {string} giftId gift id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGift: async (giftId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'giftId' is not null or undefined
            assertParamExists('getGift', 'giftId', giftId)
            const localVarPath = `/gifts/{gift_id}`
                .replace(`{${"gift_id"}}`, encodeURIComponent(String(giftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of Gift, including custodial account gifts
         * @param {TrRole} [role] sender or receiver
         * @param {number} [limit] limit the number of results, default is set to 50
         * @param {string} [otherUserId] for \&#39;pair\&#39;, either otherUserId or otherEmail is required. returns all gifts between 2 people including as guardian
         * @param {string} [otherEmail] for \&#39;pair\&#39;, either otherUserId or otherEmail is required. returns all gifts between 2 people including as guardian
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftList: async (role?: TrRole, limit?: number, otherUserId?: string, otherEmail?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gifts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (otherUserId !== undefined) {
                localVarQueryParameter['otherUserId'] = otherUserId;
            }

            if (otherEmail !== undefined) {
                localVarQueryParameter['otherEmail'] = otherEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the bundle options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftPassProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/passes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a gift
         * @param {string} giftId gift id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftPublic: async (giftId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'giftId' is not null or undefined
            assertParamExists('getGiftPublic', 'giftId', giftId)
            const localVarPath = `/gifts/public/{gift_id}`
                .replace(`{${"gift_id"}}`, encodeURIComponent(String(giftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get gift summary by receiver Id
         * @param {string} receiverId receiver Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftReceiverSummary: async (receiverId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiverId' is not null or undefined
            assertParamExists('getGiftReceiverSummary', 'receiverId', receiverId)
            const localVarPath = `/gifts/receiver_summary/{receiver_id}`
                .replace(`{${"receiver_id"}}`, encodeURIComponent(String(receiverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get invoices (mostly subscriptions)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/stripe/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets self data
         * @summary get list of my emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyEmails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/stripe/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * current user\'s policy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPolicy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns the latest trade data 
         * @summary Returns the latest trade data
         * @param {string} symbols symbols, comma delimited
         * @param {AnnouncementCAType} [type] corporate action type
         * @param {string} [since] since YYYY-MM-DD format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAnnouncementsGet: async (symbols: string, type?: AnnouncementCAType, since?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'symbols' is not null or undefined
            assertParamExists('marketAnnouncementsGet', 'symbols', symbols)
            const localVarPath = `/market/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbols !== undefined) {
                localVarQueryParameter['symbols'] = symbols;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns aggregate historical data for the requested securities. 
         * @summary aggregate historical data
         * @param {string} [symbol] stock symbol
         * @param {string} [start] Filter data equal to or after this time in RFC-3339 format. Fractions of a second are not accepted 
         * @param {string} [end] Filter data equal to or after this time in RFC-3339 format. Fractions of a second are not accepted 
         * @param {string} [timeframe] Timeframe for the aggregation. Available values are flexible for Min, Hour, Day time window sizes with a maximum constraint on the values: 60Min, 24Hour, 31Day 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketBarsGet: async (symbol?: string, start?: string, end?: string, timeframe?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/bars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (timeframe !== undefined) {
                localVarQueryParameter['timeframe'] = timeframe;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The calendar API serves the full list of market days from 1970 to 2029. It can also be queried by specifying a start and/or end time to narrow down the results. In addition to the dates, the response also contains the specific open and close times for the market days, taking into account early closures. 
         * @summary current market timestamp, market open or close
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketCalendarGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The Clock API serves the current market timestamp, whether or not the market is currently open, as well as the times of the next market open and close. 
         * @summary current market timestamp, market open or close
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketClockGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/clock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns a list of popular stocks. latest quote and details 
         * @summary returns the list of stock names and tickers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get dividends
         * @summary Returns the latest trade data
         * @param {string} symbol symbol
         * @param {string} [since] since YYYY-MM-DD format
         * @param {string} [until] until YYYY-MM-DD format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketMetricsDividendsGet: async (symbol: string, since?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('marketMetricsDividendsGet', 'symbol', symbol)
            const localVarPath = `/market/metrics/dividends`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * from polygon. income statement, cash flow, balance sheet
         * @summary Returns the latest financials data from polygon
         * @param {string} symbol symbol
         * @param {string} [since] since YYYY-MM-DD format
         * @param {string} [until] until YYYY-MM-DD format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketMetricsFinancialsLatestGet: async (symbol: string, since?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('marketMetricsFinancialsLatestGet', 'symbol', symbol)
            const localVarPath = `/market/metrics/financials/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get metric by symbol
         * @summary Returns stats like yield, cagr, payout ratio, fcf payout
         * @param {string} symbol symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketMetricsGet: async (symbol: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('marketMetricsGet', 'symbol', symbol)
            const localVarPath = `/market/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns a object of popular stocks.
         * @summary returns a list of popular stocks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPopularGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/popular`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns market symbol info. Bid ask price size 
         * @summary Returns a stock quote.
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketQuotesLatestGet: async (symbol?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/quotes/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * multiple tickers provides the latest trade, latest quote, minute bar daily bar and previous daily bar data for the given ticker symbols 
         * @summary multiple snapshots
         * @param {string} [symbols] comma separated stock symbols
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSnapshotsGet: async (symbols?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/snapshots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbols !== undefined) {
                localVarQueryParameter['symbols'] = symbols;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get symbol alpaca asset
         * @summary symbol asset
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolAssetGet: async (symbol?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/symbol/asset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary brand filenames, logos and icons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolBrandsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/symbol/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary brand filenames, logos and icons
         * @param {string} [name] name
         * @param {string} [symbol] symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolBrandsKnownGet: async (name?: string, symbol?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/symbol/brands/known`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns general information for the requested securities. https://polygon.io/docs/stocks/get_v3_reference_tickers__ticker 
         * @summary polygon symbol details V3
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolDetailGet: async (symbol?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/symbol/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * multiple tickers provides the general info for the given ticker symbols 
         * @summary multiple symbol summaries
         * @param {string} [symbols] comma separated stock symbols
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolGet: async (symbols?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/symbol`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbols !== undefined) {
                localVarQueryParameter['symbols'] = symbols;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update db symbol summary. typically a cron job. primary a button to easily update local env.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolUpdateSummaryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/symbol/update-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns the latest trade data 
         * @summary Returns the latest trade data
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketTradesLatestGet: async (symbol?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market/trades/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * queue email notification
         * @summary queue email notification
         * @param {TrNotifyEmail} [trNotifyEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyEmailPost: async (trNotifyEmail?: TrNotifyEmail, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notify/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trNotifyEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
         * @summary updates self user entity. ie. username, address
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMyUserProfile: async (trUserProfile?: TrUserProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trUserProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * redeem a gift
         * @param {string} giftId gift id
         * @param {TrGiftRedeemUpdate} [trGiftRedeemUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemGift: async (giftId: string, trGiftRedeemUpdate?: TrGiftRedeemUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'giftId' is not null or undefined
            assertParamExists('redeemGift', 'giftId', giftId)
            const localVarPath = `/gifts/redeem/{gift_id}'`
                .replace(`{${"gift_id"}}`, encodeURIComponent(String(giftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trGiftRedeemUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary send email verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailVerificationCode: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notify/verify/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add signed user to waitlist using firebase email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemWaitlistPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/waitlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * trading account info
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingAccountGet: async (minorId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (minorId !== undefined) {
                localVarQueryParameter['minor_id'] = minorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * cancel pending order
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {string} [orderId] order id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingOrdersDelete: async (minorId?: string, orderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (minorId !== undefined) {
                localVarQueryParameter['minor_id'] = minorId;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order_id'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get stock orders
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {'open' | 'closed' | 'all'} [status] Status of the orders to list.
         * @param {number} [limit] The maximum number of orders in response.
         * @param {string} [after] The response will include only ones submitted after this timestamp (exclusive.)
         * @param {string} [until] The response will include only ones submitted until this timestamp (exclusive.)
         * @param {'asc' | 'desc'} [direction] The chronological order of response based on the submission time. asc or desc. Defaults to desc.
         * @param {boolean} [nested] If true, the result will roll up multi-leg orders under the legs field of primary order.
         * @param {string} [symbols] A comma-separated list of symbols to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingOrdersGet: async (minorId?: string, status?: 'open' | 'closed' | 'all', limit?: number, after?: string, until?: string, direction?: 'asc' | 'desc', nested?: boolean, symbols?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (minorId !== undefined) {
                localVarQueryParameter['minor_id'] = minorId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = (after as any instanceof Date) ?
                    (after as any).toISOString() :
                    after;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }

            if (symbols !== undefined) {
                localVarQueryParameter['symbols'] = symbols;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * order stock for myself
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {TrTradingCreateOrderRequest} [trTradingCreateOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingOrdersPost: async (minorId?: string, trTradingCreateOrderRequest?: TrTradingCreateOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (minorId !== undefined) {
                localVarQueryParameter['minor_id'] = minorId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trTradingCreateOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * portfolio history
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {string} [period] The duration of the data in number + unit (ex 7D, 1M, 2W). Default 1M 
         * @param {string} [timeframe] The resolution of time window. 1Min, 5Min, 15Min, 1H, or 1D.  If omitted, 1Min for less than 7 days period, 15Min for less than 30 days, or otherwise 1D. 
         * @param {string} [dateEnd] The date the data is returned up to, in “YYYY-MM-DD” format. Defaults to the current market date (rolls over at the market open if extended_hours is false, otherwise at 7am ET) 
         * @param {boolean} [extendedHours] If true, include extended hours in the result. This is effective only for timeframe less than 1D. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingPortfolioHistoryGet: async (minorId?: string, period?: string, timeframe?: string, dateEnd?: string, extendedHours?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/portfolio/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (minorId !== undefined) {
                localVarQueryParameter['minor_id'] = minorId;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (timeframe !== undefined) {
                localVarQueryParameter['timeframe'] = timeframe;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['date_end'] = (dateEnd as any instanceof Date) ?
                    (dateEnd as any).toISOString() :
                    dateEnd;
            }

            if (extendedHours !== undefined) {
                localVarQueryParameter['extended_hours'] = extendedHours;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * stock positions
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingPositionsGet: async (minorId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trading/positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (minorId !== undefined) {
                localVarQueryParameter['minor_id'] = minorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update account contact info. this can be called before alpaca account creation
         * @param {TrAccountInfo} [trAccountInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountContact: async (trAccountInfo?: TrAccountInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trAccountInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * save gift image to Storage and store in db
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGiftImage: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gifts/contents/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * for displaying contributor avatar list
         * @summary gets contributors UserProfilePublic
         * @param {string} [receiverId] receiver_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersConfigContributorsGet: async (receiverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/config/contributors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (receiverId !== undefined) {
                localVarQueryParameter['receiver_id'] = receiverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * either self or minor config. data not part of profile.
         * @summary gets config data
         * @param {string} [id] custodial user firebase uid(s). comma delimited
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersConfigGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * either self or minor config. params not related to the user profile
         * @summary updates user config entity,  ie. wish
         * @param {TrUserConfig} [trUserConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersConfigPatch: async (trUserConfig?: TrUserConfig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trUserConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * save profile image to Storage and store in db
         * @param {UsersContactsAvatarPostRequest} usersContactsAvatarPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersContactsAvatarPost: async (usersContactsAvatarPostRequest: UsersContactsAvatarPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersContactsAvatarPostRequest' is not null or undefined
            assertParamExists('usersContactsAvatarPost', 'usersContactsAvatarPostRequest', usersContactsAvatarPostRequest)
            const localVarPath = `/users/contacts/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'image/png';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersContactsAvatarPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * remove a social contact.
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersContactsDelete: async (trUserProfile?: TrUserProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trUserProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of friends. Only returns public information such as name, username, profiles pics. 
         * @summary Returns the users social circle. ie. list of friends and contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersContactsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersContactsPatch: async (trUserProfile?: TrUserProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trUserProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * save profile image to Storage and store in db
         * @param {UsersContactsAvatarPostRequest} usersContactsAvatarPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCustodialAvatarPost: async (usersContactsAvatarPostRequest: UsersContactsAvatarPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersContactsAvatarPostRequest' is not null or undefined
            assertParamExists('usersCustodialAvatarPost', 'usersContactsAvatarPostRequest', usersContactsAvatarPostRequest)
            const localVarPath = `/users/custodial/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'image/png';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersContactsAvatarPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get list of custodial user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCustodialGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/custodial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
         * @summary updates self user entity. ie. username, address, wish
         * @param {string} [id] custodial user firebase uid
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCustodialPatch: async (id?: string, trUserProfile?: TrUserProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/custodial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trUserProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of users. Only returns public information such as name, username, profiles pics etc. Usage: search a user for sending stock, add as a friend, etc 
         * @summary Returns a list of users.
         * @param {string} [username] username
         * @param {string} [keyword] user search keyword (username, name, email, phone number)
         * @param {string} [email] email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (username?: string, keyword?: string, email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns user profile
         * @summary Returns user by id
         * @param {string} id user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdIdGet', 'id', id)
            const localVarPath = `/users/id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * save profile image to Storage and store in db
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeAvatarPost: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'image/png';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets self data
         * @summary gets self data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary new user, for tracking purposes. before alpaca signup finished.
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMePost: async (trUserProfile?: TrUserProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trUserProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * given username, returns public profile.
         * @summary Checks if username exists
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPublicGet: async (username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets list of users in signup process. returns array of truserprofiles
         * @summary gets list of users in signup process
         * @param {string} [since] since epoch time
         * @param {string} [until] until epoch time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersTrackInsignupsGet: async (since?: string, until?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/track/insignups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * checks if username exists. 
         * @summary Checks if username exists
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernameGet: async (username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/username`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary verify email
         * @param {VerifyMySecondaryEmailRequest} [verifyMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMySecondaryEmail: async (verifyMySecondaryEmailRequest?: VerifyMySecondaryEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/emails/secondary/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyMySecondaryEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary verify email
         * @param {VerifyMySecondaryEmailRequest} [verifyMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMySubstituteEmail: async (verifyMySecondaryEmailRequest?: VerifyMySecondaryEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/emails/substitute/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication firebaseBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyMySecondaryEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Get ACH relationship
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAchRelationshipsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrACHRelationshipData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAchRelationshipsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an ACH relationship
         * @param {string} relationshipId relationship_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAchRelationshipsRelationshipIdDelete(relationshipId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAchRelationshipsRelationshipIdDelete(relationshipId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * all alpaca activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsActivityGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrAccountActivityItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsActivityGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get custodial accounts (Alpaca)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsCustodialGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrAccountInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsCustodialGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update custodial account (Alpaca)
         * @param {string} [id] custodial account firebase uid
         * @param {TrAccountInfo} [trAccountInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsCustodialPatch(id?: string, trAccountInfo?: TrAccountInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsCustodialPatch(id, trAccountInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a custodial stock account (Alpaca)
         * @param {TrCustodialAccountCreationRequest} [trCustodialAccountCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsCustodialPost(trCustodialAccountCreationRequest?: TrCustodialAccountCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsCustodialPost(trCustodialAccountCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns a url to download?
         * @param {string} [docId] document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsDocumentsDownloadGet(docId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsDocumentsDownloadGet(docId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns a list of documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsDocumentsListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrAccountDocumentItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsDocumentsListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get stock account information (Alpaca)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update stock account (Alpaca)
         * @param {TrAccountInfo} [trAccountInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsPatch(trAccountInfo?: TrAccountInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsPatch(trAccountInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get plaid link token
         * @param {TrPlaidPublicToken} [trPlaidPublicToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsPlaidAlpacaProcessorTokenPost(trPlaidPublicToken?: TrPlaidPublicToken, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsPlaidAlpacaProcessorTokenPost(trPlaidPublicToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get plaid link token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsPlaidLinkTokenPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrPlaidLinkTokenCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsPlaidLinkTokenPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get plaid link token
         * @param {TrPlaidPublicToken} [trPlaidPublicToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsPlaidStripeProcessorTokenPost(trPlaidPublicToken?: TrPlaidPublicToken, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsPlaidStripeProcessorTokenPost(trPlaidPublicToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create stock account (Alpaca)
         * @param {TrAccountCreationRequest} [trAccountCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsPost(trAccountCreationRequest?: TrAccountCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsPost(trAccountCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * all transfers by account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsTransfersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrTransferResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsTransfersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * transfer fund
         * @param {TrACHTransferData} [trACHTransferData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsTransfersPost(trACHTransferData?: TrACHTransferData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrTransferResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsTransfersPost(trACHTransferData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * add email address and send a verification emil. if the email address is alreay added without verification and sent out verification email is expired, send a verification email again
         * @summary add email address and send a verification email
         * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMySecondaryEmail(addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMySecondaryEmail(addMySecondaryEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * add email address and send a verification emil. if the email address is alreay added without verification and sent out verification email is expired, send a verification email again
         * @summary add email address and send a verification email
         * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMySubstituteEmail(addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMySubstituteEmail(addMySecondaryEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary cancel a gift. can be cancelled by sender or receiver
         * @param {string} giftId gift id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelGift(giftId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrGiftStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelGift(giftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary send Event to user. notifications and/or background data to user
         * @param {Array<TrEvent>} [trEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communicatorEventPost(trEvent?: Array<TrEvent>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communicatorEventPost(trEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new ACHRelationship for an account
         * @param {TrCreateACHRelationshipRequest} trCreateACHRelationshipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createACHRelationship(trCreateACHRelationshipRequest: TrCreateACHRelationshipRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrACHRelationshipData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createACHRelationship(trCreateACHRelationshipRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create Gift
         * @param {TrGiftRequest} [trGiftRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGift(trGiftRequest?: TrGiftRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrGiftStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGift(trGiftRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrSubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * remove secondary email
         * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMySecondaryEmail(addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMySecondaryEmail(addMySecondaryEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * dividend alpaca activity
         * @param {string} [symbol] symbol, if not specified, send all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDividendActivity(symbol?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrAccountActivityItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDividendActivity(symbol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get gift summary by receiver Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureFlags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrFeatureFlags>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureFlags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a gift
         * @param {string} giftId gift id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGift(giftId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrGiftStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGift(giftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of Gift, including custodial account gifts
         * @param {TrRole} [role] sender or receiver
         * @param {number} [limit] limit the number of results, default is set to 50
         * @param {string} [otherUserId] for \&#39;pair\&#39;, either otherUserId or otherEmail is required. returns all gifts between 2 people including as guardian
         * @param {string} [otherEmail] for \&#39;pair\&#39;, either otherUserId or otherEmail is required. returns all gifts between 2 people including as guardian
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGiftList(role?: TrRole, limit?: number, otherUserId?: string, otherEmail?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrGiftStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGiftList(role, limit, otherUserId, otherEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the bundle options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGiftPassProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrProductGiftPass>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGiftPassProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a gift
         * @param {string} giftId gift id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGiftPublic(giftId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrGiftStatusPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGiftPublic(giftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get gift summary by receiver Id
         * @param {string} receiverId receiver Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGiftReceiverSummary(receiverId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrGiftReceiverSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGiftReceiverSummary(receiverId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get invoices (mostly subscriptions)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets self data
         * @summary get list of my emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyEmails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrEmail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyEmails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrSubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * current user\'s policy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPolicy(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrUserPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPolicy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns the latest trade data 
         * @summary Returns the latest trade data
         * @param {string} symbols symbols, comma delimited
         * @param {AnnouncementCAType} [type] corporate action type
         * @param {string} [since] since YYYY-MM-DD format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketAnnouncementsGet(symbols: string, type?: AnnouncementCAType, since?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Announcement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketAnnouncementsGet(symbols, type, since, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns aggregate historical data for the requested securities. 
         * @summary aggregate historical data
         * @param {string} [symbol] stock symbol
         * @param {string} [start] Filter data equal to or after this time in RFC-3339 format. Fractions of a second are not accepted 
         * @param {string} [end] Filter data equal to or after this time in RFC-3339 format. Fractions of a second are not accepted 
         * @param {string} [timeframe] Timeframe for the aggregation. Available values are flexible for Min, Hour, Day time window sizes with a maximum constraint on the values: 60Min, 24Hour, 31Day 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketBarsGet(symbol?: string, start?: string, end?: string, timeframe?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrMarketBars>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketBarsGet(symbol, start, end, timeframe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The calendar API serves the full list of market days from 1970 to 2029. It can also be queried by specifying a start and/or end time to narrow down the results. In addition to the dates, the response also contains the specific open and close times for the market days, taking into account early closures. 
         * @summary current market timestamp, market open or close
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketCalendarGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrMarketDay>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketCalendarGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The Clock API serves the current market timestamp, whether or not the market is currently open, as well as the times of the next market open and close. 
         * @summary current market timestamp, market open or close
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketClockGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrClock>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketClockGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns a list of popular stocks. latest quote and details 
         * @summary returns the list of stock names and tickers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrSymbolSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get dividends
         * @summary Returns the latest trade data
         * @param {string} symbol symbol
         * @param {string} [since] since YYYY-MM-DD format
         * @param {string} [until] until YYYY-MM-DD format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketMetricsDividendsGet(symbol: string, since?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Announcement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketMetricsDividendsGet(symbol, since, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * from polygon. income statement, cash flow, balance sheet
         * @summary Returns the latest financials data from polygon
         * @param {string} symbol symbol
         * @param {string} [since] since YYYY-MM-DD format
         * @param {string} [until] until YYYY-MM-DD format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketMetricsFinancialsLatestGet(symbol: string, since?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrSymbolFinancials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketMetricsFinancialsLatestGet(symbol, since, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get metric by symbol
         * @summary Returns stats like yield, cagr, payout ratio, fcf payout
         * @param {string} symbol symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketMetricsGet(symbol: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrSymbolCalc>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketMetricsGet(symbol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns a object of popular stocks.
         * @summary returns a list of popular stocks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPopularGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrMarketPopular>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPopularGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns market symbol info. Bid ask price size 
         * @summary Returns a stock quote.
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketQuotesLatestGet(symbol?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrMarketQuote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketQuotesLatestGet(symbol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * multiple tickers provides the latest trade, latest quote, minute bar daily bar and previous daily bar data for the given ticker symbols 
         * @summary multiple snapshots
         * @param {string} [symbols] comma separated stock symbols
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketSnapshotsGet(symbols?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrMarketSnapshots>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketSnapshotsGet(symbols, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get symbol alpaca asset
         * @summary symbol asset
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketSymbolAssetGet(symbol?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketSymbolAssetGet(symbol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary brand filenames, logos and icons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketSymbolBrandsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrSymbolBrandImages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketSymbolBrandsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary brand filenames, logos and icons
         * @param {string} [name] name
         * @param {string} [symbol] symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketSymbolBrandsKnownGet(name?: string, symbol?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketSymbolBrandsKnownGet(name, symbol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns general information for the requested securities. https://polygon.io/docs/stocks/get_v3_reference_tickers__ticker 
         * @summary polygon symbol details V3
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketSymbolDetailGet(symbol?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrSymbolDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketSymbolDetailGet(symbol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * multiple tickers provides the general info for the given ticker symbols 
         * @summary multiple symbol summaries
         * @param {string} [symbols] comma separated stock symbols
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketSymbolGet(symbols?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrSymbolSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketSymbolGet(symbols, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update db symbol summary. typically a cron job. primary a button to easily update local env.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketSymbolUpdateSummaryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketSymbolUpdateSummaryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns the latest trade data 
         * @summary Returns the latest trade data
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketTradesLatestGet(symbol?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrMarketTrade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketTradesLatestGet(symbol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * queue email notification
         * @summary queue email notification
         * @param {TrNotifyEmail} [trNotifyEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyEmailPost(trNotifyEmail?: TrNotifyEmail, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyEmailPost(trNotifyEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
         * @summary updates self user entity. ie. username, address
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMyUserProfile(trUserProfile?: TrUserProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMyUserProfile(trUserProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * redeem a gift
         * @param {string} giftId gift id
         * @param {TrGiftRedeemUpdate} [trGiftRedeemUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemGift(giftId: string, trGiftRedeemUpdate?: TrGiftRedeemUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrGiftStatusPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemGift(giftId, trGiftRedeemUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary send email verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailVerificationCode(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailVerificationCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * add signed user to waitlist using firebase email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemWaitlistPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemWaitlistPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * trading account info
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingAccountGet(minorId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrTradingAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingAccountGet(minorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * cancel pending order
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {string} [orderId] order id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingOrdersDelete(minorId?: string, orderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingOrdersDelete(minorId, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get stock orders
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {'open' | 'closed' | 'all'} [status] Status of the orders to list.
         * @param {number} [limit] The maximum number of orders in response.
         * @param {string} [after] The response will include only ones submitted after this timestamp (exclusive.)
         * @param {string} [until] The response will include only ones submitted until this timestamp (exclusive.)
         * @param {'asc' | 'desc'} [direction] The chronological order of response based on the submission time. asc or desc. Defaults to desc.
         * @param {boolean} [nested] If true, the result will roll up multi-leg orders under the legs field of primary order.
         * @param {string} [symbols] A comma-separated list of symbols to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingOrdersGet(minorId?: string, status?: 'open' | 'closed' | 'all', limit?: number, after?: string, until?: string, direction?: 'asc' | 'desc', nested?: boolean, symbols?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrTradingOrder>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingOrdersGet(minorId, status, limit, after, until, direction, nested, symbols, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * order stock for myself
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {TrTradingCreateOrderRequest} [trTradingCreateOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingOrdersPost(minorId?: string, trTradingCreateOrderRequest?: TrTradingCreateOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingOrdersPost(minorId, trTradingCreateOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * portfolio history
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {string} [period] The duration of the data in number + unit (ex 7D, 1M, 2W). Default 1M 
         * @param {string} [timeframe] The resolution of time window. 1Min, 5Min, 15Min, 1H, or 1D.  If omitted, 1Min for less than 7 days period, 15Min for less than 30 days, or otherwise 1D. 
         * @param {string} [dateEnd] The date the data is returned up to, in “YYYY-MM-DD” format. Defaults to the current market date (rolls over at the market open if extended_hours is false, otherwise at 7am ET) 
         * @param {boolean} [extendedHours] If true, include extended hours in the result. This is effective only for timeframe less than 1D. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingPortfolioHistoryGet(minorId?: string, period?: string, timeframe?: string, dateEnd?: string, extendedHours?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrPortfolioHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingPortfolioHistoryGet(minorId, period, timeframe, dateEnd, extendedHours, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * stock positions
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingPositionsGet(minorId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrTradingPosition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingPositionsGet(minorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update account contact info. this can be called before alpaca account creation
         * @param {TrAccountInfo} [trAccountInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountContact(trAccountInfo?: TrAccountInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountContact(trAccountInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * save gift image to Storage and store in db
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadGiftImage(file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadGiftImage(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * for displaying contributor avatar list
         * @summary gets contributors UserProfilePublic
         * @param {string} [receiverId] receiver_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersConfigContributorsGet(receiverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrUserProfilePublic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersConfigContributorsGet(receiverId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * either self or minor config. data not part of profile.
         * @summary gets config data
         * @param {string} [id] custodial user firebase uid(s). comma delimited
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersConfigGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrUserConfig>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersConfigGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * either self or minor config. params not related to the user profile
         * @summary updates user config entity,  ie. wish
         * @param {TrUserConfig} [trUserConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersConfigPatch(trUserConfig?: TrUserConfig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersConfigPatch(trUserConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * save profile image to Storage and store in db
         * @param {UsersContactsAvatarPostRequest} usersContactsAvatarPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersContactsAvatarPost(usersContactsAvatarPostRequest: UsersContactsAvatarPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersContactsAvatarPost(usersContactsAvatarPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * remove a social contact.
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersContactsDelete(trUserProfile?: TrUserProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersContactsDelete(trUserProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of friends. Only returns public information such as name, username, profiles pics. 
         * @summary Returns the users social circle. ie. list of friends and contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersContactsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrUserProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersContactsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersContactsPatch(trUserProfile?: TrUserProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersContactsPatch(trUserProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * save profile image to Storage and store in db
         * @param {UsersContactsAvatarPostRequest} usersContactsAvatarPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCustodialAvatarPost(usersContactsAvatarPostRequest: UsersContactsAvatarPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCustodialAvatarPost(usersContactsAvatarPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get list of custodial user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCustodialGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrUserProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCustodialGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
         * @summary updates self user entity. ie. username, address, wish
         * @param {string} [id] custodial user firebase uid
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCustodialPatch(id?: string, trUserProfile?: TrUserProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCustodialPatch(id, trUserProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of users. Only returns public information such as name, username, profiles pics etc. Usage: search a user for sending stock, add as a friend, etc 
         * @summary Returns a list of users.
         * @param {string} [username] username
         * @param {string} [keyword] user search keyword (username, name, email, phone number)
         * @param {string} [email] email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(username?: string, keyword?: string, email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrUserProfilePublic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(username, keyword, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns user profile
         * @summary Returns user by id
         * @param {string} id user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrUserProfilePublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * save profile image to Storage and store in db
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMeAvatarPost(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMeAvatarPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets self data
         * @summary gets self data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrUserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary new user, for tracking purposes. before alpaca signup finished.
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMePost(trUserProfile?: TrUserProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMePost(trUserProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * given username, returns public profile.
         * @summary Checks if username exists
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPublicGet(username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrUserProfilePublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPublicGet(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets list of users in signup process. returns array of truserprofiles
         * @summary gets list of users in signup process
         * @param {string} [since] since epoch time
         * @param {string} [until] until epoch time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersTrackInsignupsGet(since?: string, until?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrUserProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersTrackInsignupsGet(since, until, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * checks if username exists. 
         * @summary Checks if username exists
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUsernameGet(username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrUserProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUsernameGet(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary verify email
         * @param {VerifyMySecondaryEmailRequest} [verifyMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyMySecondaryEmail(verifyMySecondaryEmailRequest?: VerifyMySecondaryEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyMySecondaryEmail(verifyMySecondaryEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary verify email
         * @param {VerifyMySecondaryEmailRequest} [verifyMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyMySubstituteEmail(verifyMySecondaryEmailRequest?: VerifyMySecondaryEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyMySubstituteEmail(verifyMySecondaryEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Get ACH relationship
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAchRelationshipsGet(options?: any): AxiosPromise<Array<TrACHRelationshipData>> {
            return localVarFp.accountsAchRelationshipsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an ACH relationship
         * @param {string} relationshipId relationship_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAchRelationshipsRelationshipIdDelete(relationshipId: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountsAchRelationshipsRelationshipIdDelete(relationshipId, options).then((request) => request(axios, basePath));
        },
        /**
         * all alpaca activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsActivityGet(options?: any): AxiosPromise<Array<TrAccountActivityItem>> {
            return localVarFp.accountsActivityGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get custodial accounts (Alpaca)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCustodialGet(options?: any): AxiosPromise<Array<TrAccountInfo>> {
            return localVarFp.accountsCustodialGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Update custodial account (Alpaca)
         * @param {string} [id] custodial account firebase uid
         * @param {TrAccountInfo} [trAccountInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCustodialPatch(id?: string, trAccountInfo?: TrAccountInfo, options?: any): AxiosPromise<TrAccountInfo> {
            return localVarFp.accountsCustodialPatch(id, trAccountInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a custodial stock account (Alpaca)
         * @param {TrCustodialAccountCreationRequest} [trCustodialAccountCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCustodialPost(trCustodialAccountCreationRequest?: TrCustodialAccountCreationRequest, options?: any): AxiosPromise<TrAccountInfo> {
            return localVarFp.accountsCustodialPost(trCustodialAccountCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * returns a url to download?
         * @param {string} [docId] document id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsDocumentsDownloadGet(docId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.accountsDocumentsDownloadGet(docId, options).then((request) => request(axios, basePath));
        },
        /**
         * returns a list of documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsDocumentsListGet(options?: any): AxiosPromise<Array<TrAccountDocumentItem>> {
            return localVarFp.accountsDocumentsListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get stock account information (Alpaca)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGet(options?: any): AxiosPromise<TrAccountInfo> {
            return localVarFp.accountsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * update stock account (Alpaca)
         * @param {TrAccountInfo} [trAccountInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPatch(trAccountInfo?: TrAccountInfo, options?: any): AxiosPromise<TrAccountInfo> {
            return localVarFp.accountsPatch(trAccountInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Get plaid link token
         * @param {TrPlaidPublicToken} [trPlaidPublicToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPlaidAlpacaProcessorTokenPost(trPlaidPublicToken?: TrPlaidPublicToken, options?: any): AxiosPromise<void> {
            return localVarFp.accountsPlaidAlpacaProcessorTokenPost(trPlaidPublicToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get plaid link token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPlaidLinkTokenPost(options?: any): AxiosPromise<TrPlaidLinkTokenCreateResponse> {
            return localVarFp.accountsPlaidLinkTokenPost(options).then((request) => request(axios, basePath));
        },
        /**
         * Get plaid link token
         * @param {TrPlaidPublicToken} [trPlaidPublicToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPlaidStripeProcessorTokenPost(trPlaidPublicToken?: TrPlaidPublicToken, options?: any): AxiosPromise<void> {
            return localVarFp.accountsPlaidStripeProcessorTokenPost(trPlaidPublicToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Create stock account (Alpaca)
         * @param {TrAccountCreationRequest} [trAccountCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPost(trAccountCreationRequest?: TrAccountCreationRequest, options?: any): AxiosPromise<TrAccountInfo> {
            return localVarFp.accountsPost(trAccountCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * all transfers by account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsTransfersGet(options?: any): AxiosPromise<Array<TrTransferResource>> {
            return localVarFp.accountsTransfersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * transfer fund
         * @param {TrACHTransferData} [trACHTransferData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsTransfersPost(trACHTransferData?: TrACHTransferData, options?: any): AxiosPromise<TrTransferResource> {
            return localVarFp.accountsTransfersPost(trACHTransferData, options).then((request) => request(axios, basePath));
        },
        /**
         * add email address and send a verification emil. if the email address is alreay added without verification and sent out verification email is expired, send a verification email again
         * @summary add email address and send a verification email
         * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMySecondaryEmail(addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addMySecondaryEmail(addMySecondaryEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * add email address and send a verification emil. if the email address is alreay added without verification and sent out verification email is expired, send a verification email again
         * @summary add email address and send a verification email
         * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMySubstituteEmail(addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addMySubstituteEmail(addMySecondaryEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary cancel a gift. can be cancelled by sender or receiver
         * @param {string} giftId gift id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelGift(giftId: string, options?: any): AxiosPromise<TrGiftStatus> {
            return localVarFp.cancelGift(giftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary send Event to user. notifications and/or background data to user
         * @param {Array<TrEvent>} [trEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicatorEventPost(trEvent?: Array<TrEvent>, options?: any): AxiosPromise<void> {
            return localVarFp.communicatorEventPost(trEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new ACHRelationship for an account
         * @param {TrCreateACHRelationshipRequest} trCreateACHRelationshipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createACHRelationship(trCreateACHRelationshipRequest: TrCreateACHRelationshipRequest, options?: any): AxiosPromise<TrACHRelationshipData> {
            return localVarFp.createACHRelationship(trCreateACHRelationshipRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create Gift
         * @param {TrGiftRequest} [trGiftRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGift(trGiftRequest?: TrGiftRequest, options?: any): AxiosPromise<TrGiftStatus> {
            return localVarFp.createGift(trGiftRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * create Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(options?: any): AxiosPromise<TrSubscription> {
            return localVarFp.createSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * remove secondary email
         * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMySecondaryEmail(addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMySecondaryEmail(addMySecondaryEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * dividend alpaca activity
         * @param {string} [symbol] symbol, if not specified, send all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDividendActivity(symbol?: string, options?: any): AxiosPromise<Array<TrAccountActivityItem>> {
            return localVarFp.getDividendActivity(symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * get gift summary by receiver Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlags(options?: any): AxiosPromise<TrFeatureFlags> {
            return localVarFp.getFeatureFlags(options).then((request) => request(axios, basePath));
        },
        /**
         * get a gift
         * @param {string} giftId gift id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGift(giftId: string, options?: any): AxiosPromise<TrGiftStatus> {
            return localVarFp.getGift(giftId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of Gift, including custodial account gifts
         * @param {TrRole} [role] sender or receiver
         * @param {number} [limit] limit the number of results, default is set to 50
         * @param {string} [otherUserId] for \&#39;pair\&#39;, either otherUserId or otherEmail is required. returns all gifts between 2 people including as guardian
         * @param {string} [otherEmail] for \&#39;pair\&#39;, either otherUserId or otherEmail is required. returns all gifts between 2 people including as guardian
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftList(role?: TrRole, limit?: number, otherUserId?: string, otherEmail?: string, options?: any): AxiosPromise<Array<TrGiftStatus>> {
            return localVarFp.getGiftList(role, limit, otherUserId, otherEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the bundle options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftPassProducts(options?: any): AxiosPromise<Array<TrProductGiftPass>> {
            return localVarFp.getGiftPassProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * get a gift
         * @param {string} giftId gift id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftPublic(giftId: string, options?: any): AxiosPromise<TrGiftStatusPublic> {
            return localVarFp.getGiftPublic(giftId, options).then((request) => request(axios, basePath));
        },
        /**
         * get gift summary by receiver Id
         * @param {string} receiverId receiver Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftReceiverSummary(receiverId: string, options?: any): AxiosPromise<TrGiftReceiverSummary> {
            return localVarFp.getGiftReceiverSummary(receiverId, options).then((request) => request(axios, basePath));
        },
        /**
         * get invoices (mostly subscriptions)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices(options?: any): AxiosPromise<TrInvoice> {
            return localVarFp.getInvoices(options).then((request) => request(axios, basePath));
        },
        /**
         * gets self data
         * @summary get list of my emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyEmails(options?: any): AxiosPromise<Array<TrEmail>> {
            return localVarFp.getMyEmails(options).then((request) => request(axios, basePath));
        },
        /**
         * get Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscription(options?: any): AxiosPromise<TrSubscription> {
            return localVarFp.getSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * current user\'s policy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPolicy(options?: any): AxiosPromise<TrUserPolicy> {
            return localVarFp.getUserPolicy(options).then((request) => request(axios, basePath));
        },
        /**
         * returns the latest trade data 
         * @summary Returns the latest trade data
         * @param {string} symbols symbols, comma delimited
         * @param {AnnouncementCAType} [type] corporate action type
         * @param {string} [since] since YYYY-MM-DD format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketAnnouncementsGet(symbols: string, type?: AnnouncementCAType, since?: string, options?: any): AxiosPromise<Array<Announcement>> {
            return localVarFp.marketAnnouncementsGet(symbols, type, since, options).then((request) => request(axios, basePath));
        },
        /**
         * returns aggregate historical data for the requested securities. 
         * @summary aggregate historical data
         * @param {string} [symbol] stock symbol
         * @param {string} [start] Filter data equal to or after this time in RFC-3339 format. Fractions of a second are not accepted 
         * @param {string} [end] Filter data equal to or after this time in RFC-3339 format. Fractions of a second are not accepted 
         * @param {string} [timeframe] Timeframe for the aggregation. Available values are flexible for Min, Hour, Day time window sizes with a maximum constraint on the values: 60Min, 24Hour, 31Day 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketBarsGet(symbol?: string, start?: string, end?: string, timeframe?: string, options?: any): AxiosPromise<TrMarketBars> {
            return localVarFp.marketBarsGet(symbol, start, end, timeframe, options).then((request) => request(axios, basePath));
        },
        /**
         * The calendar API serves the full list of market days from 1970 to 2029. It can also be queried by specifying a start and/or end time to narrow down the results. In addition to the dates, the response also contains the specific open and close times for the market days, taking into account early closures. 
         * @summary current market timestamp, market open or close
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketCalendarGet(options?: any): AxiosPromise<Array<TrMarketDay>> {
            return localVarFp.marketCalendarGet(options).then((request) => request(axios, basePath));
        },
        /**
         * The Clock API serves the current market timestamp, whether or not the market is currently open, as well as the times of the next market open and close. 
         * @summary current market timestamp, market open or close
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketClockGet(options?: any): AxiosPromise<TrClock> {
            return localVarFp.marketClockGet(options).then((request) => request(axios, basePath));
        },
        /**
         * returns a list of popular stocks. latest quote and details 
         * @summary returns the list of stock names and tickers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketListGet(options?: any): AxiosPromise<Array<TrSymbolSummary>> {
            return localVarFp.marketListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get dividends
         * @summary Returns the latest trade data
         * @param {string} symbol symbol
         * @param {string} [since] since YYYY-MM-DD format
         * @param {string} [until] until YYYY-MM-DD format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketMetricsDividendsGet(symbol: string, since?: string, until?: string, options?: any): AxiosPromise<Array<Announcement>> {
            return localVarFp.marketMetricsDividendsGet(symbol, since, until, options).then((request) => request(axios, basePath));
        },
        /**
         * from polygon. income statement, cash flow, balance sheet
         * @summary Returns the latest financials data from polygon
         * @param {string} symbol symbol
         * @param {string} [since] since YYYY-MM-DD format
         * @param {string} [until] until YYYY-MM-DD format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketMetricsFinancialsLatestGet(symbol: string, since?: string, until?: string, options?: any): AxiosPromise<TrSymbolFinancials> {
            return localVarFp.marketMetricsFinancialsLatestGet(symbol, since, until, options).then((request) => request(axios, basePath));
        },
        /**
         * Get metric by symbol
         * @summary Returns stats like yield, cagr, payout ratio, fcf payout
         * @param {string} symbol symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketMetricsGet(symbol: string, options?: any): AxiosPromise<TrSymbolCalc> {
            return localVarFp.marketMetricsGet(symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * returns a object of popular stocks.
         * @summary returns a list of popular stocks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPopularGet(options?: any): AxiosPromise<TrMarketPopular> {
            return localVarFp.marketPopularGet(options).then((request) => request(axios, basePath));
        },
        /**
         * returns market symbol info. Bid ask price size 
         * @summary Returns a stock quote.
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketQuotesLatestGet(symbol?: string, options?: any): AxiosPromise<TrMarketQuote> {
            return localVarFp.marketQuotesLatestGet(symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * multiple tickers provides the latest trade, latest quote, minute bar daily bar and previous daily bar data for the given ticker symbols 
         * @summary multiple snapshots
         * @param {string} [symbols] comma separated stock symbols
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSnapshotsGet(symbols?: string, options?: any): AxiosPromise<TrMarketSnapshots> {
            return localVarFp.marketSnapshotsGet(symbols, options).then((request) => request(axios, basePath));
        },
        /**
         * get symbol alpaca asset
         * @summary symbol asset
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolAssetGet(symbol?: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.marketSymbolAssetGet(symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary brand filenames, logos and icons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolBrandsGet(options?: any): AxiosPromise<TrSymbolBrandImages> {
            return localVarFp.marketSymbolBrandsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary brand filenames, logos and icons
         * @param {string} [name] name
         * @param {string} [symbol] symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolBrandsKnownGet(name?: string, symbol?: string, options?: any): AxiosPromise<string> {
            return localVarFp.marketSymbolBrandsKnownGet(name, symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * returns general information for the requested securities. https://polygon.io/docs/stocks/get_v3_reference_tickers__ticker 
         * @summary polygon symbol details V3
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolDetailGet(symbol?: string, options?: any): AxiosPromise<TrSymbolDetail> {
            return localVarFp.marketSymbolDetailGet(symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * multiple tickers provides the general info for the given ticker symbols 
         * @summary multiple symbol summaries
         * @param {string} [symbols] comma separated stock symbols
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolGet(symbols?: string, options?: any): AxiosPromise<Array<TrSymbolSummary>> {
            return localVarFp.marketSymbolGet(symbols, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update db symbol summary. typically a cron job. primary a button to easily update local env.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketSymbolUpdateSummaryGet(options?: any): AxiosPromise<void> {
            return localVarFp.marketSymbolUpdateSummaryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * returns the latest trade data 
         * @summary Returns the latest trade data
         * @param {string} [symbol] stock symbol
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketTradesLatestGet(symbol?: string, options?: any): AxiosPromise<TrMarketTrade> {
            return localVarFp.marketTradesLatestGet(symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * queue email notification
         * @summary queue email notification
         * @param {TrNotifyEmail} [trNotifyEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyEmailPost(trNotifyEmail?: TrNotifyEmail, options?: any): AxiosPromise<void> {
            return localVarFp.notifyEmailPost(trNotifyEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
         * @summary updates self user entity. ie. username, address
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMyUserProfile(trUserProfile?: TrUserProfile, options?: any): AxiosPromise<void> {
            return localVarFp.patchMyUserProfile(trUserProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * redeem a gift
         * @param {string} giftId gift id
         * @param {TrGiftRedeemUpdate} [trGiftRedeemUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemGift(giftId: string, trGiftRedeemUpdate?: TrGiftRedeemUpdate, options?: any): AxiosPromise<TrGiftStatusPublic> {
            return localVarFp.redeemGift(giftId, trGiftRedeemUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: any): AxiosPromise<void> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary send email verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailVerificationCode(options?: any): AxiosPromise<void> {
            return localVarFp.sendEmailVerificationCode(options).then((request) => request(axios, basePath));
        },
        /**
         * add signed user to waitlist using firebase email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemWaitlistPost(options?: any): AxiosPromise<void> {
            return localVarFp.systemWaitlistPost(options).then((request) => request(axios, basePath));
        },
        /**
         * trading account info
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingAccountGet(minorId?: string, options?: any): AxiosPromise<TrTradingAccount> {
            return localVarFp.tradingAccountGet(minorId, options).then((request) => request(axios, basePath));
        },
        /**
         * cancel pending order
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {string} [orderId] order id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingOrdersDelete(minorId?: string, orderId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.tradingOrdersDelete(minorId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * get stock orders
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {'open' | 'closed' | 'all'} [status] Status of the orders to list.
         * @param {number} [limit] The maximum number of orders in response.
         * @param {string} [after] The response will include only ones submitted after this timestamp (exclusive.)
         * @param {string} [until] The response will include only ones submitted until this timestamp (exclusive.)
         * @param {'asc' | 'desc'} [direction] The chronological order of response based on the submission time. asc or desc. Defaults to desc.
         * @param {boolean} [nested] If true, the result will roll up multi-leg orders under the legs field of primary order.
         * @param {string} [symbols] A comma-separated list of symbols to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingOrdersGet(minorId?: string, status?: 'open' | 'closed' | 'all', limit?: number, after?: string, until?: string, direction?: 'asc' | 'desc', nested?: boolean, symbols?: string, options?: any): AxiosPromise<Array<TrTradingOrder>> {
            return localVarFp.tradingOrdersGet(minorId, status, limit, after, until, direction, nested, symbols, options).then((request) => request(axios, basePath));
        },
        /**
         * order stock for myself
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {TrTradingCreateOrderRequest} [trTradingCreateOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingOrdersPost(minorId?: string, trTradingCreateOrderRequest?: TrTradingCreateOrderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.tradingOrdersPost(minorId, trTradingCreateOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * portfolio history
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {string} [period] The duration of the data in number + unit (ex 7D, 1M, 2W). Default 1M 
         * @param {string} [timeframe] The resolution of time window. 1Min, 5Min, 15Min, 1H, or 1D.  If omitted, 1Min for less than 7 days period, 15Min for less than 30 days, or otherwise 1D. 
         * @param {string} [dateEnd] The date the data is returned up to, in “YYYY-MM-DD” format. Defaults to the current market date (rolls over at the market open if extended_hours is false, otherwise at 7am ET) 
         * @param {boolean} [extendedHours] If true, include extended hours in the result. This is effective only for timeframe less than 1D. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingPortfolioHistoryGet(minorId?: string, period?: string, timeframe?: string, dateEnd?: string, extendedHours?: boolean, options?: any): AxiosPromise<TrPortfolioHistory> {
            return localVarFp.tradingPortfolioHistoryGet(minorId, period, timeframe, dateEnd, extendedHours, options).then((request) => request(axios, basePath));
        },
        /**
         * stock positions
         * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingPositionsGet(minorId?: string, options?: any): AxiosPromise<Array<TrTradingPosition>> {
            return localVarFp.tradingPositionsGet(minorId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update account contact info. this can be called before alpaca account creation
         * @param {TrAccountInfo} [trAccountInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountContact(trAccountInfo?: TrAccountInfo, options?: any): AxiosPromise<TrAccountInfo> {
            return localVarFp.updateAccountContact(trAccountInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * save gift image to Storage and store in db
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGiftImage(file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.uploadGiftImage(file, options).then((request) => request(axios, basePath));
        },
        /**
         * for displaying contributor avatar list
         * @summary gets contributors UserProfilePublic
         * @param {string} [receiverId] receiver_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersConfigContributorsGet(receiverId?: string, options?: any): AxiosPromise<Array<TrUserProfilePublic>> {
            return localVarFp.usersConfigContributorsGet(receiverId, options).then((request) => request(axios, basePath));
        },
        /**
         * either self or minor config. data not part of profile.
         * @summary gets config data
         * @param {string} [id] custodial user firebase uid(s). comma delimited
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersConfigGet(id?: string, options?: any): AxiosPromise<Array<TrUserConfig>> {
            return localVarFp.usersConfigGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * either self or minor config. params not related to the user profile
         * @summary updates user config entity,  ie. wish
         * @param {TrUserConfig} [trUserConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersConfigPatch(trUserConfig?: TrUserConfig, options?: any): AxiosPromise<void> {
            return localVarFp.usersConfigPatch(trUserConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * save profile image to Storage and store in db
         * @param {UsersContactsAvatarPostRequest} usersContactsAvatarPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersContactsAvatarPost(usersContactsAvatarPostRequest: UsersContactsAvatarPostRequest, options?: any): AxiosPromise<string> {
            return localVarFp.usersContactsAvatarPost(usersContactsAvatarPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * remove a social contact.
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersContactsDelete(trUserProfile?: TrUserProfile, options?: any): AxiosPromise<void> {
            return localVarFp.usersContactsDelete(trUserProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of friends. Only returns public information such as name, username, profiles pics. 
         * @summary Returns the users social circle. ie. list of friends and contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersContactsGet(options?: any): AxiosPromise<Array<TrUserProfile>> {
            return localVarFp.usersContactsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersContactsPatch(trUserProfile?: TrUserProfile, options?: any): AxiosPromise<void> {
            return localVarFp.usersContactsPatch(trUserProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * save profile image to Storage and store in db
         * @param {UsersContactsAvatarPostRequest} usersContactsAvatarPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCustodialAvatarPost(usersContactsAvatarPostRequest: UsersContactsAvatarPostRequest, options?: any): AxiosPromise<string> {
            return localVarFp.usersCustodialAvatarPost(usersContactsAvatarPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get list of custodial user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCustodialGet(options?: any): AxiosPromise<Array<TrUserProfile>> {
            return localVarFp.usersCustodialGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
         * @summary updates self user entity. ie. username, address, wish
         * @param {string} [id] custodial user firebase uid
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCustodialPatch(id?: string, trUserProfile?: TrUserProfile, options?: any): AxiosPromise<void> {
            return localVarFp.usersCustodialPatch(id, trUserProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of users. Only returns public information such as name, username, profiles pics etc. Usage: search a user for sending stock, add as a friend, etc 
         * @summary Returns a list of users.
         * @param {string} [username] username
         * @param {string} [keyword] user search keyword (username, name, email, phone number)
         * @param {string} [email] email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(username?: string, keyword?: string, email?: string, options?: any): AxiosPromise<Array<TrUserProfilePublic>> {
            return localVarFp.usersGet(username, keyword, email, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns user profile
         * @summary Returns user by id
         * @param {string} id user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdIdGet(id: string, options?: any): AxiosPromise<TrUserProfilePublic> {
            return localVarFp.usersIdIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * save profile image to Storage and store in db
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeAvatarPost(body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersMeAvatarPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * gets self data
         * @summary gets self data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeGet(options?: any): AxiosPromise<TrUserProfile> {
            return localVarFp.usersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary new user, for tracking purposes. before alpaca signup finished.
         * @param {TrUserProfile} [trUserProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMePost(trUserProfile?: TrUserProfile, options?: any): AxiosPromise<void> {
            return localVarFp.usersMePost(trUserProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * given username, returns public profile.
         * @summary Checks if username exists
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPublicGet(username?: string, options?: any): AxiosPromise<TrUserProfilePublic> {
            return localVarFp.usersPublicGet(username, options).then((request) => request(axios, basePath));
        },
        /**
         * gets list of users in signup process. returns array of truserprofiles
         * @summary gets list of users in signup process
         * @param {string} [since] since epoch time
         * @param {string} [until] until epoch time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersTrackInsignupsGet(since?: string, until?: string, options?: any): AxiosPromise<Array<TrUserProfile>> {
            return localVarFp.usersTrackInsignupsGet(since, until, options).then((request) => request(axios, basePath));
        },
        /**
         * checks if username exists. 
         * @summary Checks if username exists
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernameGet(username?: string, options?: any): AxiosPromise<Array<TrUserProfile>> {
            return localVarFp.usersUsernameGet(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary verify email
         * @param {VerifyMySecondaryEmailRequest} [verifyMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMySecondaryEmail(verifyMySecondaryEmailRequest?: VerifyMySecondaryEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.verifyMySecondaryEmail(verifyMySecondaryEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary verify email
         * @param {VerifyMySecondaryEmailRequest} [verifyMySecondaryEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMySubstituteEmail(verifyMySecondaryEmailRequest?: VerifyMySecondaryEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.verifyMySubstituteEmail(verifyMySecondaryEmailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Get ACH relationship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsAchRelationshipsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsAchRelationshipsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an ACH relationship
     * @param {string} relationshipId relationship_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsAchRelationshipsRelationshipIdDelete(relationshipId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsAchRelationshipsRelationshipIdDelete(relationshipId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * all alpaca activity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsActivityGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsActivityGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get custodial accounts (Alpaca)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsCustodialGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsCustodialGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update custodial account (Alpaca)
     * @param {string} [id] custodial account firebase uid
     * @param {TrAccountInfo} [trAccountInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsCustodialPatch(id?: string, trAccountInfo?: TrAccountInfo, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsCustodialPatch(id, trAccountInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a custodial stock account (Alpaca)
     * @param {TrCustodialAccountCreationRequest} [trCustodialAccountCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsCustodialPost(trCustodialAccountCreationRequest?: TrCustodialAccountCreationRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsCustodialPost(trCustodialAccountCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns a url to download?
     * @param {string} [docId] document id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsDocumentsDownloadGet(docId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsDocumentsDownloadGet(docId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns a list of documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsDocumentsListGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsDocumentsListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get stock account information (Alpaca)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update stock account (Alpaca)
     * @param {TrAccountInfo} [trAccountInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsPatch(trAccountInfo?: TrAccountInfo, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsPatch(trAccountInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get plaid link token
     * @param {TrPlaidPublicToken} [trPlaidPublicToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsPlaidAlpacaProcessorTokenPost(trPlaidPublicToken?: TrPlaidPublicToken, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsPlaidAlpacaProcessorTokenPost(trPlaidPublicToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get plaid link token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsPlaidLinkTokenPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsPlaidLinkTokenPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get plaid link token
     * @param {TrPlaidPublicToken} [trPlaidPublicToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsPlaidStripeProcessorTokenPost(trPlaidPublicToken?: TrPlaidPublicToken, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsPlaidStripeProcessorTokenPost(trPlaidPublicToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create stock account (Alpaca)
     * @param {TrAccountCreationRequest} [trAccountCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsPost(trAccountCreationRequest?: TrAccountCreationRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsPost(trAccountCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * all transfers by account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsTransfersGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsTransfersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * transfer fund
     * @param {TrACHTransferData} [trACHTransferData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsTransfersPost(trACHTransferData?: TrACHTransferData, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsTransfersPost(trACHTransferData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add email address and send a verification emil. if the email address is alreay added without verification and sent out verification email is expired, send a verification email again
     * @summary add email address and send a verification email
     * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addMySecondaryEmail(addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addMySecondaryEmail(addMySecondaryEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add email address and send a verification emil. if the email address is alreay added without verification and sent out verification email is expired, send a verification email again
     * @summary add email address and send a verification email
     * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addMySubstituteEmail(addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addMySubstituteEmail(addMySecondaryEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary cancel a gift. can be cancelled by sender or receiver
     * @param {string} giftId gift id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cancelGift(giftId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cancelGift(giftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary send Event to user. notifications and/or background data to user
     * @param {Array<TrEvent>} [trEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public communicatorEventPost(trEvent?: Array<TrEvent>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).communicatorEventPost(trEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new ACHRelationship for an account
     * @param {TrCreateACHRelationshipRequest} trCreateACHRelationshipRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createACHRelationship(trCreateACHRelationshipRequest: TrCreateACHRelationshipRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createACHRelationship(trCreateACHRelationshipRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create Gift
     * @param {TrGiftRequest} [trGiftRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createGift(trGiftRequest?: TrGiftRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createGift(trGiftRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSubscription(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * remove secondary email
     * @param {AddMySecondaryEmailRequest} [addMySecondaryEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteMySecondaryEmail(addMySecondaryEmailRequest?: AddMySecondaryEmailRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteMySecondaryEmail(addMySecondaryEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * dividend alpaca activity
     * @param {string} [symbol] symbol, if not specified, send all
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDividendActivity(symbol?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDividendActivity(symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get gift summary by receiver Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFeatureFlags(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFeatureFlags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a gift
     * @param {string} giftId gift id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGift(giftId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGift(giftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of Gift, including custodial account gifts
     * @param {TrRole} [role] sender or receiver
     * @param {number} [limit] limit the number of results, default is set to 50
     * @param {string} [otherUserId] for \&#39;pair\&#39;, either otherUserId or otherEmail is required. returns all gifts between 2 people including as guardian
     * @param {string} [otherEmail] for \&#39;pair\&#39;, either otherUserId or otherEmail is required. returns all gifts between 2 people including as guardian
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGiftList(role?: TrRole, limit?: number, otherUserId?: string, otherEmail?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGiftList(role, limit, otherUserId, otherEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the bundle options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGiftPassProducts(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGiftPassProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a gift
     * @param {string} giftId gift id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGiftPublic(giftId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGiftPublic(giftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get gift summary by receiver Id
     * @param {string} receiverId receiver Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGiftReceiverSummary(receiverId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGiftReceiverSummary(receiverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get invoices (mostly subscriptions)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInvoices(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getInvoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets self data
     * @summary get list of my emails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyEmails(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMyEmails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSubscription(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * current user\'s policy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserPolicy(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserPolicy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns the latest trade data 
     * @summary Returns the latest trade data
     * @param {string} symbols symbols, comma delimited
     * @param {AnnouncementCAType} [type] corporate action type
     * @param {string} [since] since YYYY-MM-DD format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketAnnouncementsGet(symbols: string, type?: AnnouncementCAType, since?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketAnnouncementsGet(symbols, type, since, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns aggregate historical data for the requested securities. 
     * @summary aggregate historical data
     * @param {string} [symbol] stock symbol
     * @param {string} [start] Filter data equal to or after this time in RFC-3339 format. Fractions of a second are not accepted 
     * @param {string} [end] Filter data equal to or after this time in RFC-3339 format. Fractions of a second are not accepted 
     * @param {string} [timeframe] Timeframe for the aggregation. Available values are flexible for Min, Hour, Day time window sizes with a maximum constraint on the values: 60Min, 24Hour, 31Day 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketBarsGet(symbol?: string, start?: string, end?: string, timeframe?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketBarsGet(symbol, start, end, timeframe, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The calendar API serves the full list of market days from 1970 to 2029. It can also be queried by specifying a start and/or end time to narrow down the results. In addition to the dates, the response also contains the specific open and close times for the market days, taking into account early closures. 
     * @summary current market timestamp, market open or close
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketCalendarGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketCalendarGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The Clock API serves the current market timestamp, whether or not the market is currently open, as well as the times of the next market open and close. 
     * @summary current market timestamp, market open or close
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketClockGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketClockGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns a list of popular stocks. latest quote and details 
     * @summary returns the list of stock names and tickers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketListGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get dividends
     * @summary Returns the latest trade data
     * @param {string} symbol symbol
     * @param {string} [since] since YYYY-MM-DD format
     * @param {string} [until] until YYYY-MM-DD format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketMetricsDividendsGet(symbol: string, since?: string, until?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketMetricsDividendsGet(symbol, since, until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * from polygon. income statement, cash flow, balance sheet
     * @summary Returns the latest financials data from polygon
     * @param {string} symbol symbol
     * @param {string} [since] since YYYY-MM-DD format
     * @param {string} [until] until YYYY-MM-DD format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketMetricsFinancialsLatestGet(symbol: string, since?: string, until?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketMetricsFinancialsLatestGet(symbol, since, until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get metric by symbol
     * @summary Returns stats like yield, cagr, payout ratio, fcf payout
     * @param {string} symbol symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketMetricsGet(symbol: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketMetricsGet(symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns a object of popular stocks.
     * @summary returns a list of popular stocks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketPopularGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketPopularGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns market symbol info. Bid ask price size 
     * @summary Returns a stock quote.
     * @param {string} [symbol] stock symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketQuotesLatestGet(symbol?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketQuotesLatestGet(symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * multiple tickers provides the latest trade, latest quote, minute bar daily bar and previous daily bar data for the given ticker symbols 
     * @summary multiple snapshots
     * @param {string} [symbols] comma separated stock symbols
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketSnapshotsGet(symbols?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketSnapshotsGet(symbols, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get symbol alpaca asset
     * @summary symbol asset
     * @param {string} [symbol] stock symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketSymbolAssetGet(symbol?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketSymbolAssetGet(symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary brand filenames, logos and icons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketSymbolBrandsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketSymbolBrandsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary brand filenames, logos and icons
     * @param {string} [name] name
     * @param {string} [symbol] symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketSymbolBrandsKnownGet(name?: string, symbol?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketSymbolBrandsKnownGet(name, symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns general information for the requested securities. https://polygon.io/docs/stocks/get_v3_reference_tickers__ticker 
     * @summary polygon symbol details V3
     * @param {string} [symbol] stock symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketSymbolDetailGet(symbol?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketSymbolDetailGet(symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * multiple tickers provides the general info for the given ticker symbols 
     * @summary multiple symbol summaries
     * @param {string} [symbols] comma separated stock symbols
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketSymbolGet(symbols?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketSymbolGet(symbols, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update db symbol summary. typically a cron job. primary a button to easily update local env.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketSymbolUpdateSummaryGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketSymbolUpdateSummaryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns the latest trade data 
     * @summary Returns the latest trade data
     * @param {string} [symbol] stock symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public marketTradesLatestGet(symbol?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).marketTradesLatestGet(symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * queue email notification
     * @summary queue email notification
     * @param {TrNotifyEmail} [trNotifyEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notifyEmailPost(trNotifyEmail?: TrNotifyEmail, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notifyEmailPost(trNotifyEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
     * @summary updates self user entity. ie. username, address
     * @param {TrUserProfile} [trUserProfile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchMyUserProfile(trUserProfile?: TrUserProfile, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchMyUserProfile(trUserProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * redeem a gift
     * @param {string} giftId gift id
     * @param {TrGiftRedeemUpdate} [trGiftRedeemUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public redeemGift(giftId: string, trGiftRedeemUpdate?: TrGiftRedeemUpdate, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).redeemGift(giftId, trGiftRedeemUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary send email verification code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendEmailVerificationCode(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sendEmailVerificationCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add signed user to waitlist using firebase email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public systemWaitlistPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).systemWaitlistPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * trading account info
     * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tradingAccountGet(minorId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tradingAccountGet(minorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * cancel pending order
     * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
     * @param {string} [orderId] order id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tradingOrdersDelete(minorId?: string, orderId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tradingOrdersDelete(minorId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get stock orders
     * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
     * @param {'open' | 'closed' | 'all'} [status] Status of the orders to list.
     * @param {number} [limit] The maximum number of orders in response.
     * @param {string} [after] The response will include only ones submitted after this timestamp (exclusive.)
     * @param {string} [until] The response will include only ones submitted until this timestamp (exclusive.)
     * @param {'asc' | 'desc'} [direction] The chronological order of response based on the submission time. asc or desc. Defaults to desc.
     * @param {boolean} [nested] If true, the result will roll up multi-leg orders under the legs field of primary order.
     * @param {string} [symbols] A comma-separated list of symbols to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tradingOrdersGet(minorId?: string, status?: 'open' | 'closed' | 'all', limit?: number, after?: string, until?: string, direction?: 'asc' | 'desc', nested?: boolean, symbols?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tradingOrdersGet(minorId, status, limit, after, until, direction, nested, symbols, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * order stock for myself
     * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
     * @param {TrTradingCreateOrderRequest} [trTradingCreateOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tradingOrdersPost(minorId?: string, trTradingCreateOrderRequest?: TrTradingCreateOrderRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tradingOrdersPost(minorId, trTradingCreateOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * portfolio history
     * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
     * @param {string} [period] The duration of the data in number + unit (ex 7D, 1M, 2W). Default 1M 
     * @param {string} [timeframe] The resolution of time window. 1Min, 5Min, 15Min, 1H, or 1D.  If omitted, 1Min for less than 7 days period, 15Min for less than 30 days, or otherwise 1D. 
     * @param {string} [dateEnd] The date the data is returned up to, in “YYYY-MM-DD” format. Defaults to the current market date (rolls over at the market open if extended_hours is false, otherwise at 7am ET) 
     * @param {boolean} [extendedHours] If true, include extended hours in the result. This is effective only for timeframe less than 1D. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tradingPortfolioHistoryGet(minorId?: string, period?: string, timeframe?: string, dateEnd?: string, extendedHours?: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tradingPortfolioHistoryGet(minorId, period, timeframe, dateEnd, extendedHours, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * stock positions
     * @param {string} [minorId] optional. custodial user firebase uid. if none provided, default to auth user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tradingPositionsGet(minorId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tradingPositionsGet(minorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update account contact info. this can be called before alpaca account creation
     * @param {TrAccountInfo} [trAccountInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAccountContact(trAccountInfo?: TrAccountInfo, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateAccountContact(trAccountInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * save gift image to Storage and store in db
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadGiftImage(file?: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadGiftImage(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * for displaying contributor avatar list
     * @summary gets contributors UserProfilePublic
     * @param {string} [receiverId] receiver_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersConfigContributorsGet(receiverId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersConfigContributorsGet(receiverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * either self or minor config. data not part of profile.
     * @summary gets config data
     * @param {string} [id] custodial user firebase uid(s). comma delimited
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersConfigGet(id?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersConfigGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * either self or minor config. params not related to the user profile
     * @summary updates user config entity,  ie. wish
     * @param {TrUserConfig} [trUserConfig] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersConfigPatch(trUserConfig?: TrUserConfig, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersConfigPatch(trUserConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * save profile image to Storage and store in db
     * @param {UsersContactsAvatarPostRequest} usersContactsAvatarPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersContactsAvatarPost(usersContactsAvatarPostRequest: UsersContactsAvatarPostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersContactsAvatarPost(usersContactsAvatarPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * remove a social contact.
     * @param {TrUserProfile} [trUserProfile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersContactsDelete(trUserProfile?: TrUserProfile, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersContactsDelete(trUserProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of friends. Only returns public information such as name, username, profiles pics. 
     * @summary Returns the users social circle. ie. list of friends and contacts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersContactsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersContactsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
     * @param {TrUserProfile} [trUserProfile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersContactsPatch(trUserProfile?: TrUserProfile, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersContactsPatch(trUserProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * save profile image to Storage and store in db
     * @param {UsersContactsAvatarPostRequest} usersContactsAvatarPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersCustodialAvatarPost(usersContactsAvatarPostRequest: UsersContactsAvatarPostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersCustodialAvatarPost(usersContactsAvatarPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get list of custodial user data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersCustodialGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersCustodialGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a friend. If given username, then connects user on the platform. If given name and email, then adds to users contacts
     * @summary updates self user entity. ie. username, address, wish
     * @param {string} [id] custodial user firebase uid
     * @param {TrUserProfile} [trUserProfile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersCustodialPatch(id?: string, trUserProfile?: TrUserProfile, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersCustodialPatch(id, trUserProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of users. Only returns public information such as name, username, profiles pics etc. Usage: search a user for sending stock, add as a friend, etc 
     * @summary Returns a list of users.
     * @param {string} [username] username
     * @param {string} [keyword] user search keyword (username, name, email, phone number)
     * @param {string} [email] email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersGet(username?: string, keyword?: string, email?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersGet(username, keyword, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns user profile
     * @summary Returns user by id
     * @param {string} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersIdIdGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersIdIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * save profile image to Storage and store in db
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersMeAvatarPost(body?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersMeAvatarPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets self data
     * @summary gets self data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersMeGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary new user, for tracking purposes. before alpaca signup finished.
     * @param {TrUserProfile} [trUserProfile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersMePost(trUserProfile?: TrUserProfile, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersMePost(trUserProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * given username, returns public profile.
     * @summary Checks if username exists
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersPublicGet(username?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersPublicGet(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets list of users in signup process. returns array of truserprofiles
     * @summary gets list of users in signup process
     * @param {string} [since] since epoch time
     * @param {string} [until] until epoch time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersTrackInsignupsGet(since?: string, until?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersTrackInsignupsGet(since, until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * checks if username exists. 
     * @summary Checks if username exists
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersUsernameGet(username?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersUsernameGet(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary verify email
     * @param {VerifyMySecondaryEmailRequest} [verifyMySecondaryEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public verifyMySecondaryEmail(verifyMySecondaryEmailRequest?: VerifyMySecondaryEmailRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).verifyMySecondaryEmail(verifyMySecondaryEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary verify email
     * @param {VerifyMySecondaryEmailRequest} [verifyMySecondaryEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public verifyMySubstituteEmail(verifyMySecondaryEmailRequest?: VerifyMySecondaryEmailRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).verifyMySubstituteEmail(verifyMySecondaryEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


