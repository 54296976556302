import { createTheme } from '@mui/material/styles'

// | 'h1'
// | 'h2'
// | 'h3'
// | 'h4'
// | 'h5'
// | 'h6'
// | 'subtitle1'
// | 'subtitle2'
// | 'body1'
// | 'body2'
// | 'caption'
// | 'button'
// | 'overline';

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: 60,
      fontWeight: 400
    },
    h6: {
      fontSize: 15,
      fontWeight: 'bold'
    }  
  },
  palette: {
    success: { // green
      light: '#c7f4e7',
      100: '#c7f4e7',
      200: '#84e4c8',
      main: '#05c890',
      300: '#05c890',
      400: '#3cba96',
      500: '#008f66',
      dark: '#008f66'
    },
    background: {
      default: '#eeeeee',
      paper: '#ddd'
    },
  }
})