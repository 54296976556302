import _ from 'lodash'
import { applySnapshot, types } from 'mobx-state-tree'
import { TrAccountInfo, TrUserConfig, TrUserProfile } from '../sdk-ts-axios'
import { Positions, TradingAccount, AccountInfo, Config, Profile, Orders } from './meStore'
import { InitCustodial } from './store'

const CustodialProfiles = types.model('CustodialProfilesStore', {
  profiles: types.maybe(types.string) // JSON blob
}).actions(self => ({
  set(minors: TrUserProfile[]) { // my own kids
    self.profiles = JSON.stringify(minors)
  },
})).views(self => ({
  get(): TrUserProfile[] {
    return self.profiles ? JSON.parse(self.profiles) : []
  },
}))

const CustodialConfigs = types.model('CustodialConfigsStore', {
  configs: types.maybe(types.string) // JSON blob
}).actions(self => ({
  set(kidsConfigs: TrUserConfig[]) { // my own kids
    self.configs = JSON.stringify(kidsConfigs)
  },
})).views(self => ({
  get(): TrUserConfig[] {
    return self.configs ? JSON.parse(self.configs) : []
  },
  getById(myKidUid?: string): TrUserConfig | undefined {
    if (!self.configs || !myKidUid)
      return undefined
    const obj: TrUserConfig[] = JSON.parse(self.configs)
    const config = obj.filter(c => c.id === myKidUid)
    return config.length > 0 ? config[0] : undefined
  }
}))

const CustodialAccounts = types.model('CustodialAccountsStore', {
  accounts: types.maybe(types.string) // JSON blob
}).actions(self => ({
  set(kidsConfigs?: TrAccountInfo[]) { // my own kids
    // by setting this, we already performed api call, thus set at least empty array. undefined == no api call yet
    self.accounts = kidsConfigs ? JSON.stringify(kidsConfigs) : JSON.stringify([]) 
  }
})).views(self => ({
  getAll(): TrAccountInfo[] | undefined {
    // undefined state = no api call yet. if api called, at least empty array
    return self.accounts ? JSON.parse(self.accounts) : undefined
  },
  getByEmail(email: string | undefined): TrAccountInfo | undefined {
    if (!self.accounts || !email)
      return undefined
    const accounts: TrAccountInfo[] = JSON.parse(self.accounts)
    const filtered = accounts?.filter(acct => acct .minor_identity?.email === email)
    return filtered.length > 0 ? filtered?.[0] : undefined
  }
}))

export const Custodial = types.model('CustodialStore', {
  accounts: CustodialAccounts, // all custodial accounts
  configs: CustodialConfigs, // all custodial configs
  profiles: CustodialProfiles, // all custodial profiles
}).actions(self => ({
  clear() {
    applySnapshot(self, InitCustodial)
  },
}))
