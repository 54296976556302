import { Box, Button, Card, FilledInput, FormControl, InputAdornment, InputLabel, SxProps, TextField, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import React, { useState } from 'react'
import { useStore } from '../../hooks/useStore'

function PersonalizeCardEdit () {
  const store = useStore()
  const snap = getSnapshot(store.Donate)
  const senderName = snap.senderName
  const message = snap.message
  const amount = snap.amount

  return (
    <Card sx={sx.card}>
      <TextField 
        variant="filled" 
        label="Your Name"
        value={senderName} 
        onChange={(t) => store.Donate.setSenderName(t.target.value)}
        autoFocus
      />

      <TextField 
        variant="filled" 
        label="Message"
        value={message} 
        onChange={(t) => store.Donate.setMessage(t.target.value)}
        multiline
        minRows={3}
      />

      <Box>
        <Button variant="contained" onClick={() => store.Donate.setAmount(5)}>$5</Button>
        <Button variant="contained" onClick={() => store.Donate.setAmount(10)}>$10</Button>
        <Button variant="contained" onClick={() => store.Donate.setAmount(20)}>$20</Button>
      </Box>
      
      <FormControl fullWidth sx={{ m: 1 }} variant="filled">
        <InputLabel htmlFor="filled-adornment-amount">Amount</InputLabel>
        <FilledInput
          id="filled-adornment-amount"
          value={amount}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          onChange={(t)=> store.Donate.setAmount(Number(t.target.value))}
        />
      </FormControl>

      <Button variant="contained" onClick={() => store.Donate.setState('stock')}>Next</Button>
    </Card>
  )
}

export default observer(PersonalizeCardEdit)

const sx = {
  card: {
    backgroundColor: 'white',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
    marginY: 1
  } as SxProps,
  nameUsername: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  } as SxProps,
}
