import _ from 'lodash'
import { types, SnapshotOut, applySnapshot, flow } from 'mobx-state-tree'

export const Security = types.model('SecurityStore', {
  lastActive: types.maybe(types.Date), // used for security sign in
  delay: types.maybe(types.boolean),  // used for security sign in
  show: types.maybe(types.boolean)  // used for security sign in
}).actions(self => ({
  setLastActive() {
    self.lastActive = new Date()
  },
  setDelay(delay: boolean) {
    self.delay = delay
  },
  setShow(show: boolean) {
    self.show = show
  },
})).views(self => ({
  getLastActive() {
    return self.lastActive
  },
  isDelay() {
    return self.delay ?? false
  },
  isShow() {
    return self.show ?? false
  }
}))
