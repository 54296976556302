import React, { useState } from 'react'
import { Box, Button, Card, CardActions, CardContent, SxProps, TextField, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import Grid from '@mui/material/Unstable_Grid2'

import { useStore } from '../../hooks/useStore'

function PersonalizeCard () {
  const store = useStore()
  const snap = getSnapshot(store.Donate)
  const senderName = snap.senderName
  const message = snap.message
  const amount = snap.amount

  return (
    <Card sx={sx.card}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid xs={4}>
            <Typography>Your Name</Typography>
          </Grid>
          <Grid xs={8}>
            <Typography>{senderName}</Typography>
          </Grid>
          <Grid xs={4}>
            <Typography>Your Message</Typography>
          </Grid>
          <Grid xs={8}>
            <Typography>{message}</Typography>
          </Grid>
          <Grid xs={4}>
            <Typography>Amount</Typography>
          </Grid>
          <Grid xs={8}>
            <Typography>{`$${amount}`}</Typography>
          </Grid>
        </Grid>
      </CardContent>

      <Box display="flex" justifyContent='flex-end'>
        <Button size='small' onClick={()=> store.Donate.setState('personalize')}>Edit</Button> 
      </Box>
    </Card>
  )
}

export default observer(PersonalizeCard)

const sx = {
  card: {
    backgroundColor: 'white',
    width: 400,
    marginY: 1
  } as SxProps,
  nameUsername: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  } as SxProps,
}
