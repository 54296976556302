import React, { useState } from 'react'
import { Grid, Container, Box, SxProps, Card, TextField, Button, Typography, FormControl, InputLabel, FilledInput, InputAdornment, IconButton } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import '../App.css'
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, createUserWithEmailAndPassword } from 'firebase/auth'
import { Path } from '../routes'
import GoogleSignInButton from '../components/auth/GoogleSignInButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function SignIn() {
  const navigate = useNavigate()
  const authentication = getAuth()
  const [isSignUp, setIsSignUp] = useState(false) // false = SignIn
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  
  async function manualSignIn() {
    setErr(undefined)
    setProcessing(true)
    try {
      const res = await signInWithEmailAndPassword(authentication, email, password)
      navigate(Path.Home)
    } catch (e) {
      (e instanceof Error) ? setErr(e.message) : setErr(JSON.stringify(e))
    }
    setProcessing(false)
  }

  async function manualJoin() {
    setProcessing(true)
    const res = await createUserWithEmailAndPassword(authentication, email, password)
    sessionStorage.setItem('Auth Token', res.user.refreshToken)
    navigate(Path.Home)
    setProcessing(false)
  }

  async function googleSignin() {
    setProcessing(true)
    const auth = getAuth()
    try {
      const provider = new GoogleAuthProvider()
      const result = await signInWithPopup(auth, provider)

      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result)
      if (credential === null)
        return
      const token = credential.accessToken
      // The signed-in user info.
      const user = result.user

      setProcessing(false)
    } catch (error) {
      console.log('error', error)
    }
  }
  
  return (
    <Box sx={sx.page}>
      <Box sx={sx.centerBox} color="primary">

        <Typography variant="h1" >Trellish</Typography>

        <TextField 
          onChange={(t) => setEmail(t.target.value)}
          sx={sx.input} 
          id="email" 
          label="Email" 
          variant="filled"
          value={email} 
        />

        <FormControl sx={sx.input} variant="filled">
          <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(t)=> setPassword(t.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        { err ? <Typography color='error.main'>{err}</Typography> : null }
      
        <Box display="flex" justifyContent="flex-end">
          { isSignUp 
            ? <Button onClick={async () => await manualJoin()} sx={sx.button} variant="contained" size="large">Join</Button>
            : <Button onClick={async () => await manualSignIn()} sx={sx.button} variant="contained" size="large">Sign In</Button>
          }
        </Box>

        { isSignUp 
          ? <Typography color='primary.dark' onClick={() => setIsSignUp(false)}>Already have an account?</Typography>
          : <Typography color='primary.dark' onClick={() => setIsSignUp(true)}>Dont have an account?</Typography>
        }

        <Typography sx={sx.center} variant='h5'>Or</Typography>

        <Box sx={sx.center} onClick={async () => await googleSignin()}>
          <GoogleSignInButton isSignUp={isSignUp} disable={processing} />
        </Box>  
   
      </Box>
    </Box>
  )
}

export default SignIn

const sx = {
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: 'success.dark'
  } as SxProps,
  centerBox: {
    margin: 'auto',
    marginTop: 10,
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    backgroundColor: '#f0f0f0',
    borderRadius: 10
  } as SxProps,
  center: {
    display: 'flex',
    justifyContent: 'center',
    margin: 2
  } as SxProps,
  input: {
    margin: 1,
  } as SxProps,
  button: {
    margin: 2,
    alignItems: 'flex-end'
  } as SxProps,
}
