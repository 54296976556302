import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TrGiftStatusPublic } from '../sdk-ts-axios'
import Text from '@mui/material/Typography'
import { addDashPer5Letters } from '../utils/utils'

interface Props {
  gift: TrGiftStatusPublic
}

export default function RedeemInstructions({gift}: Props) {
  const isoDate = new Date(gift?.created_at ?? '')
  
  return (
    <Box>
      <Text>To Redeem</Text>

      {gift.receiver_guardian_email && (
        <Text variant='caption'>
          Have your Guardian Download the Trellish App and Redeem your Gift. This Gift is
          redeemable by {gift.receiver_guardian_email}.
          Gift must be redeemed with 30 days of {isoDate.toDateString()}
        </Text>
      )
      } 

      {(gift.receiver_username ||  gift.receiver_email) && (
        <Text variant='caption'>
          Download the Trellish App and Redeem your Gift. This Gift is
          connected to your {gift.receiver_username ?? gift.receiver_email}.
          Gift must be redeemed with 30 days of {isoDate.toDateString()}
        </Text>
      )
      }

      { !gift.receiver_name && (
        <Text variant='caption'>
          Download the Trellish App and Redeem this GiftCard with the Gift ID: {addDashPer5Letters(gift?.gift_id ?? '')}.
          Gift must be redeemed with 30 days of {isoDate.toDateString()}
        </Text>
      )
      }
            
    </Box>
  )
}