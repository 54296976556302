import { Box, SxProps } from '@mui/material'
import React from 'react'
import { TrGiftStatusPublic } from '../sdk-ts-axios'

interface Props {
  gift: TrGiftStatusPublic
}

export default function GiftImageWithBackup({ gift }: Props) {
  return (
    <Box sx={stylesImgBox}>
      { gift?.image_url ? (
        <Box
          component="img"
          alt={'memory'}
          sx={{height: '100%', width: '100%'}}
          src={gift?.image_url} // use normal <img> attributes as props
        />
  
      ): (
        <Box
          component="img"
          alt={'memory'}
          sx={{height: '100%', width: '100%'}}
          src={'https://t3.ftcdn.net/jpg/02/33/38/08/360_F_233380815_4ah3nJszftXDiCch6CwlmlAJdJSsRlSM.jpg'} // use normal <img> attributes as props
        />
      )}
    </Box>
  )
}

const stylesImgBox: SxProps = {
  width: 300,
  paddingBottom: 1
}
