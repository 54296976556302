import { TrGiftStatusPublic } from '../sdk-ts-axios'
import { Api, config } from './api'

export async function getGiftPublic(giftId: string): Promise<TrGiftStatusPublic | undefined> {
  try {
    const api = await Api()
    const res = await api.getGiftPublic(giftId)
    return res.data
  } catch (e) {
    console.error('getGiftPublic', e)
    console.error('getGiftPublic, bad client data')
  }
}
