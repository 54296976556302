import React, { useEffect, useState } from 'react'
import { Grid, Container, Box, SxProps } from '@mui/material'
import Text from '@mui/material/Typography'

interface Props {
  name?: string
}

export default function WishIcon({ name}: Props) {

  const arr = name?.split(' ')
  const first = arr?.shift()
  const last = arr ? arr.join(' ') : ''
  return(
    <Box sx={box}>
      <Text variant='h6'>{`${first}`}</Text>
      <Text variant='h6'>{`${last}'s`}</Text>
      <Text variant='h6'>Wish</Text>
    </Box>
  )
}

const box: SxProps = {
  height: 120,
  width: 120,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  borderColor: 'dodgerblue',
  borderWidth: 3,
  borderStyle: 'solid',
  padding: 1
}