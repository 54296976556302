import { types, Instance, applySnapshot, getIdentifier } from 'mobx-state-tree'
import {
  TrAccountInfo,
  TrUserProfile,
} from '../sdk-ts-axios'
import { Positions, TradingAccount, AccountInfo, Config, Profile, Orders } from './meStore'
import { InitRecipient } from './store'

/**
 * Recipient store is also treated as the user in focus
 */
export const Recipient = types
  .model('RecipientStore', {
    account: AccountInfo, // all custodial accounts
    config: Config, // all custodial configs
    profile: Profile, // of 1 focused custodian account
    position: Positions, // of 1 focused custodian account
    tradingAccount: TradingAccount, // of 1 focused custodian account
    orders: Orders, // of 1 focused custodian account

    guardian: types.maybe(types.string), // json blob
  })
  .actions(self => ({
    setGuardian(user: TrUserProfile) {
      self.guardian = JSON.stringify(user)
    },
    clear() {
      applySnapshot(self, InitRecipient)
    },
  }))
  .views(self => ({
    isEmpty(): boolean {
      return self.profile.get() === undefined
    },
    getGuardian(): TrUserProfile | undefined {
      return self.guardian ? JSON.parse(self.guardian) : undefined
    },
  }))
