import _ from 'lodash'
import axios, { AxiosError } from 'axios'

import { Api } from './api'
import { TrEmail, TrUserPolicy, TrUserProfile, TrUserProfilePublic } from '../sdk-ts-axios'
import { logErr, logInfo, logWarn } from '../log'
import { getContacts } from './contactsApi'
import { axiosInfoLog, axiosErrorLog } from '../utils/error'
import { resolve } from 'node:path/posix'

export async function getMyProfile(): Promise<TrUserProfile | null> {
  try {
    const api = await Api()
    const res = await api.usersMeGet()
    return res.data
  } catch (e) {
    axiosInfoLog('getMyUserEntity', e) // is not an error
  }
  return null
}

/**
 * setUsername
 * if success, returns undefined.
 * if error, returns error string
 * @param username 
 * @returns 
 */
export async function setUsername(username: string): Promise<undefined> {
  try {
    const api = await Api()
    const userprofile: TrUserProfile = {
      username: username
    }
    const res = await api.patchMyUserProfile(userprofile)
    logInfo('setUsername', {
      username,
      resdata: res.data,
      resStatus: res.status
    })
    return
  } catch (e) {
    axiosErrorLog('setUsername', e)
  }
}

export async function getMyPolicy(): Promise<TrUserPolicy | undefined> {
  try {
    const api = await Api()
    const res = await api.getUserPolicy()
    return res.data
  } catch (e) {
    axiosErrorLog('getMyPolicy', e)
  }
}

/**
 * getUsers, global list of users
 * @param keyword 
 * @returns 
 */
export async function getUserById(uid: string): Promise<TrUserProfilePublic | undefined> {
  logInfo('getUserById', { uid })

  try {
    const api = await Api()
    const res = await api.usersIdIdGet(uid)
    return res.data
  } catch (e) {
    axiosErrorLog('getUserById', e)
  }
}

/**
 * getUsers, global list of users
 * @param keyword 
 * @returns 
 */
export async function getUsers(keyword?: string): Promise<TrUserProfilePublic[]> {
  logInfo('getUsers', { keyword })
  const emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i

  try {
    const api = await Api()
    let res
    if (!keyword) {
      res = await api.usersGet(undefined, '')
    } else if (emailPattern.test(keyword)) {
      res = await api.usersGet(undefined, undefined, keyword)
    } else if (keyword.startsWith('@')) {
      res = await api.usersGet(keyword)
    } else {
      res = await api.usersGet(undefined, keyword)
    }

    if (res.data === undefined) {
      logInfo('getUsers empty')
      return []
    }

    const users = res.data.map((u) => {
      const user: TrUserProfile = { ...u }
      return user
    })

    return users
  } catch (e) {
    logErr('getUsers Error', { error: e, keyword })
    return []
  }
}

export async function isUsernameExist(username: string): Promise<boolean> {
  try {
    const api = await Api()
    const res = await api.usersUsernameGet(username)
    return !!res.data
  } catch (e) {
    logErr('isUsernameExist', { error: e, username })
  }
  return false
}

export async function getMySecondaryEmails(): Promise<TrEmail[] | undefined> {
  try {
    const api = await Api()
    const res = await api.getMyEmails()
    const filterSecondary = res.data?.filter(e => e.type === 'secondary')
    return filterSecondary
  } catch (e) {
    axiosErrorLog('getMyEmails', e)
  }
}

export async function addMySubtituteEmail(email: string): Promise<undefined | AxiosError> {
  try {
    const api = await Api()
    await api.addMySubstituteEmail({
      email
    })
    return
  } catch (e) {
    return axiosErrorLog('addMySubtituteEmail', e)
  }
}

export async function verifyMySubtituteEmail(email: string, code: string): Promise<undefined | AxiosError> {
  try {
    if (email === '')
      throw new Error('missing email')
    if (code === '')
      throw new Error('missing code')
    const api = await Api()
    await api.verifyMySubstituteEmail({
      email: email.toLowerCase(),
      code
    })
    return
  } catch (e) {
    return axiosErrorLog('verifyMySubtituteEmail', e)
  }
}

export async function addMySecondaryEmail(email: string): Promise<undefined | AxiosError> {
  try {
    const api = await Api()
    await api.addMySecondaryEmail({
      email
    })
    return
  } catch (e) {
    return axiosErrorLog('addMySecondaryEmail', e)
  }
}

export async function verifyMySecondaryEmail(email: string, code: string): Promise<undefined | AxiosError> {
  try {
    if (email === '')
      throw new Error('missing email')
    if (code === '')
      throw new Error('missing code')
    const api = await Api()
    await api.verifyMySecondaryEmail({
      email: email.toLowerCase(),
      code
    })
    return
  } catch (e) {
    return axiosErrorLog('verifyMySecondaryEmail', e)
  }
}

export async function deleteSecondaryEmail(email: string): Promise<undefined | AxiosError> {
  try {
    const api = await Api()
    await api.deleteMySecondaryEmail({ email })
    return
  } catch (e) {
    return axiosErrorLog('deleteSecondaryEmail', e)
  }
}

export async function getPublicUserByUsername(username: string): Promise<TrUserProfilePublic | AxiosError> {
  try {
    const api = await Api()
    const res = await api.usersPublicGet(username)
    return res.data
  } catch (e) {
    return axiosErrorLog('deleteSecondaryEmail', e)
  }
}