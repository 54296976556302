import { TrSymbolBrandImages, TrSymbolSummary } from '../sdk-ts-axios'
import { Api } from './api'
import { getLocalStorage, isLocalStorageExpired, LocalStorageKeys, setLocalStorage, setLocalStorageTracker } from './localStorage'
import { logErr } from './log'

export async function getSymbolSummary(symbol: string): Promise<TrSymbolSummary | undefined> {
  try{
    const api = await Api()
    const stocks = await api.marketSymbolGet(symbol)
    return stocks.data[0]
  } catch (e) {
    logErr('getSymbolSummary', { e })
  }
}

export async function knownBrandIcons(name?: string, symbol?: string): Promise<string | undefined> {
  try {
    const api = await Api()
    const imageUrl = await api.marketSymbolBrandsKnownGet(name, symbol)
    return imageUrl.data
  } catch (e) {
    logErr('knownBrandIcons', { e })
  }
}

/**
 * getBrandImages
 * gets icons and logos filenames that are available GCP cloud storage buckets, 
 * and its file extensions
 * @returns 
 */
export async function getBrandImages(): Promise<TrSymbolBrandImages | undefined> {
  const isExpired = await isLocalStorageExpired(LocalStorageKeys.symbolIconsLogosNamesLastUpdated)
  if (!isExpired) {
    const asyncRes: TrSymbolBrandImages = await getLocalStorage(LocalStorageKeys.symbolIconsLogosNames)
    if (asyncRes.icons && asyncRes.icons.length > 0)
      return asyncRes
  }
  try{
    const api = await Api()
    const apiRes = await api.marketSymbolBrandsGet()
    await setLocalStorage(LocalStorageKeys.symbolIconsLogosNames, apiRes.data)
    await setLocalStorageTracker(LocalStorageKeys.symbolIconsLogosNamesLastUpdated)
    return apiRes.data
  } catch (e) {
    logErr('clock', {e})
  }
}