import React, { useEffect, useState } from 'react'
import { Grid, Container, Box, SxProps, Card, Typography } from '@mui/material'
import Text from '@mui/material/Typography'
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { logErr } from '../../service/log'
import { useMixpanel } from 'react-mixpanel-browser'

export default function AuthActionScreen() {
  const mixpanel = useMixpanel()
  const query = useQuery()
  const mode = query.get('mode')
  const oobCode = query.get('oobCode')
  const apiKey = query.get('apiKey')
  const url = `https://trellish-prod.firebaseapp.com/__/auth/action?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}`
  /**
   * 
   * used as a redirect for firebase
   * https://console.firebase.google.com/u/0/project/trellish-prod/authentication/emails
   *  - email verification
   *  - lost password
   */

  useEffect(() => {
    mixpanel.track('Auth Action', { mode })
    window.location.href = url
  }, [])

  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  
  return (
    <Box>
      <Typography>auth password reset...</Typography>
    </Box>
  )
}
