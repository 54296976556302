import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'

import Root from './screens/RootScreen'
import Home from './screens/HomeScreen'
import ViewGiftPrintable from './screens/public/ViewGiftPrintableScreen'
import ViewGift from './screens/public/ViewGiftScreen'
import Donate from './screens/DonateScreen'

import SignIn from './screens/SignIn'
import NotFound from './screens/NotFoundScreen'
import { Box } from '@mui/material'
import ForgotPassword from './components/auth/ForgotPasswordScreen'
import AuthActionScreen from './screens/public/AuthActionScreen'

export const Path = {
  AuthAction: '/auth/action',
  ViewGift: '/g/:giftId',
  ViewGiftPrintable: '/gp/:giftId',
  DonateWithUsername: '/u/:username',
  DonateGeneral: '/donate',

  Index: '/',
  SignIn: '/signin',
  ForgotPassword: '/forgotpassword',
  Home: '/home'
}

export default function RouteList() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Root />} />
        <Route path={Path.AuthAction} element={<AuthActionScreen />} />
        <Route path={Path.ViewGift} element={<ViewGift />} />
        <Route path={Path.ViewGiftPrintable} element={<ViewGiftPrintable />} />
        <Route path={Path.DonateWithUsername} element={<Donate />} />
        <Route path={Path.DonateGeneral} element={<Donate />} />

        <Route path={Path.SignIn} element={<SignIn />} />
        <Route path={Path.ForgotPassword} element={<ForgotPassword />} />
        <Route path={Path.Home} element={<Home />} /> 

        <Route path='*' element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
