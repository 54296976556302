import { Box, Typography } from '@mui/material'
import React from 'react'

export default function CompanyLogoTagline() {
  return (

    <a href='https://www.divincome.com' style={{ textDecoration: 'none', color: 'black' }}>
      <Box sx={{ 
        display: 'flex',
        textDecoration: 'none'
      }}>
        <Box
          component="img"
          alt={'logo'}
          sx={{height: 70, width: 70, paddingRight: 1}}
          src={'https://storage.googleapis.com/divincome-assets/icons/div-income.png'} // use normal <img> attributes as props
        />
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography variant='h4'>Div Income</Typography>
          <Typography>Get Paid to Invest</Typography>
        </Box>
     
      </Box> 
    </a>
  )
}
