import { Box, Button, Card, FilledInput, FormControl, InputAdornment, InputLabel, SxProps, TextField, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import React, { useState } from 'react'
import { useStore } from '../../hooks/useStore'
import { State } from '../../store/donateStore'

function PayCard () {
  const store = useStore()
  const snap = getSnapshot(store.Donate)
  const state = snap.state
  const senderName = snap.senderName

  async function pay() {
    // adsf
  }
  return (
    <Card sx={sx.card}>
      <TextField 
        sx={sx.text}
        variant="filled" 
        label="Credit card"
        value={senderName} 
        // onChange={(t) => store.Donate.setSenderName(t.target.value)}
        autoFocus
      />

      <Button sx={sx.button} variant="contained" onClick={() => pay()}>Donate</Button>
    </Card>
  )
}

export default observer(PayCard)

const sx = {
  card: {
    backgroundColor: 'white',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
  } as SxProps,
  button: {
    margin: 1
  } as SxProps,
  text: {
    margin: 1
  }
}
