import React from 'react'
import '../App.css'

function Root() {
  return (
    <div className='App'>
      <header className='App-header'>
        <nav
          style={{
            borderBottom: 'solid 1px',
            paddingBottom: '1rem',
            fontFamily: 'Roboto'
          }}
        >
          Div Income Web
        </nav>
      </header>
    </div>
  )
}

export default Root
