import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Link, useNavigate } from 'react-router-dom'
import { Path } from '../routes'
import { auth } from '../service/api'

function Home() {
  const navigate = useNavigate()
  const user = auth.currentUser

  async function signout() {
    await auth.signOut()
    navigate(Path.SignIn)
  }

  return (
    <Box>
      <Typography>{`you are signed in ${JSON.stringify(user)}`}</Typography>
      <Typography onClick={() => signout()}>Signout</Typography>
    </Box>
  )
}

export default Home
