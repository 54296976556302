export function logInfo(msg: string, obj?: object) {
  obj ? console.log(msg, JSON.stringify(obj, null, 2)) : console.log(msg)
  // mixpanel.track(msg, flatten(obj)) TODO
  // DdLogs.info(msg, obj)
}

export function logWarn(msg: string, obj?: object) {
  obj ? console.warn(msg, JSON.stringify(obj, null, 2)) : console.warn(msg)
  // mixpanel.track(msg, flatten(obj)) TODO
  // DdLogs.warn(msg, obj)
}

export function logErr(msg: string, obj?: object) {
  obj ? console.error(msg, JSON.stringify(obj, null, 2)) : console.error(msg)
//   mixpanel.track(msg, flatten(obj))
//   DdLogs.error(msg, obj)
}

const flatten = (obj: any, prefix = '') => {
  if (!obj)
    return
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : ''
    if (typeof obj[k] === 'object') Object.assign(acc, flatten(obj[k], pre + k))
    // @ts-expect-error skip anytype
    else acc[pre + k] = obj[k]
    return acc
  }, {})

}
