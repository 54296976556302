import { EncryptStorage } from 'encrypt-storage'
import { config } from './api'
import { logErr } from './log'

export const encryptStorage = new EncryptStorage(config.apiKey ?? '')

export enum LocalStorageKeys {
  SymbolSummaryLastUpdated = 'SymbolSummaryLastUpdated', // all stock names and symbols
  SymbolSummary = 'SymbolSummary',

  // icon filenames list from GCP cloud storage
  symbolIconsLogosNamesLastUpdated = 'symbolIconsLogosNamesLastUpdated',
  symbolIconsLogosNames = 'symbolIconsLogosNames',
}

export async function isLocalStorageExpired(key: LocalStorageKeys, duration?: number): Promise<boolean> {
  try {
    const value = await JSON.parse(window.localStorage.getItem(key) ?? '')
    const weekAgo = (new Date()).getDate() - (duration ?? 7) // weekAgo
    return !((value && Number(value) > weekAgo))
  } catch (e) {
    return true
  }
}

export async function setLocalStorageTracker(key: LocalStorageKeys): Promise<void> {
  const now = String(Date.now())
  try {
    encryptStorage.setItem(key, now)
  } catch (e) {
    logErr('setLocalStorageTracker', { error: e, key })
  }
}

export async function setLocalStorage(key: LocalStorageKeys, jsonData: any): Promise<void> {
  try {
    const jsonValue = JSON.stringify(jsonData)
    encryptStorage.setItem(key, jsonValue)
  } catch (e) {
    logErr('setLocalStorage', { error: e, key })
  }
}

export async function getLocalStorage(key: LocalStorageKeys): Promise<any | undefined> {
  try {
    const jsonValue =  encryptStorage.getItem(key)
    if (jsonValue != null)
      return JSON.parse(jsonValue)
  } catch(e) {
    logErr('getLocalStorage', { error: e, key })
  }
}