import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { getBrandImages, knownBrandIcons } from '../service/marketService'
import { IconsBaseUrl, LogosBaseUrl } from '../service/gcpStorage'
import axios from 'axios'

interface Props {
  symbol?: string
  name?: string
}

export default function BrandIcon(props: Props): JSX.Element {
  const { symbol, name } = props
  const [iconUrl, setIconUrl] = useState<string | undefined>()
  const [logoUrl, setLogoUrl] = useState<string | undefined>()
  const [islogoUrlSVG, setLogoUrlSVG] = useState(false)
  
  const isSubscribed = true // cleanup async if unmounted

  useEffect(()=>{
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[symbol]) 

  async function get() {
    setIconUrl(undefined)
    try {
      const knownIconUrl = await knownBrandIcons(name, symbol)
      if (!isSubscribed)
        return
      if (knownIconUrl)
        return setIconUrl(knownIconUrl)

      const images = await getBrandImages()
      if (!isSubscribed || axios.isAxiosError(images))
        return
      const iconFilename = _.find(images?.icons, e => e.split('.')[0] === symbol)
      if(iconFilename) {
        setIconUrl(`${IconsBaseUrl}/${iconFilename}`)
        return
      }

      const logoFilename = _.find(images?.logos, e => e.split('.')[0] === symbol)
      if(logoFilename) {
        setIconUrl(`${LogosBaseUrl}/${logoFilename}`)

      }
    } catch(e) {
      console.log('user left component before async finished', e)
    }
  }

  return (
    <>
      {
        iconUrl && (
          <img src={ iconUrl } width="100" height="100"/>
        )
      }
    </>
  )
}
