import React, { useEffect, useState } from 'react'
import { Grid, Container, Card } from '@mui/material'
import Text from '@mui/material/Typography'
import _ from 'lodash'
import { TrGiftStatusPublic, TrSymbolSummary } from '../sdk-ts-axios'
import BrandIcon from './BrandIcon'

interface Props {
  stockSummary: TrSymbolSummary
}

export default function StockNameBrandGivenGift({ stockSummary }: Props) {

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <BrandIcon symbol={stockSummary?.symbol} name={stockSummary?.name} />
      </Grid>
      <Grid item xs={9}>
        <Text variant="body1" gutterBottom>
          {stockSummary?.symbol}
        </Text>
        {stockSummary?.name}
      </Grid>
    </Grid>
  )
}
