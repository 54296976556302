// abc/:rfv  ->  abc.   the :rfv is removed
export function removeParamsFromRouterUrl(url: string): string {
  const colonIndex = url.lastIndexOf(':')
  return url.slice(0, colonIndex-1)
}

import { AccountStatus, TrAccountInfo, TrACHRelationshipData, TrRelation, TrTradingAccount, TrUserProfile } from '../sdk-ts-axios'
import _ from 'lodash'
import { number } from 'yargs'

// for ssn and birthdates
export function allowOnlyNumberDash(value: string) {
  return value.replace(/[^0-9-]/g, '')
}

export const allowOnlyLettersNumbers = (value: string) => {
  return value.replace(/[^a-zA-Z0-9]/g, '')
}

// for phone numbers
export const allowOnlyNumberDashParenthesis = (value: string) => {
  return value.replace(/[^0-9-()]/g, '')
}

export const USA_PHONE_LENGTH = /^.{13,}$/ // (999)999-9999

export function randomBoolean(): boolean {
  return !!Math.floor(Math.random() * 2)
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function validateEmail(email: string): boolean {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(email)
}

export async function sleep(ms: number) {
  return await new Promise(resolve => setTimeout(resolve, ms))
}

export function numbersNoLeadingZeros(value: string): string {
  const num = value.replace(/[^0-9.]/g, '')
  return num.replace(/^0+/, '')
}

// reads in -5.23 or 3.492, returns -$5.23 or $3.42
export function formatDollarValue(dollar: number): string {
  if(dollar >= 0) {
    return dollar.toFixed(2)
  }
  const pos = Math.abs(dollar)
  return `-$${pos.toFixed(2)}`
}

export function scrubBankAccount(data?: TrACHRelationshipData): TrACHRelationshipData | undefined {
  if (!data)
    return
  data.bank_account_number = data.bank_account_number?.slice(-4)
  return data
}

export function scrubBankAccounts(data: TrACHRelationshipData[]): TrACHRelationshipData[] {
  return _.map(data, d => {
    d.bank_account_number = d.bank_account_number?.slice(-3)
    d.bank_routing_number = d.bank_routing_number?.slice(-3)
    return d
  })
}

export type AccountStatusMode = 'pass' | 'fail' | 'pending'

export function accountStatusMode(account?: TrAccountInfo): AccountStatusMode {
  if (account?.kyc_results?.summary === 'pass')
    return 'pass'
  if (account?.status === AccountStatus.Rejected ||
    account?.status === AccountStatus.ActionRequired ||
    account?.status === AccountStatus.SubmissionFailed ||
    account?.kyc_results?.summary === 'fail')
    return 'fail'
  return 'pending'
}

export type MsgType = 'success' | 'error' | undefined
export interface Msg {
  text?: string
  type?: MsgType
}

export function isActiveAccount(tradingAcct?: TrTradingAccount): boolean {
  return (tradingAcct?.status && (tradingAcct.status === 'ACTIVE')) ?? false
}

export function isAccountNeedsAction(tradingAcct?: TrTradingAccount): boolean {
  return (tradingAcct?.status === 'SUBMISSION_FAILED'
  || tradingAcct?.status === 'ACTION_REQUIRED'
  || tradingAcct?.status === 'REJECTED')
}

// to read giftcards
export function addDashPer5Letters(id: string): string {
  return id.slice(0,5)+'-'+id.slice(5,10)+'-'+id.slice(10,15)+'-'+id.slice(15)
}
