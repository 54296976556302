import { types, applySnapshot } from 'mobx-state-tree'

export type State = 'personalize' | 'stock' | 'pay'

export const Donate = types.model('DonateStore', {
  state: types.optional(types.string, 'personalize' as State),
  senderName: types.maybe(types.string), 
  message: types.maybe(types.string),
  amount: types.maybe(types.number),
  symbol: types.maybe(types.string),
}).actions(self => ({
  setState(state: State) {
    self.state = state
  },
  setSenderName(name: string) {
    self.senderName = name
  },
  setMessage(msg: string) {
    self.message = msg
  },
  setAmount(amount: number) {
    self.amount = amount
  },
  setSymbol(symbol: string) {
    self.symbol = symbol
  },
})).views(self => ({
  clear() {
    applySnapshot(self, {})
  },
}))
